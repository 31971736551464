/**
 * Values availables are:
 *
 * 0 = No tecRMI
 * 1 = Labour Times (TecTime)
 * 2 = Maintenance (TecMainteance)
 * 4 = Repairbooks (TecManuals)
 * 8 = Adjustments (TecAdjust)
 * 16 = SOS-Books (TecBulletin)
 * 32 = Wirings (TecWirings)
 * 64 = Grafical Search(TecGraphics)
 * 128 = Diagnose (TecDiagnosticValues)
 * 256 = Fuse and Relais (TecRelays/Fuses)
 * 512 = Component Location (TecComponentLocation)
 * 1024 = Comfortcircuit Diagrams (TecComfortcircuitDiagrams)
 * 2048 = Tyre (TecTyres)
 *
 * @type {RmiGrants}
 */
export enum RmiGrants {
  /**
   * No tecRMI
   */
  None = 0,

  /**
   * Labour Times (TecTime)
   */
  LabourTimes = 1 << 0,             // 0000000000001

  /**
   * Maintenance (TecMainteance)
   */
  Maintenance = 1 << 1,             // 0000000000010

  /**
   * Repairbooks (TecManuals)
   */
  Repairbooks = 1 << 2,             // 0000000000100

  /**
   * Adjustments (TecAdjust)
   */
  Adjustments = 1 << 3,             // 0000000001000

  /**
   * SOS-Books (TecBulletin)
   */
  SOSBooks = 1 << 4,                // 0000000010000

  /**
   * Wirings (TecWirings)
   */
  Wirings = 1 << 5,                 // 0000000100000

  /**
   * Grafical Search (TecGraphics)
   */
  Grafical = 1 << 6,                // 0000001000000

  /**
   * Diagnose (TecDiagnosticValues)
   */
  Diagnose = 1 << 7,                // 0000010000000

  /**
   * Fuse and Relais (TecRelays/Fuses)
   */
  FuseAndRelais = 1 << 8,           // 0000100000000

  /**
   * Component Location (TecComponentLocation)
   */
  ComponentLocation = 1 << 9,       // 0001000000000

  /**
   * Comfortcircuit Diagrams (TecComfortcircuitDiagrams)
   */
  ComfortcircuitDiagrams = 1 << 10, // 0010000000000

  /**
   * Tyre (TecTyres)
   */
  Tyre = 1 << 11,                   // 1000000000000

  /**
   * All
   */
  All = ~(~0 << 13)                 // 1111111111111
}
