<div class="d-flex flex-column h-100">
  <div class="flex-grow-1">
    <chart-panel [panelStyleClass]="'h-100'"
      [chartData]="chartData"
      [chartOptions]="chartOptions"
      [addTourAnchors]="true"
      [headerLabel]="'ARTICLE_VISIBILITY_ARTNOS.HEADER_LABEL' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'ARTICLE_VISIBILITY_ARTNOS.INFO_TEXT' | translate"
      [showTopDropdown]="true"
      [enableExport]="false"
      [selectedTopOptions]="selectedTopOptions">
    </chart-panel>
  </div>
</div>
