<div class="d-flex flex-column h-100">
  <div class="flex-grow-1">

    <!-- Sessions -->
    <chart-panel [panelStyleClass]="'mb-3'"
      [panelStyle]="{'height': '600px'}"
      [selectedChartType]="'line'"
      [chartData]="sessionsChartData"
      [chartOptions]="sessionsChartOptions"
      [headerLabel]="'USER_STATS.SESSIONS_HEADER' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'USER_STATS.SESSIONS_INFO' | translate"
      [showTopDropdown]="false"
      [enableChartTypeToggle]="false"
      [showTimelineButton]="false"
      [timelineDatasets]="sessionsDatasets"
      [(selectedTimelineDatasets)]="sessionsSelectedDatasets"
      (selectedTimelineDatasetsChange)="onSessionsSelectedDatasetsChange($event)"
      [timelineDatasetLimit]="10"
      [showAggregationOptions]="true"
      [showAggregationWeekdaysFilter]="false"
      (exportCsvClick)="onSessionsChartExportCsvClick()">
    </chart-panel>

    <!-- Page views -->
    <chart-panel [panelStyleClass]="'mb-3'"
      [panelStyle]="{'height': '600px'}"
      [selectedChartType]="'bar'"
      [stackedBar]="true"
      [chartData]="pageViewsChartData"
      [chartOptions]="pageViewsChartOptions"
      [headerLabel]="'USER_STATS.PAGE_VIEWS_HEADER' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'USER_STATS.PAGE_VIEWS_INFO' | translate"
      [showTopDropdown]="false"
      [enableChartTypeToggle]="false"
      [showTimelineButton]="false"
      [timelineDatasets]="pageViewsDatasets"
      [(selectedTimelineDatasets)]="pageViewsSelectedDatasets"
      (selectedTimelineDatasetsChange)="onPageViewsSelectedDatasetsChange($event)"
      [timelineDatasetLimit]="10"
      [showAggregationOptions]="true"
      [showAggregationWeekdaysFilter]="false"
      (exportCsvClick)="onPageViewsChartExportCsvClick()">
    </chart-panel>

    <!-- Sessions per Countries -->
    <chart-panel [panelStyleClass]="'mb-3'"
      [panelStyle]="{'height': '600px'}"
      [selectedChartType]="'bar'"
      [stackedBar]="true"
      [chartData]="sessionsPerCountriesChartData"
      [chartOptions]="sessionsPerCountriesChartOptions"
      [headerLabel]="'USER_STATS.SESSIONS_PER_COUNTRY_HEADER' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'USER_STATS.SESSIONS_PER_COUNTRY_INFO' | translate"
      [showTopDropdown]="false"
      [enableChartTypeToggle]="false"
      [showTimelineButton]="false"
      [timelineDatasets]="sessionsPerCountriesDatasets"
      [(selectedTimelineDatasets)]="sessionsPerCountriesSelectedDatasets"
      (selectedTimelineDatasetsChange)="onSessionsPerCountriesSelectedDatasetsChange($event)"
      [timelineDatasetLimit]="10"
      [showAggregationOptions]="false"
      (exportCsvClick)="onSessionsPerCountryChartExportCsvClick()">
    </chart-panel>

    <catalog-users-traffic-compare
      [catalogs]="catalogs"
      [catalogsData]="catalogsData"
      (exportCsvClick)="onUsersTrafficCompareCsvExport($event)">
    </catalog-users-traffic-compare>

  </div>
</div>
