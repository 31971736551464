<div class="d-flex flex-column h-100">
  <div class="flex-grow-1 flex-column">
    <chart-panel [panelStyleClass]="'h-100 mb-3'"
      [panelStyle]="{'maxHeight': '600px'}"
      [stackedBar]="true"
      [chartData]="genartsChartData"
      [chartOptions]="chartOptions"
      [addTourAnchors]="true"
      [headerLabel]="'MARKET_OVERVIEW_TRENDS.HEADER_LABEL_GA' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'MARKET_OVERVIEW_TRENDS.INFO_TEXT_GA' | translate"
      [showTopDropdown]="false"
      [enableChartTypeToggle]="false"
      [showTimelineButton]="true"
      [timelineDatasets]="genartsDatasets"
      [(selectedTimelineDatasets)]="selectedGenartsDatasets"
      (selectedTimelineDatasetsChange)="onSelectedGenartsDatasetsChange($event)"
      [timelineDatasetLimit]="5"
      [showAggregationOptions]="true"
      (chartTypeChange)="onChartTypeChanged($event)"
      (exportCsvClick)="onGenartsExportCsvClick()">
    </chart-panel>
    <chart-panel [panelStyleClass]="'h-100'"
      [panelStyle]="{'maxHeight': '600px'}"
      [stackedBar]="true"
      [chartData]="countriesChartData"
      [chartOptions]="chartOptions"
      [headerLabel]="'MARKET_OVERVIEW_TRENDS.HEADER_LABEL_CTRY' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'MARKET_OVERVIEW_TRENDS.INFO_TEXT_CTRY' | translate"
      [showTopDropdown]="false"
      [enableChartTypeToggle]="false"
      [showTimelineButton]="true"
      [timelineDatasets]="countriesDatasets"
      [(selectedTimelineDatasets)]="selectedCountriesDatasets"
      (selectedTimelineDatasetsChange)="onSelectedCountriesDatasetsChange($event)"
      [timelineDatasetLimit]="5"
      [showAggregationOptions]="true"
      (chartTypeChange)="onChartTypeChanged($event)"
      (exportCsvClick)="onCountriesExportCsvClick()">
    </chart-panel>
  </div>
</div>
