import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ChartData, ChartOptions } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';
import { FacetItem } from '@app/shared/models/facet-item';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { BAR_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';

import { AnalyticsPage } from '../analytics-page';
import { AnalyticsDetailsPageQueryParams } from '../../models/details-page-query-params';
import { AnalyticsNavigationUtils } from '../../utils/navigation';
import { TextSearchUtils } from '../../utils/text-search';
import { CustomTooltipsUtils } from '../../utils/custom-tooltips';

import { lastValueFrom, map } from 'rxjs';
import { DownloadHandler } from '@app/shared/utils/download-handler';

@Component({
  selector: 'app-article-views-countries-details',
  templateUrl: './article-views-countries-details.component.html',
  styleUrl: './article-views-countries-details.component.scss'
})
export class ArticleViewsCountriesDetailsPage extends AnalyticsPage implements AnalyticsDetailsPageQueryParams, OnChanges {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Country Details';
  }

  get pageStoreKey(): string {
    return 'CountryDetailsComponent';
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    // uncommendted the not needed data to display - should not load
    return [Fields.GENART_NO, /* Fields.GENART_NO_BRANDLESS, */ Fields.ARTICLE_NUMBER, Fields.OE_NUMBER,
    /* Fields.OE_NUMBER_BRANDLESS, */ Fields.VEHICLES, /* Fields.VEHICLES_BRANDLESS */ ];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  //#region AnalyticsDetailsPageQueryParams

  @Input() genartNo?: string;
  @Input() brandNo?: string;
  @Input() timespan?: string;
  @Input() fromDate?: string;
  @Input() toDate?: string;
  @Input() paramsMap: string;
  @Input() countryId?: string;
  @Input() countryName?: string;
  @Input() fieldName?: string;

  //#endregion

  /**
   * Collection representation of `paramsMap`.
   */
  parsedParams: {[key: string]: string[]};

  private searchParams?: {[key: string]: string[]};

  private genartsTotalFound: number;
  private articleNumbersTotalFound: number;
  private oeNumbersTotalFound: number;
  private vehiclesTotalFound: number;

  private genartsOriginalData: FacetItem[];
  private articleNumbersOriginalData: FacetItem[];
  private oeNumbersOriginalData: FacetItem[];
  private vehiclesOriginalData: FacetItem[];

  genartsChartOptions: ChartOptions;
  articleNumbersChartOptions: ChartOptions;
  oeNumbersChartOptions: ChartOptions;
  vehiclesChartOptions: ChartOptions;

  genartsChartData: ChartData;
  articleNumbersChartData: ChartData;
  oeNumbersChartData: ChartData;
  vehiclesChartData: ChartData;


  constructor(
    private translate: TranslateService,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
  ) {
    super(tracker, confirmation, api, router);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.parsedParams = AnalyticsNavigationUtils.getDeserializedParamsMap(this.paramsMap);
    await this.requestData();
    this.setupCharts();
  }

  private getChartOptionsForChart(chart:  'genarts' | 'articleNumbers' | 'oeNumbers' | 'vehicles'): ChartOptions {
    return {
      ...BAR_CHART_DEFAULT_OPTIONS,
      indexAxis: 'y',
      plugins: {
        datalabels: {
          display: true,
          anchor: 'start',
          align: 'left',
          offset: -60,
          color: 'black',
          formatter: (value, context) => `${((value / this.getTotalFoundForChart(chart)) * 100).toFixed(2)}%`
        },
        legend: {
          display: false,
        },
      },
    }
  }

  private getChartRequest(facets?: string[]): ChartRequest {
    if (!facets) {
      facets = this.pageFacets;
    }

    const filterData = this.getFilterDataFromQueryParams();

    const reportChartRequest = new ChartRequest('article_selection');
    reportChartRequest.params.set('location_country', [this.countryId]);
    if (this.searchParams) {
      Object.entries(this.searchParams).forEach(([key, value]) => {
        reportChartRequest.params.set(key, value);
      });
    }
    reportChartRequest.setParams(filterData, facets);

    return reportChartRequest;
  }

  private async requestData() {
    return lastValueFrom(this.api.getReport(this.reportPath, this.getChartRequest())
      .pipe(
        map(data => {
          const genartsFacets = data.facets.get('genart_id');
          this.genartsOriginalData = genartsFacets.originalData;
          this.genartsTotalFound = genartsFacets.numFound;

          const articleNumbersFacets = data.facets.get('brand_article_number');
          this.articleNumbersOriginalData = articleNumbersFacets.originalData;
          this.articleNumbersTotalFound = articleNumbersFacets.numFound;

          const oeNumbersFacets = data.facets.get('oe_number');
          this.oeNumbersOriginalData = oeNumbersFacets.originalData;
          this.oeNumbersTotalFound = oeNumbersFacets.numFound;

          const vehiclesFacets = data.facets.get('linking_target_id');
          this.vehiclesOriginalData = vehiclesFacets.originalData;
          this.vehiclesTotalFound = vehiclesFacets.numFound;
        })
      )
    );
  }

  private setupCharts() {
    // Charts options
    this.genartsChartOptions = this.getChartOptionsForChart('genarts');
    this.articleNumbersChartOptions = this.getChartOptionsForChart('articleNumbers');
    this.oeNumbersChartOptions = this.getChartOptionsForChart('oeNumbers');
    this.vehiclesChartOptions = this.getChartOptionsForChart('vehicles');

    // Custom tooltips
    this.articleNumbersChartOptions.plugins.tooltip = {
      callbacks: {
        title: (tooltipItems) =>
          tooltipItems.map(tooltipItem => {
            const data = this.articleNumbersOriginalData[tooltipItem.dataIndex]
            return `${data.brandName} ${data.label}`;
          }),
      }
    };

    this.oeNumbersChartOptions.plugins.tooltip = {
      callbacks: {
        title: (tooltipItems) =>
          tooltipItems.map(tooltipItem => {
            const data = this.oeNumbersOriginalData[tooltipItem.dataIndex]
            return `${data.label} (${data.articleCount} ${this.translate.instant('COMMON.TOOLTIP_MATCH_ARTICLES')})`;
          }),
      }
    };

    this.vehiclesChartOptions.plugins.tooltip = {
      enabled: false,
      position: 'nearest',
      external: (context) => CustomTooltipsUtils.setupVehicleDetailsTooltip(context, this.vehiclesOriginalData)
    };

    // Charts data
    this.genartsChartData = this.getChartData(this.genartsOriginalData, (facet) => `[${facet.id}] ${facet.label}`);
    this.articleNumbersChartData = this.getChartData(this.articleNumbersOriginalData);
    this.oeNumbersChartData = this.getChartData(this.oeNumbersOriginalData);
    this.vehiclesChartData = this.getChartData(
      this.vehiclesOriginalData,
      (facet) => `${facet.details?.['manufacturer_name']} ${facet.details?.['model_name']} ${facet.details?.['types_name']?.split(' (')[0]} [${facet.id}]`
    );
  }

  private getChartData(data: FacetItem[], labelFn?: (facet: FacetItem) => string): ChartData {
    if (!data?.length) {
      return null;
    }

    return {
      labels: data.map(x => labelFn ? labelFn(x) : x.label),
      datasets: [
        {
          data: data.map(x => x.count)
        },
      ],
    };
  }

  /**
   * Returns an `AnalyticsFilterData` object with the fulfilled data,
   * according to query params values.
   *
   * @returns an `AnalyticsFilterData` object with the fulfilled data,
   * according to query params values.
   */
  private getFilterDataFromQueryParams(): AnalyticsFilterData {
    const isTimespanRange = !this.timespan?.length;

    return {
      brands: this.brandNo?.split(',').map(x => +x),
      genarts: this.genartNo?.split(',').map(x => ({id: +x, label: undefined})),
      timespan: {
        isRange: isTimespanRange,
        dates: isTimespanRange
          ? [this.fromDate, this.toDate]
          : this.timespan,
      },
      locations: this.parsedParams?.['location_country'],
      datasources: this.parsedParams?.['datasource'],
    };
  }

  private getTotalFoundForChart(chart: 'genarts' | 'articleNumbers' | 'oeNumbers' | 'vehicles'): number {
    switch (chart) {
      case 'genarts':
        return this.genartsTotalFound;

      case 'articleNumbers':
        return this.articleNumbersTotalFound;

      case 'oeNumbers':
        return this.oeNumbersTotalFound;

      case 'vehicles':
        return this.vehiclesTotalFound;
    }
  }

  async onArticleNumbersSearchStart(term: string) {
    this.searchParams = term?.length
      ? {'search.article_number': [TextSearchUtils.getWildcardEscapedString(term)]}
      : undefined;
    await this.requestData();
    this.setupCharts();
  }

  async onOeNumbersSearchStart(term: string) {
    this.searchParams = term?.length
      ? {'search.oe_number': [TextSearchUtils.getWildcardEscapedString(term)]}
      : undefined;
    await this.requestData();
    this.setupCharts();
  }

  async onVehiclesSearchStart(term: string) {
    this.searchParams = term?.length
      ? {'search.linking_target_id': [TextSearchUtils.getWildcardEscapedString(term)]}
      : undefined;
    await this.requestData();
    this.setupCharts();
  }

  onExportCsvClick(facets: string[]) {
    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getChartRequest(facets));
  }
}
