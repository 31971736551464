import { AnalyticsFilterData } from "@app/shared/components/analytics-filter/analytics-filter-data";

export class AnalyticsNavigationUtils {
  /**
   * Returns the string representation of the `AnalyticsFilterData` values.
   *
   * The different params are separated by '::', and each param is a key-value(s)
   * pair, separated by ':'. If a pair has multiple values, they must be separated
   * by a comma ','.
   * E.g: datasource:2,4::brandno:123 --> { datasource: [2, 4], brandno: [123] }
   */
  static getSerializedParamsMap(filterSnapshot: AnalyticsFilterData): string {
    const params: {[key: string]: string[]} = {};

    if (filterSnapshot.datasources?.length) {
      params['datasource'] = Array.from(filterSnapshot.datasources);
    }

    if (filterSnapshot.locations?.length) {
      params['location_country'] = Array.from(filterSnapshot.locations);
    }

    return Object.entries(params).map(([key, value]) => `${key}:${value.join(',')}`).join('::');
  }

  /**
   * Fulfills the map of query params, parsing them from string format
   * to data structured map.
   *
   * The different params are separated by '::', and each param is a key-value(s)
   * pair, separated by ':'. If a pair has multiple values, they must be separated
   * by a comma ','.
   * E.g: datasource:2,4::brandno:123 --> { datasource: [2, 4], brandno: [123] }
   */
  static getDeserializedParamsMap(paramsMap: string): {[key: string]: string[]} {
    const parsedParams: {[key: string]: string[]} = {};
    const paramsFromMap = paramsMap.split('::');
    paramsFromMap.forEach(param => {
      const [key, value] = param.split(':');
      parsedParams[key] = value.split(',');
    });

    return parsedParams;
  }
}
