import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ActiveElement, Chart, ChartData, ChartEvent, ChartOptions } from 'chart.js';
import moment from 'moment';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';
import { ChartData as AnalyticsChartData } from '@app/shared/models/analytics/chart-data';
import { Brand } from '@app/shared/models/brand';
import { BrandRecommends } from '@app/shared/models/brand-recommends';
import { GenartRecommends } from '@app/shared/models/genart-recommends';
import { FacetItem } from '@app/shared/models/facet-item';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { Converter } from '@app/shared/utils/converter';
import { ChartType, OptionalChartPanelData } from '@app/shared/components/chart-panel/chart-panel.component';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { BAR_CHART_DEFAULT_OPTIONS, DEFAULT_BACKGROUND_COLORS, DOUGHNUT_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';

import { AnalyticsPage } from '../analytics-page';
import { AnalyticsNavigationUtils } from '../../utils/navigation';
import { AnalyticsDetailsPageQueryParams } from '../../models/details-page-query-params';

import { Subject, combineLatest, forkJoin, map, mergeMap, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-article-views-article-numbers-details',
  templateUrl: './article-views-article-numbers-details.component.html',
  styleUrl: './article-views-article-numbers-details.component.scss'
})
export class ArticleViewsArticleNumbersDetailsPage extends AnalyticsPage implements AnalyticsDetailsPageQueryParams, OnChanges {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Most popular article number details';
  }

  get pageStoreKey(): string {
    return 'ArticlenumbersDetailsComponent';
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.FOUND_BY];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  //#region AnalyticsDetailsPageQueryParams

  @Input() genartNo?: string;
  @Input() brandNo?: string;
  @Input() timespan?: string;
  @Input() fromDate?: string;
  @Input() toDate?: string;
  @Input() paramsMap: string;
  @Input() articleNumber?: string;

  //#endregion

  /**
   * Subject that emits an event on `ngOnDestroy`.
   */
  private onDestroy$ = new Subject<void>();

  /**
   * Hierarchical level of the charts.
   */
  private readonly visibleChartsDataLevels: {[key: string]: number} = {
    'found_by_article': 0,
    'found_by_article_search': 1,
    'found_by_vehicle': 1,
    'found_by_number_plate': 2,
  };

  private readonly visibleChartsDataHeaderLabels: {[key: string]: string} = {
    'found_by_article': this.translate.instant('COMMON.FOUND_BY_TOP_LEVEL'),
    'found_by_article_search': this.translate.instant('COMMON.FOUND_BY_ARTICLE_SEARCH'),
    'found_by_vehicle': this.translate.instant('COMMON.FOUND_BY_VEHICLE'),
    'found_by_number_plate': this.translate.instant('COMMON.FOUND_BY_NUMBER_PLATE'),
  };

  get foundByInfoText(): string {
    return `${this.articleData?.brand} ${this.articleData?.artNo} ${this.articleData?.description}`;
  }

  /**
   * Collection representation of `paramsMap`.
   */
  parsedParams: {[key: string]: string[]};

  private totalFound: number;

  articleData: {
    brand: string,
    artNo: string,
    description: string,
    imgUrl: string,
  };

  /**
   * Flag to determine if the brands and generic article recommendations
   * related to the selected article are loading.
   */
  areRecommendationsLoading = true;

  /**
   * Charts data indexed by facet ID.
   */
  private chartsData: {
    [facetId: string]: OptionalChartPanelData<number>
  };

  /**
   * List of charts data which should be rendered. The position in the array
   * determines the order in which charts are shown.
   */
  visibleChartsData: OptionalChartPanelData<number>[] = [];

  /**
   * Chart data for brand recommendations related to selected article.
   */
  brandsRecommendationChartData: ChartData;

  /**
   * Chart data for generic article recommendations related to selected article.
   */
  genartsRecommendationChartData: ChartData;

  /**
   * Chart options for selected article details charts.
   */
  chartOptions: ChartOptions = {
    ...DOUGHNUT_CHART_DEFAULT_OPTIONS,
    parsing: {
      key: 'nested.value',
    },
    onHover: (event, elements) => {
      event.native.target['style'].cursor = elements?.length ? 'pointer' : 'default';
    },
    onClick: (event, elements) => {
      if (!elements?.length) {
        return;
      }

      const datasetIndex = elements[0].datasetIndex;
      const dataIndex = elements[0].index;
      const clickedData = (event['chart'] as Chart).data.datasets[datasetIndex].data[dataIndex];

      // Find the related chart data and hierarchical level
      const chartDataKey = `found_by_${clickedData['id']}`;
      const selectedChartData = this.chartsData?.[chartDataKey];
      const selectedChartDataLevel = this.visibleChartsDataLevels?.[chartDataKey];
      if (!selectedChartData || !selectedChartDataLevel){
        return;
      }

      // Add the selected data to new or existing level
      if (this.visibleChartsData.length <= selectedChartDataLevel) {
        this.visibleChartsData.push(selectedChartData)
      } else {
        this.visibleChartsData[selectedChartDataLevel] = selectedChartData;
        this.visibleChartsData.splice(selectedChartDataLevel + 1); // Remove deeper level charts if exist.
      }
      this.cd.detectChanges();
    },
    plugins: {
      legend: {
        position: 'bottom',
        onClick: (e) => e.native.preventDefault() // Omit default behavior of toggle a value
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const tooltip = tooltipItems?.[0];
            if (!tooltip) {
              return;
            }

            const tooltipFragments = tooltip.label.split(' ');
            const countIndex = tooltipFragments.length - 2;
            tooltipFragments.splice(countIndex, 1);
            tooltip.label = tooltipFragments.join(' ');
          }
        }
      }
    }
  };

  /**
   * Chart options for recommendation charts when their type is Bar chart.
   */
  private readonly recommendationsBarChartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    indexAxis: 'y',
    plugins: {
      datalabels: {
        display: true,
        anchor: 'start',
        align: 'left',
        offset: -60,
        color: 'black',
        formatter: (value, context) => `${value.toFixed(2)}%`
      },
      legend: {
        display: false,
      },
    },
    onHover: (event, elements) => {
      event.native.target['style'].cursor = elements?.length ? 'pointer' : 'default';
    },
  }

  /**
   * Chart options for recommendation charts when their type is Doughnut chart.
   */
  private readonly recommendationsPieChartOptions: ChartOptions = {
    ...DOUGHNUT_CHART_DEFAULT_OPTIONS,
    onHover: (event, elements) => {
      event.native.target['style'].cursor = elements?.length ? 'pointer' : 'default';
    },
  }

  /**
   * Snapshot of reference data about all available brands.
   */
  private allBrandsSnapshot: Brand[];

  /**
   * Snapshot of reference data about all available generic articles.
   */
  private allGenartsSnapshot: {[key: number]: string};

  /**
   * Snapshot of the brand recommendations for the selected article.
   */
  private brandRecommendationsSnapshot: (Brand & BrandRecommends)[];

  /**
   * Snapshot of the generic article recommendations for the selected article.
   */
  private genartsRecommendationsSnapshot: (GenartRecommends & {genartDesc: string})[];

  /**
   * Chart options for the brand recommendations chart.
   */
  brandsRecommendationChartOptions: ChartOptions = {
    ...this.recommendationsBarChartOptions,
    onClick: (event, elements) => this.onRecommendationsChartClick('brands', event, elements),
  };

  /**
   * Chart options for the generic article recommendations chart.
   */
  genartsRecommendationChartOptions: ChartOptions = {
    ...this.recommendationsBarChartOptions,
    onClick: (event, elements) => this.onRecommendationsChartClick('genarts', event, elements),
  };

  /**
   * Event handler for clicks on the recommendations charts.
   *
   * @param type the type of recommendations chart being clicked: `'brands'` for brands recommendations chart,
   * `'genarts'` for generic article recommendations chart.
   * @param event the chart click event.
   * @param elements the list of chart elements targeted by the click event.
   */
  private onRecommendationsChartClick = (type: 'brands' | 'genarts', event: ChartEvent, elements: ActiveElement[]) => {
    const dataIndex = elements[0].index;

    switch (type) {
      case 'brands':
        this.onBrandRecommendationClick(this.brandRecommendationsSnapshot[dataIndex])
        break;
      case 'genarts':
        this.onGenartRecommendationClick(this.genartsRecommendationsSnapshot[dataIndex]);
    }
  }

  /**
   * Flag to determine if the recommendations details chart dialog is visible or not.
   */
  showChartDialog: boolean = false;

  /**
   * Header text for the recommendations details chart dialog.
   */
  chartDialogHeader: string;

  /**
   * Chart panel header text inside of the recommendations details chart dialog.
   */
  chartDialogChartPanelHeaderLabel: string;

  /**
   * Tooltip text inside of the recommendations deatils chart dialog.
   */
  chartDialogInfoText: string;

  /**
   * Chart options for the recommendations details chart, inside dialog.
   */
  chartDialogChartOptions: ChartOptions = {
    ...DOUGHNUT_CHART_DEFAULT_OPTIONS
  };

  /**
   * Chart data for the recommendations details chart, inside dialog.
   */
  chartDialogChartData: ChartData;

  constructor(
    private translate: TranslateService,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private cd: ChangeDetectorRef,
  ) {
    super(tracker, confirmation, api, router);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.parsedParams = AnalyticsNavigationUtils.getDeserializedParamsMap(this.paramsMap);
    this.requestData();
    this.requestRecommendations();
  }

  override ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private getReportChartRequest(facetId?: string): ChartRequest {
    const pageFacets = facetId
      ? [facetId]
      : this.pageFacets;
    const reportChartRequest = new ChartRequest('article_selection');
    reportChartRequest.params.set('brand_article_number', [`${this.brandNo} / ${this.articleNumber}`]);
    reportChartRequest.setParams(this.getFilterDataFromQueryParams(), pageFacets, -1);

    return reportChartRequest;
  }

  /**
   * Requests report data about "found by" info of the selected article,
   * and sets it up on the chart(s).
   */
  private requestData() {
    const articleDetailsChartRequest = new ChartRequest('article_selection');
    const today = moment().format('YYYY-MM-DD');
    articleDetailsChartRequest.setParams({
      brands: [+this.brandNo],
      timespan: {
        isRange: true,
        dates: [today, today]
      },
      datasources: undefined,
      genarts: undefined,
      locations: undefined,
    }, []);

    combineLatest([
      this.api.getReport(this.reportPath, this.getReportChartRequest()),
      this.api.getArticleDetails(articleDetailsChartRequest, this.articleNumber),
    ])
    .subscribe(([reportData, articleDetails]) => {
      if (articleDetails.status === 200 && articleDetails.articles?.length === 1) {
        // Fullfill article details
        const article = articleDetails.articles[0];
        this.articleData = {
          artNo: article.articleNumber,
          brand: article.mfrName,
          description: `${article.genericArticles[0].genericArticleDescription} ${article.misc.additionalDescription}`,
          imgUrl: article.images[0].imageURL200,
        };
      }

      const allDetailsData = reportData.facets.get('found_by');
      this.totalFound = allDetailsData.numFound;

      const originalChartsData: { [facetId: string]: AnalyticsChartData } = {};
      this.chartsData = {};

      reportData.facets.forEach((value, key) => {
        if (key === 'found_by') {
          return;
        }
        originalChartsData[key] = value;
      });

      Object.entries(originalChartsData).forEach(([key, detailsData]) => {
        this.chartsData[key] = {
          chartData: {
            labels: detailsData.originalData.map(x => `${x.name} ${x.count.toLocaleString('de-DE')} (${((x.count / this.totalFound) * 100).toFixed(2)}%)`),
            datasets: [
              {
                data: detailsData.originalData.map(x => ({ nested: {value: x.count}, id: x.id })),
                parsing: {
                  key: 'nested.value'
                },
                backgroundColor: DEFAULT_BACKGROUND_COLORS,
              },
            ],
          },
          headerLabel: `${this.translate.instant('COMMON.FOUND_BY_PREFIX')} ${this.visibleChartsDataHeaderLabels[key]}`,
          infoText: this.foundByInfoText,
          facetId: key,
        };
      });

      this.visibleChartsData.push(this.chartsData['found_by_article']);
    });
  }

  /**
   * Requests both brand and generic article recommendations data related
   * to the selected article, and set up the respective charts.
   */
  private requestRecommendations() {
    combineLatest([
      forkJoin([
        this.api.getAllBrands(),
        this.api.getBrandAssociations(+this.brandNo, this.articleNumber, undefined, false),
      ]),
      forkJoin([
        this.api.getResources('genericArticles2'),
        this.api.getGenericArticleAssociations(+this.brandNo, this.articleNumber, undefined, false),
      ]),
    ])
    .pipe(
      takeUntil(this.onDestroy$), // Cancel requests when onDestroy is invoked
      mergeMap(([[allBrands, brandsRecommendations], [allGenarts, genartsRecommendations]]) => {
        this.allBrandsSnapshot = allBrands;
        this.allGenartsSnapshot = allGenarts;

        const brandRecommendationsFull = brandsRecommendations.recommendations.map(rec => ({
          ...rec,
          ...allBrands.find(b => b.brandId === rec.brand),
        }));

        const genartRecommendationsFull = genartsRecommendations.recommendations.map(rec => ({
          ...rec,
          genartDesc: <string>allGenarts[rec.genart],
        }));

        return combineLatest([of(brandRecommendationsFull), of(genartRecommendationsFull)]);
      }),
    )
    .subscribe(([brandsRecommendations, genartsRecommendations]) => {
      this.brandRecommendationsSnapshot = brandsRecommendations;
      this.genartsRecommendationsSnapshot = genartsRecommendations;

      this.brandsRecommendationChartData = {
        datasets: [
          {
            data: brandsRecommendations.map(b => b.percent),
          }
        ],
        labels: brandsRecommendations.map(b => `${b.brandName} [${b.brandId}]`)
      };

      this.genartsRecommendationChartData = {
        datasets: [
          {
            data: genartsRecommendations.map(b => b.percent),
          }
        ],
        labels: genartsRecommendations.map(b => `${b.genartDesc} [${b.genart}]`)
      };

      this.areRecommendationsLoading = false;
    });
  }

  /**
   * Returns an `AnalyticsFilterData` object with the fulfilled data,
   * according to query params values.
   *
   * @returns an `AnalyticsFilterData` object with the fulfilled data,
   * according to query params values.
   */
  private getFilterDataFromQueryParams(): AnalyticsFilterData {
    const isTimespanRange = !this.timespan?.length;

    return {
      brands: this.brandNo?.split(',').map(x => +x),
      genarts: this.genartNo?.split(',').map(x => ({id: +x, label: undefined})),
      timespan: {
        isRange: isTimespanRange,
        dates: isTimespanRange
          ? [this.fromDate, this.toDate]
          : this.timespan,
      },
      locations: this.parsedParams?.['location_country'],
      datasources: this.parsedParams?.['datasource'],
    };
  }

  /**
   * Define the colors for a chart
   *
   * @param chartData the chart data object, on which the `backgroundColor` is modified.
   * @param chartType the cart type.
   */
  private setChartColors(chartData: ChartData, chartType: ChartType) {
    switch (chartType) {
      case 'bar':
        chartData.datasets[0].backgroundColor = '#9ad0f5';
        break;

      case 'doughnut':
        chartData.datasets[0].backgroundColor = DEFAULT_BACKGROUND_COLORS;
        break;
    }
  }

  /**
   * Event triggered when the brands recommendations chart type changes.
   * @param chartType the new chart type.
   */
  onBrandsRecommendationChartTypeChange(chartType: ChartType) {
    this.brandsRecommendationChartOptions = chartType === 'doughnut'
      ? this.recommendationsPieChartOptions
      : this.recommendationsBarChartOptions;
    this.brandsRecommendationChartOptions.onClick = (event, elements) =>
      this.onRecommendationsChartClick('brands', event, elements);
    this.setChartColors(this.brandsRecommendationChartData, chartType);
  }

  /**
   * Event triggered when the generic article recommendations chart type changes.
   * @param chartType the new chart type.
   */
  onGenartsRecommendationChartTypeChange(chartType: ChartType) {
    this.genartsRecommendationChartOptions = chartType === 'doughnut'
      ? this.recommendationsPieChartOptions
      : this.recommendationsBarChartOptions;
    this.genartsRecommendationChartOptions.onClick = (event, elements) =>
      this.onRecommendationsChartClick('genarts', event, elements);
    this.setChartColors(this.genartsRecommendationChartData, chartType);
  }

  /**
   * Event triggered when a brands recommendations chart value is clicked.
   *
   * It'll open a dialog with a chart about generic article recommendations
   * related to the clicked brand.
   *
   * @param brand the clicked element associated data.
   */
  onBrandRecommendationClick(brand: Brand & BrandRecommends) {
    this.api.getGenericArticleAssociations(+this.brandNo, this.articleNumber, brand.brandId)
      .subscribe(data => {
        const genartsRecommendationsForBrand = data.recommendations.map(x => ({...x, genartDesc: this.allGenartsSnapshot[x.genart]}));

        this.chartDialogHeader = `${brand.brandName} [${brand.brandId}]`;
        this.chartDialogChartPanelHeaderLabel = this.translate.instant('ARTICLE_VIEWS_ARTNO_DETAILS.RECC_DRILL_HEADER_BRAND');
        this.chartDialogInfoText = `${this.translate.instant('ARTICLE_VIEWS_ARTNO_DETAILS.RECC_DRILL_INFO_TEXT_BRANDS')}: ${brand.brandName}`;
        this.chartDialogChartData = {
          datasets: [
            {
              data: genartsRecommendationsForBrand.map(x => x.percent),
            }
          ],
          labels: genartsRecommendationsForBrand.map(x => `${x.genartDesc} [${x.genart}]`),
        };

        this.showChartDialog = true;
      });
  }

   /**
   * Event triggered when a generic article recommendations chart value is clicked.
   *
   * It'll open a dialog with a chart about brand recommendations
   * related to the clicked generic article.
   *
   * @param brand the clicked element associated data.
   */
  onGenartRecommendationClick(genart: GenartRecommends & {genartDesc: string}) {
    this.api.getBrandAssociations(+this.brandNo, this.articleNumber, genart.genart)
      .subscribe(data => {
        const brandsRecommendationsForGenart = data.recommendations.map(x => ({...x, ...this.allBrandsSnapshot.find(y => y.brandId === x.brand)}));

        this.chartDialogHeader = `${genart.genartDesc} [${genart.genart}]`;
        this.chartDialogChartPanelHeaderLabel = this.translate.instant('ARTICLE_VIEWS_ARTNO_DETAILS.RECC_DRILL_HEADER_GENART');
        this.chartDialogInfoText = `${this.translate.instant('ARTICLE_VIEWS_ARTNO_DETAILS.RECC_DRILL_INFO_TEXT_GENART')}: ${genart.genartDesc}`;
        this.chartDialogChartData = {
          datasets: [
            {
              data: brandsRecommendationsForGenart.map(x => x.percent),
            }
          ],
          labels: brandsRecommendationsForGenart.map(x => `${x.brandName} [${x.brandId}]`),
        };

        this.showChartDialog = true;
      });
  }

  onReportExportCsvClick(facetId: string) {
    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getReportChartRequest(facetId));
  }

  onExportDetailedReccClick(event: MouseEvent) {
    this.api.getAssociationsTable(+this.brandNo, this.articleNumber)
      .pipe(
        map(data => data.recommendations),
        map(recommendations => recommendations.map(rec => {
          const brand = this.allBrandsSnapshot.find(x => x.brandId === rec.brand);
          const genart = this.allGenartsSnapshot[rec.genart];

          return <FacetItem>{
            id: `${rec.brand}`,
            name: brand.brandName,
            label: `${brand.brandName} [${rec.brand}]`,
            count: rec.percent,
            percent: rec.percent,
            details: {
              genArtId: `${rec.genart}`,
              genArtName: genart,
              genArtLabel: `${genart} [${rec.genart}]`,
            },
          };
        }))
      ).subscribe(facets => {
        this.exportAsCsv(
          facets,
          `recommendations_${this.brandNo}_${this.articleNumber}`,
          ['id', 'name', 'genArtId', 'genArtName', 'percent'],
        );
      });
  }

  onBrandsReccExportCsvClick() {
    const brandsReccFacets = Converter.convertBrandRecoms(this.api.allBrandMap, { recommendations: this.brandRecommendationsSnapshot });
    this.exportAsCsv(
      brandsReccFacets,
      `brand_associations_${this.brandNo}_${this.articleNumber}`,
      ['id', 'name', 'percent'],
    );
  }

  onGenartsReccExportCsvClick() {
    const genartsReccFacets = Converter.convertGenartRecoms(this.allGenartsSnapshot, { recommendations: this.genartsRecommendationsSnapshot });
    this.exportAsCsv(
      genartsReccFacets,
      `genart_associations_${this.brandNo}_${this.articleNumber}`,
      ['id', 'name', 'percent'],
    );
  }
}
