import { Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule , FormBuilder, FormControl, Validators } from '@angular/forms';

import { Table } from 'primeng/table';
import { Listbox } from 'primeng/listbox';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { User } from '@app/shared/models/user';
import { Brand } from '@app/shared/models/brand';
import { Features } from '@app/shared/models/features';
import { UserRole } from "@app/shared/models/user-role";
import { TrackerService, UtilsService } from '@app/core';
import { FacetItem } from '@app/shared/models/facet-item';
import { PageComponent } from '@app/shared/components/page';
import { ApiService } from '@app/shared/services/api.service';
import { UserRoleDTO } from '@app/shared/models/user-role-dto';
import { UserSettings } from '@app/shared/models/user-settings';
import { BrandFilter } from '@app/shared/models/settings/brand-filter';
import { SaveUserResponse } from '@app/shared/models/save-user-response';
import { DatasourcesResponse } from '@app/shared/models/settings/datasources-response';
import { PasswordValidationResult } from '@app/shared/models/password-validation-result';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class AdminUsersPage extends PageComponent {
  get pageName(): string {
    return 'User list';
  }

  hasChanges(): boolean {
    return this.changed;
  }

  _pleaseSelect: { label: string, value: string } = { label: ' --- please select --- ', value: '' };
  _optionSelectAll = { label: 'all', value: '' };

  filteredvalues: any[] = [];

  userTableData: User[] = [];
  userList: User[] = [];
  selectedUser: User;

  user_main_fields: string[] = ['userName', 'email', 'isActive'];
  user_settings_fields: string[] = ['userRole', 'brandFilterId', 'features', 'datasource'];

  brandFilters: SelectItem[]=[];
  brandFiltersIdMap: Map<string, BrandFilter> = new Map();
  brandNamesMap: Map<number, string>;
  brandFiltersFilter: SelectItem[];

  datasources: SelectItem[];
  features: SelectItem[];

  allUsers: User[] = [];
  userRoles: any[] = [];
  userRolesSelect: any[]= [];

  statusFilterOptions: any[];

  sortField: string = 'userName';
  sortAsc: boolean = true;
  defaultSortValue: string = '';

  countDeleteMarkedUsers = 0;

  isRefreshingFilter = false;

  changed = false;

  showEditUserDialog: boolean = false;
  showNewUserDialog: boolean = false;

  editUserform: FormGroup;
  newUserform: FormGroup;
  originalUser: FormGroup;

  errorMessage: string;
  isRequesting: boolean = false;

  passwordValidationResult: PasswordValidationResult;

  dialogStyle = {
    height: '400px',
    'min-height': '400px',
    'max-height': '400px',
    width: '400px',
    'min-width': '400px',
  };

  constructor(
    translate: TranslateService,
    private fb: FormBuilder,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.disableFilter();

    this.initFormControls();
    this.selectedUser = this.newUser();
  }

  override ngOnInit(): void {
    this.loadDataSources();
    this.loadBrandFilters();
    this.loadBrandNames();
    this.loadStatusFilter()
    this.loadRoles();
    this.loadUsers();
    this.loadFeatures();
  }

  private loadUsers() {
    this.api.getUsers().subscribe(
      (data: User[]) => {
        this.userList = data;
        this.userTableData = data.map(item => ({
          ...item,
          status: this.getUserStatus(item)
        }));
        this.sortByField(this.sortField, true);

        this.checkCountDeleted();
      });
  }

  private loadRoles() {
    const allRoles = [];
    this.api.getUserRoles().subscribe((roles: UserRoleDTO[]) => {
      for (const r of roles) {
        allRoles.push({ label: r.label, value: r.key });
      }
      allRoles.sort((a, b) => a.label.localeCompare(b.label));

      this.userRolesSelect = [this._pleaseSelect].concat(allRoles);
      this.userRoles = [this._optionSelectAll].concat(allRoles);
    });
  }

  private loadFeatures() {
    this.api.getFeatures().subscribe((data: FacetItem[]) => {
      const f = []
      for (const fa of data) {
        f.push(
          { label: fa.label, value: fa.id }
        );
      }
      this.features = f;
    });
  }

  loadBrandFilters(event?, pListBox?) {
    this.api.getBrandFilters(true).subscribe((data: BrandFilter[]) => {
      let isRefresh = false;

      if (event) {
        event.preventDefault();
        isRefresh = this.brandFiltersIdMap.size > 0;
        this.isRefreshingFilter = true;
      }

      const bfsAll = [];
      const newBfs = [];
      const bfs = [];

      let brandFiltersIdMap = new Map();

      for (const bf of data) {
        const item = { label: bf.label, value: bf.id };
        if (isRefresh && !this.brandFiltersIdMap.has(bf.id)) {
          item['isNew'] = true;
          newBfs.push(item);
        } else {
          bfs.push(item);
        }
        bfsAll.push(item);
        brandFiltersIdMap.set(bf.id, bf);
      }

      UtilsService.sortArrayIgnoreCase(bfsAll, 'label');
      UtilsService.sortArrayIgnoreCase(newBfs, 'label');
      UtilsService.sortArrayIgnoreCase(bfsAll, 'label');

      this.brandFiltersIdMap = brandFiltersIdMap;
      this.brandFilters = [...newBfs, ...bfs];
      this.brandFiltersFilter = [this._optionSelectAll].concat(bfsAll);
      this.isRefreshingFilter = false;

      if (isRefresh && pListBox) {
        try {
          // scroll to top - where the new filters are ?
          (<Listbox>pListBox).el.nativeElement.childNodes[0].scrollTop = 0;
        } catch (ex) { }
      }
    });

  }

  private loadBrandNames() {
    this.api.getAllBrands().subscribe(
      (data: Brand[]) => {
        this.brandNamesMap = new Map();
        for (const b of data) {
          this.brandNamesMap.set(b.brandId, b.brandName);
        }
      }
    );
  }

  private loadStatusFilter() {
    let StatusFilterOptions= [
      this._optionSelectAll,
      { label: 'active', value: 'active' },
      { label: 'inactive', value: 'inactive' },
      { label: 'logged in', value: 'loggedIn' }];

    if (this.api.isSuperAdmin()) {
      StatusFilterOptions.push(
        { label: 'deleted', value: 'deleted' }
      );
    }

    this.statusFilterOptions = StatusFilterOptions;
  }

  private loadDataSources() {
    this.api.getDatasources().subscribe((data: DatasourcesResponse) => {
      const r = [];
      for (const ds of data.datasources) {
        r.push({ label: ds.name, value: 'DS-' + ds.id });
      }

      for (const site of data.sites) {
        r.push({ label: site.name, value: 'PWK-' + site.siteIdNumeric });
      }

      this.datasources = r;
    });
  }

  getBrandNamesByFilterId(id: string): string {
    if (!id || !this.brandFiltersIdMap.has(id)) {
      return '';
    }

    const bf: BrandFilter = this.brandFiltersIdMap.get(id);
    let r = [];

    for (const brandId of bf.brandIds) {
      r.push(this.brandNamesMap.get(brandId));
    }
    r.sort();
    let bn = "";
    let i = 0;
    let l = 0;
    for (const brandName of r) {
      if (i > 0) {
        bn += ", ";
        l += 2;
      }
      bn += brandName;
      l += ('' + brandName).length;
      i++;
      if (l >= 50) {
        i = 0;
        l = 0;
        bn += "\n";
      }
    }
    return bn;
  }

  getBrandFilterName(id: string): string {
    if (id && this.brandFiltersIdMap && this.brandFiltersIdMap.has(id)) {
      return this.brandFiltersIdMap.get(id).name;
    }
    return ''
  }

  getLastLoginDate(user: User, lastLoginTime = 'never') {
    if (user.timeAgoCalculated) {
      return user.timeAgoCalculated;
    }

    user.timeAgoCalculated = !user.lastLogin ? lastLoginTime :
      UtilsService.calcDaysOrYearsAgo(user.lastLogin) + ' ago';

    return user.timeAgoCalculated;
  }

  getLastLoginTitle(user: User) {
    return !user.lastLogin ? 'Never logged in' :
      'Last login: ' +
      this.getDateFormated(user.lastLogin) + ' at ' +
      user.lastLogin.substring(11, 16)
  }

  private getDateFormated(d) {
    return d.substring(8, 10) + '.' +
      d.substring(5, 7) + '.' +
      d.substring(0, 4);
  }

  getUserStatusIcon(user: User) {
    if (user.deleted) {
      return 'fa fa-trash-o user-inactive';
    }

    if (user.loggedIn) {
      return 'pi pi-check user-inactive';
    }

    if (user.isActive) {
      return 'pi pi-check user-active';
    }

    return 'pi pi-ban user-inactive';
  }

  getStatusTitle(user: User) {
    if (user.deleted) {
      return 'deleted';
    }

    if (user.loggedIn) {
      return 'is currently logged in';
    }

    if (user.isActive) {
      return 'account activated';
    }

    return 'account deactivated';
  }

  private getUserStatus(user: User) {
    if (user.deleted) {
      return 'inactive';
    }

    if (user.loggedIn) {
      return 'loggedIn';
    }

    if (user.isActive) {
      return 'active';
    }

    return 'inactive';
  }

  private getDatasourceNamesByIds(ids: string[]) {
    const r = [];
    for (const ds of this.datasources) {
      if (ids.indexOf(ds.value) != -1) {
        r.push(ds.label);
      }
    }
    return r;
  }

  private newUser() {
    return <User>{
      settings: <UserSettings>{}
    };
  }

  private setDialogSize() {
    this.dialogStyle = {
      height: 'auto',
      'max-height': '' + (window.innerHeight * 0.7) + 'px',
      'min-height': '600px',
      width: '' + (window.innerWidth * 0.5) + 'px',
      'min-width': '1000px',
    };
  }

  private initFormControls() {
    this.editUserform = this.fb.group({
      'userRole': ['', Validators.required],
      'email': ['', [Validators.required, Validators.email]],
      'brandFilterId': [false],
      'isActive': [false],
      'datasource': [false],
      'features': [false]
    });

    this.newUserform = this.fb.group({
      'userRole': ['', Validators.required],
      'userName': ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_\\-\\.@]{3,25}')]],
      'email': ['', [Validators.required, Validators.email]],
      'brandFilterId': [false],
      'isActive': [true],
      'features': [false],
      'datasource': [false]
    });

    this.user_main_fields.forEach(field => {
      this.newUserform.get(field).valueChanges.subscribe(newValue => {
          this.selectedUser[field] = newValue;
        });
      if (field === "userName") {
        // pass
      } else {
        this.editUserform.get(field).valueChanges.subscribe(newValue => {
          this.selectedUser[field] = newValue;
        });
      }
    });

    this.user_settings_fields.forEach(field => {
      this.newUserform.get(field).valueChanges.subscribe(newValue => {
        this.selectedUser.settings[field] = newValue;
      });
      this.editUserform.get(field).valueChanges.subscribe(newValue => {
        this.selectedUser.settings[field] = newValue;
      });
    });
  }

  private startValidation(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (!control.valid) {
        control.markAsDirty();
      }
    });
  }

  private saveUserChanges(user, del?) {
    this.api.saveUser(this.selectedUser).subscribe((u: SaveUserResponse) => {
      this.isRequesting = false;
      if (u.status === 401 && u.passwordValidationResult) {
        this.handlePasswordValidation(u);
        this.errorMessage = null;
        return;
      } else if (u.status !== 200) {
        this.errorMessage = u.statusText;
        return;
      }

      for (let i = 0; i < this.allUsers.length; i++) {
        if (this.allUsers[i].id === u.user.id && !del) {
          this.allUsers[i] = u.user;
          break;
        }
      }

      let updatedUserList: User[] = [];
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].deleted && !this.api.isSuperAdmin()) {
          continue;
        }

        if (this.userList[i].id === u.user.id) {
          if (!del) {
            this.userList[i] = u.user;
          }
          this.userList[i].deleteConfirm = false;
        }
        updatedUserList.push(this.userList[i]);
      }
      this.userList = updatedUserList;

      this.api.clearStoredFilterOptions();
      this.selectedUser = this.newUser();
      this.editUserform.reset();
      this.showEditUserDialog = false;
    });
  }

  private handlePasswordValidation(sr: SaveUserResponse) {
    this.passwordValidationResult = sr.passwordValidationResult;
  }

  private addUser(user) {
    this.api.saveUser(this.selectedUser).subscribe((u: SaveUserResponse) => {
      this.isRequesting = false;
      if (u.status === 401 && u.passwordValidationResult) {
        this.handlePasswordValidation(u);
        this.errorMessage = null;
        return;
      } else if (u.status !== 200) {
        this.errorMessage = u.statusText;
        return;
      }
      this.userList.push(u.user);
      this.userTableData.push(u.user)
      this.selectedUser = this.newUser();
      this.newUserform.reset();
      this.showNewUserDialog = false;
    });
  }

  onSubmit(form: FormGroup) {
    this.startValidation(form);
    if (form.valid) {
      this.isRequesting = true;
      if (form == this.editUserform) {
        this.saveUserChanges(form.value);
      } else
        if (form == this.newUserform) {
          this.addUser(form.value);
        }
    }
  }

  createNewUser() {
    this.newUserform.reset();
    this.selectedUser = this.newUser();
    this.newUserform.patchValue({
      'isActive': true
    });
    this.setDialogSize();
    this.showNewUserDialog = true;
    this.errorMessage = null;
    this.isRequesting = false;
  }

  deleteUser(user: User, event: any) {
    event.stopPropagation();
    const brandFilter = this.getBrandFilterName(user.settings.brandFilterId);

    let deleteMsg = 'delete';
    if (user.deleted) {
      deleteMsg = 'restore';
    }

    let message = 'Do you really want to ' + deleteMsg + ' marked user <b>' + user.userName +
      (brandFilter ? '</b> with brand filter <b>' + brandFilter + '</b> ?' : '');

    user.deleteConfirm = true;

    this.confirmDelete(() => {
      user.deleted = !user.deleted;

      this.selectedUser = this.newUser();
      this.selectedUser.id = user.id;
      this.selectedUser.deleted = user.deleted;

      this.saveUserChanges(user, true);
      this.checkCountDeleted();
    },
      () => {
        user.deleteConfirm = false;
      },
      'Warning',
      message);
  }

  onEditDialogHide() {
    this.selectedUser = this.newUser();
    if (this.editUserform.dirty) {
      this.loadUsers();
      this.editUserform.reset();
      this.editUserform.markAsPristine();
    };
  }

  shouldHiddeDeleteButton(user: User) {
    return user.userName == this.api._auth.userDetail.login
      || user.settings.userRole == UserRole.SUPER_ADMIN;
  }

  exportUsers() {
    var rows = [
      [
        'user_id',
        'user_name',
        'email',
        'user_role',
        'datasources',
        'features',
        'brand_filter',
        'activated',
        'deleted',
        'last_login_relative',
        'last_login_time'
      ]
    ];

    const tableToExport = this.filteredvalues !== undefined ? this.filteredvalues : (this.userTableData !== undefined ? this.userTableData : [])

    for (var user of tableToExport) {
      rows.push(
        [
          this.getString(user.id),
          this.getString(user.userName),
          this.getEmail(user.email),
          this.getString(user.settings.userRole),
          this.getString(this.getDatasourceNamesByIds(user.settings.datasource).join(', ')),
          this.getString(Features.getNamesByIds(user.settings.features).join(', ')),
          this.getString(this.getBrandFilterName(user.settings.brandFilterId)),
          this.getString(user.deleted ? false : user.isActive),
          this.getString(user.deleted),
          this.getString(this.getLastLoginDate(user, '?')),
          this.getString(user.lastLogin)
        ]
      );
    }
    UtilsService.exportToCsv('users.csv', rows);
  }

  handleSelectEdit(event: any) {
    this.setDialogSize();
    this.editUserform.patchValue({
      'userRole': this.selectedUser.settings.userRole,
      'email': this.selectedUser.email,
      'brandFilterId': this.selectedUser.settings.brandFilterId,
      'isActive': this.selectedUser.isActive,
      'datasource': this.selectedUser.settings.datasource,
      'features': this.selectedUser.settings.features
    });
    this.showEditUserDialog = true;
  }

  handleUnselectEdit(event: any) {
    this.selectedUser = this.newUser();
  }

  private getString(v: any): string {
    return typeof v == 'undefined' ? '' : '' + v;
  }

  private getEmail(v: any) {
    const r = this.getString(v);
    if (r.endsWith('@webadmin.tecalliance.net')) {
      return '';
    }
    return r;
  }

  onFilter(event: any) {
    this.filteredvalues = event.filteredValue;
  }

  @ViewChild('dt') dt: Table | undefined;
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt!.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  private sortByField(field, dontChangeDir?) {
    if (!dontChangeDir) {
      this.sortAsc = this.sortField != field ? true : !this.sortAsc;
    }
    this.sortField = field;
    this.defaultSortValue = field == 'lastLogin' ? '1970-01-01T00:00:00Z' : '';
    this.sortUsers(field, this.sortAsc ? 1 : -1, this.defaultSortValue);
  }

  private sortUsers(sortField, dir, defaultValue) {
    this.userList.sort((a: User, b: User) => {

      let aval = a[sortField];
      let bval = b[sortField];

      if (!aval) {
        aval = defaultValue;
      }
      if (!bval) {
        bval = defaultValue;
      }

      if (aval < bval) {
        return -1 * dir;
      }
      if (aval > bval) {
        return 1 * dir;
      }
      return 0;
    });
  }

  private checkCountDeleted() {
    this.countDeleteMarkedUsers = 0;
    for (const u of this.userTableData) {
      if (u.deleted) {
        this.countDeleteMarkedUsers++;
      }
    }
  }
}
