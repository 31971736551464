<div class="d-flex h-100">
  <div class="col-md-3 col-lg-3 col-xl-3 pl-0 pr-0 border border-top-0" id="nav-menu-container" [@mainaside]="_state">
    <nav class="navbar-light">
      <nav-menu [items]="_menuItems" id="side-menu"></nav-menu>
    </nav>
  </div>
  <div class="flex-grow-1 mb-1 overflow-auto" [ngClass]="{ 'p-3': !isHomePage }">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="loading-data-sources">
  <loading></loading>
</div>

<p-confirmDialog key="discart-changes"  acceptButtonStyleClass="default-button mx-1"  rejectButtonStyleClass="default-button mx-1" [closable]="false" ></p-confirmDialog>
<p-confirmDialog key="confirm-actions" [closable]="false" ></p-confirmDialog>
<p-confirmDialog key="warning-message" [closable]="true" [acceptLabel]="'Close'" [rejectVisible]="false"></p-confirmDialog>
