import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import * as services from './services';
import * as interceptors from './interceptors';

import { CoreModuleConfig } from '.';
import { AuthTypes } from './security/auth-types';
import { WebShopContext } from '@app/shared/models/web-shop-context';
import { WebShopUser } from '@app/shared/models/web-shop-user';

const servicesList = [
  services.ContextService,
  services.AuthService,
  services.CisAuthService,
  services.JWTAuthService,
  services.NavigationService,
  services.OAuth2Service,
  services.StorageService,
  services.TaskTrackerService,
  services.TrackerService,
  services.UtilsService,
  services.RequestCacheService,
  services.ReleaseNotesService
];

/**
 * Module with functionalities that are used globally on the application.
 */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ]
})
export class CoreModule {
  static forRoot(options: CoreModuleConfig = {
    authType: AuthTypes.Oauth2,
    contextFactory: null,
    userFactory: null,
  }): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...servicesList,
        {
          provide: CoreModuleConfig,
          useValue: {
            authType: AuthTypes.Cis,
            contextFactory: WebShopContext.factory,
            userFactory: WebShopUser.factory,
          }
        },

        // Interceptors
        {
          provide: HTTP_INTERCEPTORS,
          useClass: interceptors.ApiURLInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: interceptors.HeadersInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: interceptors.CachingInterceptor,
          multi: true
        },
        // APP_INITIALIZER
        {
          provide: APP_INITIALIZER,
          useFactory: CoreInitializer,
          deps: [services.ContextService],
          multi: true
        }
      ]
    };
  }

  constructor (
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}

export function CoreInitializer(context: services.ContextService): () => Promise<any> {
  return async () => context.load();
}
