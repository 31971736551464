import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "@app/core";

/**
 * Guard for analytics module. It should only allow access if the user is logged in.
 * 
 * @param route The actual route
 * @param state The actual route snapshot
 * @returns `true` if the user is logged in (meaning that is allowed to navigate), otherwise redirect to login page.
 */
export const analyticsGuard: CanActivateFn = (route, state) => {
    return inject(AuthService).isLogged
        ? true
        : inject(Router).createUrlTree(['/portal/login']);
}