import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { Listbox } from 'primeng/listbox';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { TaskTrackerService, TrackerService } from '@app/core';
import { ApiService, GrowlService } from '@app/shared';
import { Brand } from '@app/shared/models/brand';
import { BrandFilter } from '@app/shared/models/settings/brand-filter';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';
import { PageComponent } from '@app/shared/components/page';

import { FiltersOverviewDialogComponent } from './components/filters-overview-dialog/filters-overview-dialog.component';

import { combineLatest } from 'rxjs';

type ListboxBrand = Brand & {
  label?: string;
  isAdded?: boolean;
  isRemoved?: boolean;
  disabled?: boolean;
};

@Component({
  selector: 'app-admin-brand-filters',
  templateUrl: './brand-filters.component.html',
  styleUrl: './brand-filters.component.scss'
})
export class AdminBrandFiltersPage extends PageComponent {
  get pageName(): string {
    return 'Brandfilter management';
  }

  override hasChanges(): boolean {
    return this.changed;
  }

  changed = false;
  isEditModeActive = false;
  filterName: string;

  toBeAddedFilter: BrandFilter;
  selectedFilter: BrandFilter;
  selectedFilterBrands: ListboxBrand[] = [];
  availableBrandsforFilter: ListboxBrand[] = [];

  filters: BrandFilter[] = [];
  allBrands: ListboxBrand[] = [];

  @ViewChild('selectedBrands') private selectedBrandsListbox: Listbox;
  @ViewChild('availableBrands') private availableBrandsListbox: Listbox;

  @ViewChild('filtersOverviewDialog') private filtersOverviewDialog: FiltersOverviewDialogComponent;

  get selectedBrandsListboxSelection(): ListboxBrand[] {
    return this.selectedBrandsListbox?.value || [];
  }

  get availableBrandsListboxSelection(): ListboxBrand[] {
    return this.availableBrandsListbox?.value || [];
  }

  constructor(
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
    private growl: GrowlService,
    private translate: TranslateService,
    private taskTracker: TaskTrackerService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.disableFilter();
  }

  override ngOnInit(): void {
    this.taskTracker.add(
      combineLatest([
        this.api.getBrandFilters(true),
        this.api.getAllBrands()
      ])
      .subscribe(([brandFilters, brands]) => {
        this.filters = _.sortBy(brandFilters.filter(x => !x.brandIds.includes(-1)), filter => filter.name.toLowerCase());
        this.allBrands = brands.map(x => ({
          ...x,
          label: `${x.brandName} [${x.brandId}]`,
          disabled: true,
        }));

        this.selectedFilter = this.filters[0];
        this.setBrandsForSelectedFilter();
      })
    );
  }

  isBrandAddedToSelection(brandId: number) {
    return this.selectedFilterBrands.find(b => b.brandId === brandId)
      && !this.selectedFilter.brandIds.includes(brandId);
  }

  isBrandRemovedFromSelection(brandId: number) {
    return this.availableBrandsforFilter.find(b => b.brandId === brandId)
      && this.selectedFilter.brandIds.includes(brandId);
  }

  private setBrandsForSelectedFilter() {
    this.selectedFilterBrands = this.allBrands.filter(b => this.selectedFilter.brandIds.includes(b.brandId));
    this.availableBrandsforFilter = this.allBrands.filter(b => !this.selectedFilter.brandIds.includes(b.brandId));
  }

  private clearListboxSelection(...listboxes: Listbox[]) {
    listboxes.forEach(x => x.writeValue([]));
  }

  private resetListboxStateFlags(...listboxes: ListboxBrand[][]) {
    listboxes.forEach(listbox => {
      listbox.forEach(i => {
        i.isAdded = undefined;
        i.isRemoved = undefined;
      });
    });
  }

  private disableListboxBrands(...listboxes: ListboxBrand[][]) {
    listboxes.forEach(listbox => {
      listbox.forEach(i => {
        i.disabled = true;
      });
    });
  }

  private enableListboxBrands(...listboxes: ListboxBrand[][]) {
    listboxes.forEach(listbox => {
      listbox.forEach(i => {
        i.disabled = false;
      });
    });
  }

  private removeBrandsFromFilter(brands: ListboxBrand[]) {
    brands.forEach(x => {
      if (x.isAdded) {
        x.isAdded = false;
        return;
      }

      x.isRemoved = true;
    });

    this.availableBrandsforFilter = [...this.availableBrandsforFilter, ...brands];
    this.sortAvailableBrands();

    const removedBrandsIds = brands.map(x => x.brandId);
    this.clearListboxSelection(this.selectedBrandsListbox);
    this.selectedFilterBrands = this.selectedFilterBrands.filter(x => !removedBrandsIds.includes(x.brandId));
  }

  private addBrandsToFilter(brands: ListboxBrand[]) {
    brands.forEach(x => {
      if (x.isRemoved) {
        x.isRemoved = false;
        return;
      }

      x.isAdded = true;
    });

    this.selectedFilterBrands = [...this.selectedFilterBrands, ...brands];
    this.sortSelectedBrands();

    const removedBrandsIds = brands.map(x => x.brandId);
    this.clearListboxSelection(this.availableBrandsListbox);
    this.availableBrandsforFilter = this.availableBrandsforFilter.filter(x => !removedBrandsIds.includes(x.brandId));
  }

  private sortSelectedBrands() {
    this.selectedFilterBrands = _.sortBy(this.selectedFilterBrands, 'brandName');
  }

  private sortAvailableBrands () {
    this.availableBrandsforFilter = _.sortBy(this.availableBrandsforFilter, 'brandName');
  }

  private finishEditMode() {
    if (this.toBeAddedFilter) {
      this.filters = this.filters.slice(0, -1);
      this.toBeAddedFilter = undefined;
      this.selectedFilter = this.filters.at(-1);
    }

    this.clearListboxSelection(this.selectedBrandsListbox, this.availableBrandsListbox);
    this.resetListboxStateFlags(this.allBrands);
    this.disableListboxBrands(this.allBrands);
  }

  private checkFilterHasBrands(): boolean {
    if (this.selectedFilterBrands?.length) {
      return true;
    }

    this.growl.showError(
      this.translate.instant('BRAND_FILTERS.NO_BRANDS_TITLE'),
      this.translate.instant('BRAND_FILTERS.NO_BRANDS_MSG'),
    );

    return false;
  }

  private createNewFilter() {
    if (!this.checkFilterHasBrands()) {
      return;
    }

    const filter: BrandFilter = {
      ...this.toBeAddedFilter,
      brandIds: this.selectedFilterBrands.map(x => x.brandId),
      countUsage: 1,
      name: this.filterName,
      label: `${this.filterName} (1)`,
      users: ["CustomerDemo"],
    };

    this.api.saveBrandFilter(filter)
      .subscribe(addedFilter => {
        this.isEditModeActive = !this.isEditModeActive;
        this.finishEditMode();

        const filters = [...this.filters, addedFilter];
        this.filters = _.sortBy(filters, filter => filter.name.toLowerCase());
        this.selectedFilter = addedFilter;

        this.setBrandsForSelectedFilter();
      })
  }

  private saveFilter() {
    if (!this.checkFilterHasBrands()) {
      return;
    }

    const filter: BrandFilter = {
      ...this.selectedFilter,
      brandIds: this.selectedFilterBrands.map(x => x.brandId),
      name: this.filterName,
    }

    this.api.saveBrandFilter(filter)
      .subscribe(savedFilter => {
        this.isEditModeActive = !this.isEditModeActive;
        this.finishEditMode();

        const filters = [...this.filters.filter(x => x.id !== savedFilter.id), savedFilter];
        this.filters = _.sortBy(filters, filter => filter.name.toLowerCase());
        this.selectedFilter = savedFilter;

        this.setBrandsForSelectedFilter();
      })
  }

  private deleteFilter() {
    this.api.deleteBrandFilter(this.selectedFilter)
      .subscribe(response => {
        if (response.statusCode !== 200) {
          return;
        }

        const filters = this.filters.filter(x => x.id !== this.selectedFilter.id);
        this.filters = _.sortBy(filters, filter => filter.name.toLowerCase());
        this.selectedFilter = this.filters[0];

        this.setBrandsForSelectedFilter();

        this.growl.infoMessage(
          this.translate.instant('BRAND_FILTERS.FILTER_DELETED_TITLE'),
          this.translate.instant('BRAND_FILTERS.FILTER_DELETED_MSG'),
          1500
        );
      })
  }

  onAddClick() {
    this.isEditModeActive = true;

    const newFilterName = this.translate.instant('BRAND_FILTERS.NEW_FILTER');
    this.filterName = newFilterName;
    this.toBeAddedFilter = {
      name: newFilterName,
      label: `${newFilterName} (0)`,
      brandIds: [],
    };
    this.filters = [...this.filters, this.toBeAddedFilter];
    this.selectedFilter = this.toBeAddedFilter;
    this.enableListboxBrands(this.allBrands);
    this.setBrandsForSelectedFilter();
  }

  onSaveClick() {
    this.toBeAddedFilter
      ? this.createNewFilter()
      : this.saveFilter();
  }

  onEditClick() {
    this.changed = true;
    this.isEditModeActive = !this.isEditModeActive;

    if (!this.isEditModeActive) {
      this.finishEditMode();
    } else {
      this.filterName = this.selectedFilter.name;
      this.enableListboxBrands(this.allBrands);
    }

    this.setBrandsForSelectedFilter();
  }

  onDeleteClick() {
    if (this.selectedFilter.countUsage) {
      this.growl.showError(
        this.translate.instant('BRAND_FILTERS.FILTER_IN_USE_TITLE'),
        this.translate.instant('BRAND_FILTERS.FILTER_IN_USE_MSG'),
      );
      return;
    }

    this.confirmation.confirm({
      header: this.translate.instant('COMMON.DELETE'),
      message: this.translate.instant('BRAND_FILTERS.CONFIRM_DELETE_MSG'),
      acceptButtonStyleClass: 'ms-2',
      rejectButtonStyleClass: 'me-2',
      accept: () => this.deleteFilter(),
    });
  }

  onFiltersOverviewClick() {
    this.filtersOverviewDialog.visible = true;
  }

  onClearCacheClick() {
    this.api.resetBrandFilterCache()
      .subscribe(x => this.growl.infoMessage(
        this.translate.instant('BRAND_FILTERS.CACHE_CLEARED_TITLE'),
        this.translate.instant('BRAND_FILTERS.CACHE_CLEARED_MSG'),
        1500
      ));
  }

  onFilterNameChange(filterName: string) {
    this.filterName = filterName;
  }

  onSelectedFilterChange(event: BrandFilter) {
    this.setBrandsForSelectedFilter();
  }

  onSelectedBrandsSelectionChange() {
    this.clearListboxSelection(this.availableBrandsListbox);
  }

  onAvailableBrandsSelectionChange() {
    this.clearListboxSelection(this.selectedBrandsListbox);
  }

  onRemoveAll() {
    this.removeBrandsFromFilter(this.selectedFilterBrands);
  }

  onRemoveSelection() {
    this.removeBrandsFromFilter(this.selectedBrandsListboxSelection);
  }

  onAddAll() {
    this.addBrandsToFilter(this.availableBrandsforFilter);
  }

  onAddSelection() {
    this.addBrandsToFilter(this.availableBrandsListboxSelection);
  }

  onFiltersDeletedFromDialog(filterIds: string[]) {
    const filters = this.filters.filter(x => !filterIds.includes(x.id));
    this.filters = _.sortBy(filters, filter => filter.name.toLowerCase());
    this.selectedFilter = this.filters[0];
  }
}
