import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import * as pages from './pages';
import * as shells from './shells';

import { analyticsGuard } from './guards/analytics-guard';
import { confirmDeactivate } from './guards/confirm-deactivate';

const routes: Routes = [
  {
    path: 'analytics',
    canActivate: [analyticsGuard],
    children: [
      {
        path: '',
        component: shells.AnalyticsShell,
        children: [
          { path: '', component: pages.HomePage },

          // Market overview
          { path: 'market-overview-competition', component: pages.MarketOverviewCompetitionPage, canDeactivate: [confirmDeactivate] },
          { path: 'market-overview-trends', component: pages.MarketOverviewTrendsPage, canDeactivate: [confirmDeactivate] },

          // Article visibility
          { path: 'article-visibility-article-numbers',  component: pages.ArticleVisibilityArticleNumbersPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-visibility-brands', component: pages.ArticleVisibilityBrandsPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-visibility-genarts',  component: pages.ArticleVisibilityGenartsPage, canDeactivate: [confirmDeactivate] },

          // Article views
          { path: 'article-views-brands', component: pages.ArticleViewsBrandsPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-oe-numbers', component: pages.ArticleViewsOeNumbersPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-vehicles', component: pages.ArticleViewsVehiclesPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-vehicles/details/level1', component: pages.ArticleViewsVehiclesDetailsPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-article-numbers', component: pages.ArticleViewsArticleNumbersPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-article-numbers/details/level1', component: pages.ArticleViewsArticleNumbersDetailsPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-genarts', component: pages.ArticleViewsGenartsPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-genarts/details/level1', component: pages.ArticleViewsGenartsDetailsPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-countries', component: pages.ArticleViewsCountriesPage, canDeactivate: [confirmDeactivate] },
          { path: 'article-views-countries/details/level1', component: pages.ArticleViewsCountriesDetailsPage, canDeactivate: [confirmDeactivate] },

          // Most searched
          { path: 'most-searched-oe-numbers', component: pages.MostSearchedOeNumbersPage, canDeactivate: [confirmDeactivate] },
          { path: 'most-searched-numbers', component: pages.MostSearchedNumbersPage, canDeactivate: [confirmDeactivate] },
          { path: 'most-searched-countries', component: pages.MostSearchedCountriesPage, canDeactivate: [confirmDeactivate] },
          { path: 'most-searched-vehicles', component: pages.MostSearchedVehiclesPage, canDeactivate: [confirmDeactivate]},

          //Sales Page
          { path: 'sales-brands', component: pages.SalesBrandsPage, canDeactivate: [confirmDeactivate] },
          { path: 'sales-genarts', component: pages.SalesGenartsPage, canDeactivate: [confirmDeactivate] },
          { path: 'sales-article-numbers', component: pages.SalesArticleNumbersPage, canDeactivate: [confirmDeactivate] },
          { path: 'sales-countries', component: pages.SalesArticleCountriesPage, canDeactivate: [confirmDeactivate] },

          // Vehicle searches
          { path: 'most-searched-vin-numbers', component: pages.MostSearchedVinNumbersPage, canDeactivate: [confirmDeactivate] },
          { path: 'most-searched-number-plates', component: pages.MostSearchedNumberPlatesPage, canDeactivate: [confirmDeactivate] },

          // Full export
          { path: 'full-export', component: pages.FullExportPage, canDeactivate: [confirmDeactivate] },

          { path: 'monitoring/solr', component: pages.SolrMonitoringPage, canDeactivate: [confirmDeactivate] },

          // User activity
          { path: 'user-stats-premium', component: pages.UserStatsPremiumPage, canDeactivate: [confirmDeactivate]},
          { path: 'user-stats', component: pages.UserStatsPage, canDeactivate: [confirmDeactivate] },
        ]
      }
    ]
  },
  {
    path: 'manage',
    canActivate: [analyticsGuard],
    children: [
      {
        path: '',
        component: shells.AnalyticsShell,
        children: [
          { path: 'brand-filters', component: pages.AdminBrandFiltersPage },
          { path: 'users', component: pages.AdminUsersPage },
          { path: '',  redirectTo: 'users', pathMatch: 'full' },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnalyticsRoutingModule { }
