import { UtilsService } from "@app/core";
import { PageComponent } from "@app/shared/components/page";
import { FacetItem } from "@app/shared/models/facet-item";

export abstract class AnalyticsPage extends PageComponent {
  /**
   * The key of the page data on storage.
   */
  abstract get pageStoreKey(): string;

  /**
   * The API path to request some report/chart data.
   */
  abstract get reportPath(): string;

  /**
   * The API path to request some export data.
   */
  abstract get exportPath(): string;

  /**
   * The facets needed for this page, to be passed to the API request(s).
   */
  abstract get pageFacets(): string[];

  exportAsCsv(facets: FacetItem[], fileName: string, fields: string[], header?: string[], modFn?: Function[]) {
    if (!header) {
      header = fields;
    }

    const rows = [header];
    facets.forEach(facet => {
      const row = [];
      fields.forEach((field, i) => {
        const data = field === 'percent'
          ? facet[field] + ' %'
          : facet[field] || facet.details?.[field];

        if (!data) {
          return;
        }

        row.push(modFn?.[i] ? modFn[i](data) : data);
      });

      rows.push(row);
    });

    UtilsService.exportToCsv(fileName, rows);
  }
}
