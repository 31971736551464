import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { ApiService, GrowlService } from '@app/shared';
import { Brand } from '@app/shared/models/brand';
import { BrandFilter } from '@app/shared/models/settings/brand-filter';

import { combineLatest } from 'rxjs';

@Component({
  selector: 'filters-overview-dialog',
  templateUrl: './filters-overview-dialog.component.html',
  styleUrl: './filters-overview-dialog.component.scss'
})
export class FiltersOverviewDialogComponent {
  @Input() filters: BrandFilter[] = [];

  @Output() filtersDeleted = new EventEmitter<string[]>();

  selectedFilters: BrandFilter[] = [];

  private allBrands: Brand[] = [];

  visible = false;

  constructor(
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private confirmation: ConfirmationService,
    private translate: TranslateService,
    private growl: GrowlService,
  ) {
    this.api.getAllBrands(false)
      .subscribe(brands => this.allBrands = brands);
  }

  getBrandsLabelsFromIds(brandIds: number[]): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      _.sortBy(
        this.allBrands.filter(x => brandIds.includes(x.brandId))
          .map(x => `${x.brandName} [${x.brandId}]`),
        x => x.toLowerCase()
      ).join('<br>')
    );
  }

  private deleteSelectedFilters() {
    const selectedFiltersIds = this.selectedFilters.map(x => x.id);
    combineLatest(this.selectedFilters.map(x => this.api.deleteBrandFilter(x)))
      .subscribe(responses => {
        if (responses.every(x => x.statusCode === 200)) {
          this.growl.infoMessage(
            this.translate.instant('FILTERS_OVERVIEW_DIALOG.FILTERS_DELETED_TITLE'),
            this.translate.instant('FILTERS_OVERVIEW_DIALOG.FILTERS_DELETED_MSG'),
            1500
          );

          this.filtersDeleted.emit(selectedFiltersIds);
        }
      })
  }

  onSelectUnusedFiltersClick() {
    this.selectedFilters = this.filters.filter(x => !x.countUsage);
  }

  onDeleteSelectedFiltersClick() {
    this.confirmation.confirm({
      header: this.translate.instant('COMMON.DELETE'),
      message: this.translate.instant('BRAND_FILTERS.CONFIRM_DELETE_MSG'),
      acceptButtonStyleClass: 'ms-2',
      rejectButtonStyleClass: 'me-2',
      accept: () => this.deleteSelectedFilters(),
    })
  }
}
