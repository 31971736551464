import { ChartOptions } from "chart.js";

export const DEFAULT_BACKGROUND_COLORS = [
  '#3D94C2',
  '#004DBF',
  '#DE6726',
  '#B82192',
  '#911A74',
  '#3CCC7D',
  '#268D6C',
  '#6F38B1',
  '#D2D90D',
  '#2780EB',
  '#EC5454',
  '#37C1C1',
  '#5D7EFF',
  '#01BA49',
  '#B5A100',
  '#BC9CFF',
  '#B42700',
  '#01A3A4',
  '#C4004A',
  '#82D89A',
  '#FF5784',
  '#185E3B',
  '#6D5E00',
  '#E7C17B',
  '#F2B2E6',
  '#FF97AC',
  '#DD252F',
  '#00A1D5',
  '#FABC32',
  '#FF8800',
];

export const BAR_CHART_DEFAULT_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        precision: 0,
      },
    },
    x: {
      ticks: {
        precision: 0,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    }
  },
}

export const DOUGHNUT_CHART_DEFAULT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: true,
      position: "right",
      onClick: (e) => e.native.preventDefault() // Omit default behavior of toggle a value
    },
    tooltip: {
      callbacks: {
        title: ([context]) => {
          const title = context.label || '';
          const totalData: number = (context.dataset.data as number[]).reduce((x, y) => x + y);
          const percentage = (context.raw as number / totalData) * 100.0;

          return `${title} (${percentage.toFixed(2)}%)`;
        },
      }
    }
  }
}

export const STACKED_BAR_CHART_DEFAULT_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      ticks: {
        precision: 0,
      },
    },
    y: {
      beginAtZero: true,
      stacked: true,
      ticks: {
        precision: 0,
      },
    }
  },
  plugins: {
    legend: {
      onClick: (e) => e.native.preventDefault(), // Omit default behavior of toggle a value
      labels: {
        filter: (item, data) => { // Only show labels for selected datasources
          return !item.hidden;
        }
      }
    }
  },
}
