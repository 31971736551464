import { UserDetail, Customer } from '@app/core';
import { RmiGrants } from '../enums/rmi-grants';

/**
 * Web Show user model.
 */
export class WebShopUser extends UserDetail {
  city: string;
  company: string;
  companyType: number;
  customerNumOld: number;
  ibanNumber: string;

  override login: string;
  manager: string;
  mandator: string;
  masterId: number;

  language: string;
  country: string;

  fax: string;
  position: string;
  postCode: string;
  profileId: number;
  salesCompany: string;
  salesLocation: string;
  street: string;
  tariffRole: number;
  title: number;
  userLevel: number;
  vatIdNumber: string;

  hasTecdoc = true;
  hasShop = false;
  hasOE = false;

  /**
   * Rmi grants for Passenger cars and little commercial vehicles.
   */
  rmiPC: RmiGrants = RmiGrants.None;

  /**
   * Rmi grants for commercial vehicles.
   */
  rmiCV: RmiGrants = RmiGrants.None;

  constructor() {
    super();
  }

  /**
   * Web Shop user factory.
   *
   * @param {any} data
   */
  static factory<T extends UserDetail>(data: Customer): T {
    const user_detail = new WebShopUser();    

    console.log( "factory", data );

    user_detail.id = data.customerId || data.id;
    user_detail.firstName = data.firstName;
    user_detail.lastName = data.lastName;
    user_detail.email = data.email;
    user_detail.phone = data.phone;
    user_detail.fax = data.fax;
    user_detail.culture = `${data.languagecode}-${data.countrycode}`;

    user_detail.city = data.city;
    user_detail.company = data.company;
    user_detail.companyType = data.companyType;
    user_detail.customerNumOld = data.customerNumOld;
    user_detail.ibanNumber = data.ibanNumber;
    user_detail.login = data.login;
    user_detail.manager = data.manager;
    user_detail.mandator = data.mandator;
    user_detail.masterId = data.masterId;

    user_detail.position = data.position;
    user_detail.postCode = data.postCode;
    user_detail.profileId = data.profileId;
    user_detail.salesCompany = data.salesCompany;
    user_detail.salesLocation = data.salesLocation;
    user_detail.street = data.street;
    user_detail.tariffRole = data.tariffRole;
    user_detail.title = data.title;
    user_detail.userLevel = data.userLevel;
    user_detail.vatIdNumber = data.vatIdNumber;

    user_detail.language = data.languagecode;
    user_detail.country = data.countrycode;

    return user_detail as any;
  }

  // TODO: For development only
  public getNumberPlateSearchParams(id: number) {
    const additionalSearchParams = [
      {
        keySystemType: 50,
        mandatorUsername: 'TD_GERAUSIV',
        mandatorPassword: 'a45@FgsD5pU',
        endCustomerPassword: 'Default2015',
        endCustomerUsername: 'TDU_support2_more',
        id: 5,
      },
      {
        keySystemType: 50,
        mandatorUsername: 'TD_GERAUSIV',
        mandatorPassword: 'a45@FgsD5pU',
        endCustomerPassword: 'Andrea',
        endCustomerUsername: 'Nollens',
        id: 11,
      },
      {
        keySystemType: 55,
        idKey: '63442b2c-1d62-4d5e-8c42-9f81e069a7f2',
        id: 1,
      },
      {
        keySystemType: 61,
        mandatorUsername: '0450864',
        mandatorPassword: 'finbar',
        initials: 'SG',
        id: 7,
      },
      {
        keySystemType: 91,
        mandatorUsername: 'BDRTws-t3C4L4n',
        mandatorPassword: '17244e143',
        companyName: 'tecalliance',
        serviceName: 'RTWS_EQUIVALENTI',
        id: 10,
      },
      {
        keySystemType: 92,
        mandatorUsername: 'EXIDE',
        mandatorPassword: '?9924Volvo=',
        productId: 1066,
        idKey: '80c6ba55-00f5-4635-bc01-f67b468cac55',
        id: 0,
      },
      {
        keySystemType: 93,
        mandatorUsername: 'EXIDE',
        mandatorPassword: '?9924Volvo=',
        productId: 1065,
        idKey: '80c6ba55-00f5-4635-bc01-f67b468cac55',
        id: 19,
      },
      {
        keySystemType: 94,
        mandatorUsername: 'EXIDE',
        mandatorPassword: '?9924Volvo=',
        productId: 1055,
        idKey: '80c6ba55-00f5-4635-bc01-f67b468cac55',
        id: 3,
      },
      {
        keySystemType: 95,
        mandatorUsername: 'tecdocapi',
        mandatorPassword: 'a931iq4g',
        id: 4,
      },
      {
        keySystemType: 95,
        mandatorUsername: 'tecdocapi',
        mandatorPassword: 'a931iq4g',
        id: 14,
      },
      {
        keySystemType: 95,
        mandatorUsername: 'tecdocapi',
        mandatorPassword: 'a931iq4g',
        id: 18,
      },
      {
        keySystemType: 97,
        mandatorUsername: 'EXIDE',
        mandatorPassword: '?9924Volvo=',
        productId: 1067,
        idKey: '80c6ba55-00f5-4635-bc01-f67b468cac55',
        id: 15,
      },
      {
        keySystemType: 99,
        mandatorUsername: 'Tecdoccartell',
        mandatorPassword: 'Cartell10',
        id: 8,
      },
      {
        keySystemType: 99,
        mandatorUsername: 'Tecdoccartell',
        mandatorPassword: 'Cartell10',
        id: 9,
      },
      {
        keySystemType: 99,
        mandatorUsername: 'Tecdoccartell',
        mandatorPassword: 'Cartell10',
        id: 16,
      },
      {
        keySystemType: 99,
        mandatorUsername: 'XMLTECALLIANCE',
        mandatorPassword: 'XMLPWL10L1020!7',
        id: 2,
      }
    ];

    return additionalSearchParams.find(config => config.id === id);
  }
}
