import {
  Component,
  ElementRef,
  HostListener
} from '@angular/core';

import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

import { Router, Event, NavigationEnd } from '@angular/router';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { MenuService } from '../../services/menu';
import { ApiService, SideMenuItem, UIActionsService } from '@app/shared';
import { IS_ACTIVE_MATCH_OPTIONS_EXACT_FALSE } from '@app/shared/consts/is-active-match-options';
import { DomInfo } from '@app/shared/models/dom-info';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

@Component({
  //moduleId: module.id,
  selector: 'analytics-shell',
  templateUrl: 'analytics-shell.html',
  styleUrl: 'analytics-shell.scss',
  host: {'class': 'h-100'},
  animations: [
      trigger('mainaside', [
          state('expanded', style({
            height: '*'
          })),
          state('collapsed', style({
            height: '0'
          })),
          transition('expanded => collapsed', [animate('300ms ease-out')]),
          transition('collapsed => expanded', [animate('300ms ease-out')])
      ])
  ]
})
export class AnalyticsShell {
  get isHomePage(): boolean{
    return this._router.url === '/analytics';
  }

  //#region Fields

  _menuItems: Array<SideMenuItem>;
  _mobileFlag = (window.innerWidth < 768);
  _state = this._mobileFlag
    ? 'collapsed'
    : 'expanded';

  //#endregion

  //#region Constructor

  constructor(
    private _menu: MenuService,
    private _uiActions: UIActionsService,
    private _router: Router,
    private analyticsFilter: AnalyticsFilterService,
    private api: ApiService,
  ) {
    _uiActions.switchMenu.subscribe(() => {
      this.menuToggle();
    });

    _router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
            // Hide loading indicator
            this.setMenu();
        }
    });

    window.onmousemove = (event) => this.onMouseMove(event);

    Chart.register(ChartDataLabels);
    Chart.defaults.plugins.datalabels.display = false;

    this.analyticsFilter.applyFilter
      .subscribe(filterData => {
        this.api.setSelectedDatasourceId(filterData.datasources);
        this._menu.loadProperties();
      })
  }

  //#endregion

  //#region Helpers

  private setMenu() {
    let site = 'analytics';
    if( this._router.isActive('/manage', IS_ACTIVE_MATCH_OPTIONS_EXACT_FALSE) ) {
      site = 'manage';
    }

    this._menu.getMainMenu( site )
        .subscribe({
          next: (data: SideMenuItem[]) => {
            this._menuItems = data;
          },
          error: (err: any) => {}
        });
  }

  private menuToggle() {
    switch (this._state) {
      case 'collapsed':
        this._state = 'expanded';
        break;
      case 'expanded':
        this._state = 'collapsed';
        break;
    }
  }

  //#endregion

  //#region Events Handlers

  @HostListener('window:resize')
  onResize() {
    let flag = window.innerWidth < 768;

    if (flag === this._mobileFlag) {
      return;
    }

    this._mobileFlag = flag;
    this._state = this._mobileFlag
      ? 'collapsed'
      : 'expanded';
  }

  onScroll( event ) {
    DomInfo.scrollTop = event.target.scrollTop;
  }

  onMouseMove(event: MouseEvent) {
    DomInfo.mouseX = event.pageX;
    DomInfo.mouseY = event.pageY;
  }
  //#endregion
}
