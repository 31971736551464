import { Component } from '@angular/core';
import { Fields } from '@app/shared/models/fields';
import { AnalyticsPage } from '../analytics-page';

@Component({
  selector: 'app-sales-article-countries',
  templateUrl: './sales-article-countries.component.html',
  styleUrl: './sales-article-countries.component.scss'
})
export class SalesArticleCountriesPage extends AnalyticsPage {
  get pageName(): string {
    return 'Sales countries';
  }

  get pageStoreKey(): string {
    return "SalesCountriesComponent";
  }

  get reportPath(): string {
    return '/report/order/sales';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.LOCATION_COUNTRY, Fields.LOCATION_COUNTRY_BRANDLESS];
  }

  hasChanges(): boolean {
    return false;
  }
}
