import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ChartData, ChartOptions } from 'chart.js';
import { ConfirmationService } from 'primeng/api';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { DateUtils } from '@app/shared/utils/date-utils';
import { BAR_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

import { AnalyticsPage } from '../analytics-page';

@Component({
  selector: 'app-article-visibility-genarts',
  templateUrl: './article-visibility-genarts.component.html',
  styleUrl: './article-visibility-genarts.component.scss'
})
export class ArticleVisibilityGenartsPage extends AnalyticsPage {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Most visible brands';
  }

  get pageStoreKey(): string {
    return 'ArticleVisibleGenartsComponent';
  }

  get reportPath(): string {
    return '/report/article/visibility';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.GENART_NO];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  readonly chartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
      }
    },
  };

  dateRangeLabel: string;

  chartData: ChartData;

  selectedTopOptions: 10 | 20 | 30 = 10;

  filterSnapshot: AnalyticsFilterData;

  constructor(
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey);

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  private requestData(filterData: AnalyticsFilterData) {
    const chartRequest = new ChartRequest('article_visibility');
    chartRequest.setParams(filterData, this.pageFacets);

    this.api.getReport(this.reportPath, chartRequest)
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate);

        const genartsData = data.facets.get('genart_id');

        this.chartData = {
          labels: genartsData.originalData.map(x => x.name),
          datasets: [
            {
              data: genartsData.originalData.map(x => x.count),
            }
          ]
        };

        this.chartData.datasets[0].backgroundColor = '#9ad0f5';
      });
  }

  //#region Event handlers

  onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter);
  }
}
