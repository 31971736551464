<div class="d-flex justify-content-between">
  <!-- Left panel -->
  <div class="flex-grow-1">
    <ng-container *ngTemplateOutlet="catalogUsersTrafficPanel; context: {
      side: 'left',
      catalog: leftPanelCatalog,
      data: leftPanelCatalogData,
      top: leftTopOption
    }">
    </ng-container>
  </div>

  <!-- Separator -->
  <div class="px-2" *ngIf="rightPanelCatalog"></div>

  <!-- Right panel -->
  <div *ngIf="rightPanelCatalog" class="flex-grow-1">
    <ng-container *ngTemplateOutlet="catalogUsersTrafficPanel; context: {
      side: 'right',
      catalog: rightPanelCatalog,
      data: rightPanelCatalogData,
      top: rightTopOption,
    }">
    </ng-container>
  </div>
</div>

<ng-template #catalogUsersTrafficPanel
  let-selectedCatalog="catalog"
  let-catalogData="data"
  let-side="side"
  let-selectedTopOptions="top">
  <div class="card rounded-0">
    <div class="card-header d-flex align-items-center justify-content-between p-0">
      <!-- Left side -->
      <div class="flex-grow-1 py-2">
        <p-dropdown
          [options]="catalogs"
          [optionLabel]="'name'"
          [ngModel]="selectedCatalog"
          [virtualScroll]="true"
          [virtualScrollItemSize]="30"
          [filter]="true"
          (onChange)="onChangeCatalog($event, side)"
          class="mx-2"
          [style]="{'height': '30px'}"
          [styleClass]="'xs rounded-0'"
          [panelStyleClass]="'items-no-padding xs'">
          <ng-template pTemplate="selectedItem">
            <h5>{{ selectedCatalog?.name }}</h5>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- Right side -->
      <div class="flex-shrink-0 d-flex align-items-center" [style.height.px]="45">
        <!-- Top 10, 20, 30 -->
        <p-dropdown
          [options]="topOptions"
          [ngModel]="selectedTopOptions"
          (onChange)="onChangeTop($event, side)"
          class="mx-2"
          [styleClass]="'xs rounded-0'"
          [panelStyleClass]="'items-no-padding xs'">
        </p-dropdown>

        <button *ngIf="!isAnyDownloadInProgress"
          pTooltip="Save"
          tooltipPosition="left"
          class="btn text-primary border-0 border-start rounded-0 py-0 px-2 h-100"
          (click)="onSaveClick(side)">
          <i class="fa ta-icon-download text-black"></i>
        </button>
      </div>
    </div>
    <div class="card-body row">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ 'CATALOG_USERS_TRAFFIC_COMPARE.USERNAME' | translate }}</th>
            <th scope="col">{{ 'CATALOG_USERS_TRAFFIC_COMPARE.SESSIONS' | translate }}</th>
            <th scope="col">{{ 'CATALOG_USERS_TRAFFIC_COMPARE.ACTIONS' | translate }}</th>
            <th scope="col">{{ 'CATALOG_USERS_TRAFFIC_COMPARE.MAX_ACTIONS' | translate }}</th>
            <th scope="col">{{ 'CATALOG_USERS_TRAFFIC_COMPARE.TOTAL_TIME' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of catalogData">
            <th class="fw-normal" scope="row">{{ user.name }}</th>
            <th class="fw-normal">{{ user.count }}</th>
            <th class="fw-normal">{{ user.details['nb_actions'] }}</th>
            <th class="fw-normal">{{ user.details['max_actions'] }}</th>
            <th class="fw-normal">{{ normalizeTime(user.details['sum_visit_length']) }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
