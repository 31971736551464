import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { FacetItem } from '@app/shared/models/facet-item';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { DOUGHNUT_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';

import { AnalyticsPage } from '../analytics-page';
import { UserActivityVisitorsData } from './user-activity-visitors-data';

@Component({
  selector: 'app-user-stats-premium',
  templateUrl: './user-stats-premium.component.html',
  styleUrl: './user-stats-premium.component.scss'
})
export class UserStatsPremiumPage extends AnalyticsPage {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'User statictics';
  }

  get pageStoreKey(): string {
    return 'MostSearchedVisitorsComponent';
  }

  get reportPath(): string {
    return '/piwikApi/visitors';
  }

  get exportPath(): string {
    return '/piwikApi/export';
  }

  get pageFacets(): string[] {
      return [];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  readonly lineChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      }
    },
    plugins: {
      legend: {
        onClick: (e) => e.native.preventDefault(), // Omit default behavior of toggle a value
      }
    },
  }

  readonly doughnutChartOptions: ChartOptions = {
    ...DOUGHNUT_CHART_DEFAULT_OPTIONS
  }

  private readonly overviewPrefix = 'siteVisitOverview';
  private readonly bySourceMediumPrefix = 'siteVisitBySourceMedium';
  private readonly byRefererTypePrefix = 'siteVisitByRefererType';

  filterSnapshot: AnalyticsFilterData;

  availableDatasources: UserActivityVisitorsData[];
  selectedDatasource: UserActivityVisitorsData;

  get topVisitsBySourceMedium(): FacetItem[] {
    return _.orderBy(
      this.selectedDatasource.visitsBySourceMedium.slice(0, this.selectedTopOption),
      item => item.details['Visitors'],
      ['desc']
    );
  }

  /**
   * Available top options.
   */
  readonly topOptions = [
    { label: 'Top 10', value: 10 },
    { label: 'Top 20', value: 20 },
    { label: 'Top 30', value: 30 }
  ];
  selectedTopOption: 10 | 20 | 30 = 10;

  constructor(
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
    private translate: TranslateService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey, {
      showBrandFilter: false,
      showCountryFilter: false,
      showGenericArticleFilter: false,
    });

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  getVisitorsBySourcePercentage(item: FacetItem) {
    const totalCount = this.selectedDatasource.visitsBySourceMedium
      .map<number>(x => x.details['Visitors'])
      .reduce((a, b) => a + b);

    return ((item.details['Visitors'] / totalCount) * 100.0).toFixed(2);
  }

  private requestData(filterData: AnalyticsFilterData) {
    const chartRequest = new ChartRequest('article_selection');
    chartRequest.setParams(filterData, this.pageFacets);

    this.api.getReport(this.reportPath, chartRequest)
      .subscribe(data => {
        this.availableDatasources = [];
        this.selectedDatasource = undefined;
        if (data.statusCode !== 200) {
          return;
        }

        const facetsGroupedByDatasource = _.groupBy(Object.fromEntries(data.facets), 'label');
        this.availableDatasources = Object.values(facetsGroupedByDatasource).map(facetData => {
          const overviewData = facetData.find(x => x.fieldName.startsWith(this.overviewPrefix));
          const bySourceMediumData = facetData.find(x => x.fieldName.startsWith(this.bySourceMediumPrefix));
          const byRefererTypeData = facetData.find(x => x.fieldName.startsWith(this.byRefererTypePrefix));

          const overviewDetails = overviewData.originalData[0].details;
          const bounceRatePercentage = +(overviewDetails['Bounce rate'] as number * 100.0).toFixed(2);
          const returningVisitorsRatePercentage = +(overviewDetails['Returning visitors rate'] as number * 100.0).toFixed(2);
          const newVisitorsRatePercentage = 100.0 - returningVisitorsRatePercentage;

          // Regex to filter out the IP addresses
          const ipRegex = /^(?:\d{1,3}\.){3}\d{1,3}/;

          return <UserActivityVisitorsData>{
            datasourceId: overviewData.fieldName.split('_').at(-1),
            datasourceName: overviewData.label,
            bounceRate: bounceRatePercentage,
            pageViews: overviewDetails['Page views'],
            sessions: overviewDetails['Sessions'],
            visitors: overviewDetails['Visitors'],
            returningVisitorsRate: returningVisitorsRatePercentage,
            newVisitorsRate: newVisitorsRatePercentage,
            visitsByRefererType: {
              labels: byRefererTypeData.timeLine.labels,
              datasets: byRefererTypeData.timeLine.datasets,
            },
            visitsBySourceMedium: bySourceMediumData.originalData.filter(x => !ipRegex.test(x.id)),  // filter out the IP addresses
            visitorsRateChartData: {
              labels: [
                this.translate.instant('USER_STATS_PREMIUM.NEW'),
                this.translate.instant('USER_STATS_PREMIUM.RETURNING'),
              ],
              datasets: [
                {
                  data: [newVisitorsRatePercentage, returningVisitorsRatePercentage],
                },
              ],
            },
          };
        });

        this.selectedDatasource = this.availableDatasources[0];
      })
  }

  //#region Event handlers

  onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter);
  }

  onSaveClick() {
    const sortedData =  _.orderBy(
      this.selectedDatasource.visitsBySourceMedium,
      item => item.details['Visitors'],
      ['desc']
    );

    this.exportAsCsv(
      sortedData.map(x => ({
        ...x,
        percent: +this.getVisitorsBySourcePercentage(x),
      })),
      `${this.selectedDatasource.datasourceName}.csv`,
      ['name', 'Visitors', 'percent', 'Average page views in session'],
      ['Source / medium', 'Visitors', 'Visitors percentage', 'Average page views in session'],
      [undefined, undefined, undefined,
        // Limit decimals for 'Average page views in session'
        (data) => data.toString().split('.')?.[1]?.length > 2
          ? data.toFixed(2)
          : data
      ],
    );
  }

  //#endregion
}
