import { Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ContextService } from '..';

@Injectable()
export class ApiURLInterceptor implements HttpInterceptor {
  constructor(
    private _context: ContextService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    // HttpClient works with inmutable requests.

    if (!/^http[s]?:\/\//.test( req.url ) && !(req.url.startsWith('/') || req.url.startsWith('./') || req.url.startsWith('../'))) {
      const fReq = req.clone({ url: `${this._context.instance.apiUrl}/${req.url}` });

      return next.handle(fReq);
    }

    return next.handle(req);
  }
}
