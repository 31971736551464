import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ChartData, ChartOptions } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';

import { AnalyticsPage } from '../analytics-page';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { DateUtils } from '@app/shared/utils/date-utils';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { ChartType } from '@app/shared/components/chart-panel/chart-panel.component';
import { BAR_CHART_DEFAULT_OPTIONS, DEFAULT_BACKGROUND_COLORS, DOUGHNUT_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

@Component({
  selector: 'app-market-overview-competition',
  templateUrl: './market-overview-competition.component.html',
  styleUrl: './market-overview-competition.component.scss'
})
export class MarketOverviewCompetitionPage extends AnalyticsPage {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Market overview competition';
  }

  get pageStoreKey(): string {
    return 'MarketOverviewCompetitionComponent';
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.COMPETITORS]
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  //#region Properties

  private readonly barChartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    scales: {
      y: {
        title: {
          display: true,
          text: this.translate.instant('COMMON.Y_LABEL_ARTICLE_VIEWS'),
        }
      }
    },
  };

  private readonly doughnutChartOptions: ChartOptions = {
    ...DOUGHNUT_CHART_DEFAULT_OPTIONS
  }

  private chartType: ChartType = 'bar';

  chartOptions: ChartOptions;
  chartData: ChartData;

  filterSnapshot: AnalyticsFilterData;

  dateRangeLabel: string;

  //#endregion

  //#region Constructor

  constructor(
    private translate: TranslateService,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey);

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  //#endregion

  override ngAfterViewInit(): void {
    // To avoid NG0100 ("Expression has changed after it was checked") error
    setTimeout(() => this.chartOptions = this.barChartOptions);
  }

  private getChartRequest(filterData?: AnalyticsFilterData): ChartRequest {
    if (!filterData) {
      filterData = this.filterSnapshot;
    }

    const chartRequest = new ChartRequest('article_selection');
    chartRequest.setParams(filterData, this.pageFacets);

    return chartRequest;
  }

  private requestData(filterData: AnalyticsFilterData) {
    this.api.getReport(this.reportPath, this.getChartRequest(filterData))
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate);

        const reportData: any[] = data.responseData?.facets?.competitors?.data;
        if (!reportData) {
          return;
        }

        this.chartData = {
          labels: reportData.map(x => `${x.label} [${x.id}]`),
          datasets: [
            {
              data: reportData.map(x => x.count),
            }
          ]
        };

        this.setChartOptions();
        this.setChartColors();
      });
  }

  private setChartColors() {
    switch (this.chartType) {
      case 'bar':
        this.chartData.datasets[0].backgroundColor = '#9ad0f5';
        break;

      case 'doughnut':
        this.chartData.datasets[0].backgroundColor = DEFAULT_BACKGROUND_COLORS;
        break;
    }
  }

  private setChartOptions() {
    switch (this.chartType) {
      case 'bar':
        this.chartOptions = this.barChartOptions;
        break;

      case 'doughnut':
        this.chartOptions = this.doughnutChartOptions;
        break;
    }
  }

  //#region Event handlers

  onFilterChange(filter) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter)
  }

  onChartTypeChanged(chartType: ChartType) {
    this.chartType = chartType;
    this.setChartOptions();
    this.setChartColors();
  }

  onExportCsvClick() {
    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getChartRequest());
  }

  //#endregion
}
