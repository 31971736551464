import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ChartData, ChartOptions } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';
import { DateUtils } from '@app/shared/utils/date-utils';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { ChartType } from '@app/shared/components/chart-panel/chart-panel.component';
import { DEFAULT_BACKGROUND_COLORS, STACKED_BAR_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';
import { TimeLineData } from '@app/shared/models/analytics/time-line-data';

import { AnalyticsPage } from '../analytics-page';

import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrackerService } from '../../../core';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';
import { DownloadHandler } from '@app/shared/utils/download-handler';

type DatasetSelection = 'genarts' | 'countries' | 'all';

@Component({
  selector: 'app-market-overview-trends',
  templateUrl: './market-overview-trends.component.html',
  styleUrl: './market-overview-trends.component.scss'
})
export class MarketOverviewTrendsPage extends AnalyticsPage {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Market overview trends component';
  }

  get pageStoreKey(): string {
    return 'MarketOverviewTrendsComponent';
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.GENART_NO + Fields.DEMAND_TIME_LINE, Fields.LOCATION_COUNTRY + Fields.DEMAND_TIME_LINE];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion
  private readonly FACET_COUNT = 30;
  private readonly barChartOptions: ChartOptions = {
    ...STACKED_BAR_CHART_DEFAULT_OPTIONS,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: true,
        title: {
          display: true,
          text: this.translate.instant('COMMON.Y_LABEL_ARTICLE_VIEWS'),
        }
      }
    },
  };

  //#region Constructor

  constructor(
    private translate: TranslateService,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router)

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey);

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  //#endregion

  chartOptions: ChartOptions;

  genartsDatasets: TimeLineData[];
  selectedGenartsDatasets: TimeLineData[] = [];
  genartsChartData: ChartData;

  countriesDatasets: TimeLineData[];
  selectedCountriesDatasets: TimeLineData[] = [];
  countriesChartData: ChartData;

  filterSnapshot: AnalyticsFilterData;

  dateRangeLabel: string;

  override ngAfterViewInit(): void {
    // To avoid NG0100 ("Expression has changed after it was checked") error
    setTimeout(() => this.chartOptions = this.barChartOptions);
  }

  private async getChartRequest(
    dataset: DatasetSelection,
    filterData?: AnalyticsFilterData,
    refreshDatasets = false
  ): Promise<ChartRequest> {
    if (!filterData) {
      filterData = this.filterSnapshot;
    }

    const chartRequest = new ChartRequest('article_selection');
    chartRequest.setParams(filterData, this.pageFacets, this.FACET_COUNT);

    if (refreshDatasets) {
      await this.setupDatasets(chartRequest, dataset);
    }

    // Add genarts selection to the request params
    if (dataset !== 'countries' && this.selectedGenartsDatasets?.length) {
      chartRequest.params.set('genart_id_tl_datasets', this.selectedGenartsDatasets.map(dataset => dataset.id));
    }

    // Add countries selection to the request params
    if (dataset !== 'genarts' && this.selectedCountriesDatasets?.length) {
      chartRequest.params.set('location_country_tl_datasets', this.selectedCountriesDatasets.map(dataset => dataset.id));
    }

    return chartRequest;
  }

  /**
   * Requests data for the report, for specified dataset. Depending on the value of `refreshDatasets` parameter,
   * the datasets selector options will be updated or not.
   *
   * @param filterData the filter values to be sent in the request
   * @param dataset the dataset from which the data is requested. Possible values: `'all'`, `'genarts'`, `'countries'`
   * @param refreshDatasets flag to determine if the datasets selector options should be updated
   */
  private async requestData(
    filterData: AnalyticsFilterData,
    dataset: DatasetSelection,
    refreshDatasets = false
  ) {
    this.analyticsApi.getReport(this.reportPath, await this.getChartRequest(dataset, filterData, refreshDatasets))
      .subscribe(data => {
        if (dataset !== 'countries') {
          const genartsData = data.facets.get('genart_id').timeLine;
          this.genartsChartData = {
            labels: genartsData.labels,
            datasets: genartsData.datasets.map((dataset, i) => ({
              data: dataset.data,
              label: dataset.label,
              backgroundColor: DEFAULT_BACKGROUND_COLORS[i % DEFAULT_BACKGROUND_COLORS.length],
              hidden: !dataset.filledData,
            })),
          };
        }

        if (dataset !== 'genarts') {
          const countriesData = data.facets.get('location_country').timeLine;
          this.countriesChartData = {
            labels: countriesData.labels,
            datasets: countriesData.datasets.map((dataset, i) => ({
              data: dataset.data,
              label: dataset.label,
              backgroundColor: DEFAULT_BACKGROUND_COLORS[i % DEFAULT_BACKGROUND_COLORS.length],
              hidden: !dataset.filledData,
            })),
          };
        }
      })
  }

  /**
   * Extracts the datasets from the report request and setup the datasets selectors.
   *
   * @param chartRequest the `ChartRequest` report request
   */
  private setupDatasets(chartRequest: ChartRequest, dataset: DatasetSelection): Promise<void> {
    return lastValueFrom(this.analyticsApi.getReport(this.reportPath, chartRequest)
      .pipe(
        map(data => {
          this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate);

          const setDatasetColors = (dataset: TimeLineData, index: number) => {
            dataset.color = DEFAULT_BACKGROUND_COLORS[index % DEFAULT_BACKGROUND_COLORS.length]
          };

          // Genarts datasets
          if (dataset !== 'countries') {
            const genartsFacets = data.facets.get('genart_id')
            this.genartsDatasets = genartsFacets.timeLine.datasets;
            this.genartsDatasets.forEach((dataset, i) => setDatasetColors(dataset, i));

            // Select by default the dataset that have data on first load
            this.selectedGenartsDatasets = this.genartsDatasets.filter(d => d.data?.length);
          }

          if (dataset !== 'genarts') {
            // Countries datasets
            const countriesFacets = data.facets.get('location_country')
            this.countriesDatasets = countriesFacets.timeLine.datasets;
            this.countriesDatasets.forEach((dataset, i) => setDatasetColors(dataset, i));

            // Select by default the dataset that have data on first load
            this.selectedCountriesDatasets = this.countriesDatasets.filter(d => d.data?.length);
          }
        })
      ));
  }

  //#region Event handlers

  async onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    await this.requestData(filter, 'all', true);
  }

  onChartTypeChanged(chartType: ChartType) {

  }

  async onSelectedGenartsDatasetsChange(selectedGenartsDatasets: TimeLineData[]) {
    await this.requestData(this.filterSnapshot, 'genarts');
  }

  async onSelectedCountriesDatasetsChange(selectedCountriesDatasets: TimeLineData[]) {
    await this.requestData(this.filterSnapshot, 'countries');
  }

  async onGenartsExportCsvClick() {
    this.api.doExport(this.exportPath, new DownloadHandler(false), await this.getChartRequest('genarts'));
  }

  async onCountriesExportCsvClick() {
    this.api.doExport(this.exportPath, new DownloadHandler(false), await this.getChartRequest('countries'));
  }

  //#endregion
}
