import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core';
import { UIActionsService } from '@app/shared/services/ui-actions';
import { DashboardTourService } from '@app/shared/services/dashboard-tour.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  get isLogged(): boolean {
    return this.auth.isLogged;
  }

  get isDetailsPage(): boolean {
    return this.router.url.split('?')[0].endsWith('details/level1');
  };

  constructor(
    private auth: AuthService,
    private router: Router,
    private uiActions: UIActionsService,
    public dashboardTour: DashboardTourService,
  ) {}

  goToHome() {
    if (this.isLogged) {
      this.router.navigate(['/analytics']);
    }
  }

  onSwitchMenu() {
    this.uiActions.switchMenu.emit();
  }
}
