<div class="d-flex flex-column">

  <!-- General info card -->
  <div class="card col-12 mb-3">
    <div class="card-header">
      {{ 'ARTICLE_VIEWS_ARTNO_DETAILS.CARD_HEADER' | translate }}
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-8">
          <table class="table">
            <tr>
              <td class="col-3">{{ 'ARTICLE_VIEWS_ARTNO_DETAILS.CARD_DETAIL_1' | translate }}:</td>
              <td>{{ articleData?.brand }}</td>
            </tr>
            <tr>
              <td class="col-3">{{ 'ARTICLE_VIEWS_ARTNO_DETAILS.CARD_DETAIL_2' | translate }}:</td>
              <td>{{ articleData?.artNo }}</td>
            </tr>
            <tr>
              <td class="col-3">{{ 'ARTICLE_VIEWS_ARTNO_DETAILS.CARD_DETAIL_3' | translate }}:</td>
              <td>{{ articleData?.description }}</td>
            </tr>
          </table>
        </div>
        <div class="col-4">
          <img [src]="articleData?.imgUrl">
        </div>
      </div>
    </div>
  </div>

  <!-- Charts cards -->
  <div class="d-flex row">
    <div *ngFor="let chartData of visibleChartsData" class="col-3 mb-3">
      <chart-panel
        [panelStyle]="{'minHeight': '550px'}"
        [infoText]="foundByInfoText"
        [headerLabel]="chartData.headerLabel"
        [showTopDropdown]="false"
        [chartOptions]="chartOptions"
        [selectedChartType]="'doughnut'"
        [chartData]="chartData.chartData"
        (exportCsvClick)="onReportExportCsvClick(chartData.facetId)">
      </chart-panel>
    </div>
  </div>

  <hr>

  <!-- Recommendations -->
  <div class="d-flex row">
    <loading [useDialog]="false"
      [busy]="areRecommendationsLoading"
      [options]="{ message: 'ARTICLE_VIEWS_ARTNO_DETAILS.RECC_LOAD' | translate }"
      [showCloseButton]="false">
    </loading>

    <div *ngIf="!areRecommendationsLoading">
      <div class="card col-12 mt-2 mb-3">
        <div class="card-body">
          <h5>{{ 'ARTICLE_VIEWS_ARTNO_DETAILS.RECC_HEADER' | translate }}</h5>
          <p>
            {{ 'ARTICLE_VIEWS_ARTNO_DETAILS.RECC_DESCRIPTIONS' | translate }}
          </p>
          <span class="fw-bold me-2">{{ 'ARTICLE_VIEWS_ARTNO_DETAILS.RECC_EXPORT' | translate }}:</span>
          <button class="btn"
            [pTooltip]="'COMMON.SAVE' | translate"
            tooltipPosition="right"
            (click)="onExportDetailedReccClick($event)">
            <i class="fa fa-save"></i>
          </button>
        </div>
      </div>

      <div class="row">
        <chart-panel class="col-6"
          [panelStyle]="{'height': '550px'}"
          [infoText]="'ARTICLE_VIEWS_ARTNO_DETAILS.RECC_INFO_TEXT_BRANDS' | translate"
          [headerLabel]="'ARTICLE_VIEWS_ARTNO_DETAILS.RECC_HEADER_LABEL_BRANDS' | translate"
          [showTopDropdown]="true"
          [enableChartTypeToggle]="true"
          (chartTypeChange)="onBrandsRecommendationChartTypeChange($event)"
          [chartData]="brandsRecommendationChartData"
          [chartOptions]="brandsRecommendationChartOptions"
          (exportCsvClick)="onBrandsReccExportCsvClick()">
        </chart-panel>
        <chart-panel class="col-6"
          [panelStyle]="{'height': '550px'}"
          [infoText]="'ARTICLE_VIEWS_ARTNO_DETAILS.RECC_INFO_TEXT_GENART' | translate"
          [headerLabel]="'ARTICLE_VIEWS_ARTNO_DETAILS.RECC_HEADER_LABEL_GENART' | translate"
          [showTopDropdown]="true"
          [enableChartTypeToggle]="true"
          (chartTypeChange)="onGenartsRecommendationChartTypeChange($event)"
          [chartData]="genartsRecommendationChartData"
          [chartOptions]="genartsRecommendationChartOptions"
          (exportCsvClick)="onGenartsReccExportCsvClick()">
        </chart-panel>
      </div>
    </div>
  </div>

</div>

<p-dialog [(visible)]="showChartDialog" [header]="chartDialogHeader" [modal]="true">
  <chart-panel
    [panelStyle]="{'height': '550px', 'width': '600px'}"
    [headerLabel]="chartDialogChartPanelHeaderLabel"
    [infoText]="chartDialogInfoText"
    [selectedChartType]="'doughnut'"
    [enableChartTypeToggle]="false"
    [enableExport]="false"
    [showTopDropdown]="false"
    [chartOptions]="chartDialogChartOptions"
    [chartData]="chartDialogChartData">
  </chart-panel>
</p-dialog>
