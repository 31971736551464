import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

export const routes: Routes = [
  {path: '', children: [
    {path: '', loadChildren: () => import('../anonymous/anonymous.module').then(m => m.AnonymousModule)},
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true,
    onSameUrlNavigation: 'reload',
   })],
  exports: [RouterModule]
})
export class BootstrapRoutingModule { }
