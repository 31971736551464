import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { cloneDeep } from 'lodash';
import { ChartData, ChartOptions } from "chart.js"
import { ConfirmationService } from 'primeng/api';

import { ApiService } from '@app/shared';
import { TrackerService } from '@app/core';
import { Fields } from '@app/shared/models/fields';
import { DateUtils } from '@app/shared/utils/date-utils';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { ChartData as AnalyticsChartData } from "@app/shared/models/analytics/chart-data"
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { BAR_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';

import { TextSearchUtils } from '../../utils/text-search';
import { AnalyticsPage } from '../analytics-page';
import { ChartRequest } from '../../../shared/utils/chart-request';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

@Component({
  selector: 'app-most-searched-vin-numbers',
  templateUrl: './most-searched-vin-numbers.component.html',
  styleUrl: './most-searched-vin-numbers.component.scss'
})
export class MostSearchedVinNumbersPage extends AnalyticsPage {
  get pageName(): string {
    return 'Most searched vin numbers';
  }

  get pageStoreKey(): string {
    return "MostSearchedVinnumbersComponent";
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.SEARCH_STRING];
  }

  hasChanges(): boolean {
    return false;
  }

  private readonly barChartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    indexAxis: 'y',
    plugins: {
      datalabels: {
        display: true,
        anchor: 'start',
        align: 'left',
        offset: -60,
        color: 'black',
        formatter: (value, context) => `${((value / this.totalFound) * 100).toFixed(2)}%`
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const data = this.standardDataSnapshot.originalData;
            return tooltipItems.map(tooltipItem => data[tooltipItem.dataIndex].name);
          }
        }
      },
    },
  };

  dateRangeLabel: string;

  chartData: ChartData;
  chartOptions: ChartOptions;
  totalFound: number;

  selectedTopOptions: 10 | 20 | 30 = 10;

  searchTerm: string = '';

  filterSnapshot: AnalyticsFilterData;
  standardDataSnapshot: AnalyticsChartData;

  constructor(
    protected api: ApiService,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey, {
      showBrandFilter: false,
      showGenericArticleFilter: false,
    });

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  override ngAfterViewInit(): void {
    // To avoid NG0100 ("Expression has changed after it was checked") error
    setTimeout(() => this.chartOptions = this.barChartOptions);
  }

  private getChartRequest(filterData?: AnalyticsFilterData): ChartRequest {
    if (!filterData) {
      filterData = this.filterSnapshot;
    }

    const chartRequest = new ChartRequest('vin_search');
    if (this.searchTerm?.length) {
      chartRequest.params.set('search.search_string', [this.searchTerm])
    }
    chartRequest.setParams(filterData, this.pageFacets);

    return chartRequest;
  }

  private requestData(filterData: AnalyticsFilterData) {
    this.api.getReport(this.reportPath, this.getChartRequest(filterData))
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate)

        const numbersData = data.facets.get('search_string:bl:')
        this.standardDataSnapshot = cloneDeep(numbersData)
        this.totalFound = numbersData.numFound;

        this.chartData = {
          labels: numbersData.originalData.map(x => x.name),
          datasets: [
            {
              data: numbersData.originalData.map(x => x.count),
            }
          ]
        };
        this.chartData.datasets[0].backgroundColor = '#9ad0f5';
      });
  }

  //#region Event Handler

  onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter);
  }

  onSearch(term: string) {
    this.searchTerm = TextSearchUtils.getWildcardEscapedString(term);
    this.requestData(this.filterSnapshot)
  }

  onExportCsvClick() {
    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getChartRequest());
  }

  //#endregion
}
