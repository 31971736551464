import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ChartData, ChartOptions } from 'chart.js';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { DateUtils } from '@app/shared/utils/date-utils';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { TimeLineData } from '@app/shared/models/analytics/time-line-data';
import { FacetItem } from '@app/shared/models/facet-item';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { BAR_CHART_DEFAULT_OPTIONS, DEFAULT_BACKGROUND_COLORS } from '@app/shared/components/chart-panel/chart-default-options';

import { AnalyticsPage } from '../analytics-page';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

type DatasetSelection = 'sessions' | 'pageViews' | 'sessionsPerCountries' | 'all';

@Component({
  selector: 'app-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrl: './user-stats.component.scss'
})
export class UserStatsPage extends AnalyticsPage {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Most searched visitors';
  }

  get pageStoreKey(): string {
    return "TAAnalyticsDashboardPage";
  }

  get reportPath(): string {
    return '/piwikApi/all';
  }

  get exportPath(): string {
    return '/piwikApi/export';
  }

  get pageFacets(): string[] {
    return ['visits', 'pageViews', 'countries'];
  }

  override hasChanges(): boolean {
    return false;
  }

  //#endregion

  private readonly MAX_COUNTRIES = 30;

  sessionsChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: true,
      }
    },
    plugins: {
      legend: {
        onClick: (e) => e.native.preventDefault(), // Omit default behavior of toggle a value
        labels: {
          filter: (item, data) => { // Only show labels for selected datasources
            return !item.hidden;
          }
        }
      }
    },
  }

  pageViewsChartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    plugins: {
      legend: {
        onClick: (e) => e.native.preventDefault(), // Omit default behavior of toggle a value
        labels: {
          filter: (item, data) => { // Only show labels for selected datasources
            return !item.hidden;
          }
        }
      }
    },
  };

  sessionsPerCountriesChartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    plugins: {
      legend: {
        onClick: (e) => e.native.preventDefault(), // Omit default behavior of toggle a value
        labels: {
          filter: (item, data) => { // Only show labels for selected datasources
            return !item.hidden;
          }
        }
      }
    },
  }

  dateRangeLabel: string;

  filterSnapshot: AnalyticsFilterData;

  sessionsDatasets: TimeLineData[];
  sessionsSelectedDatasets: TimeLineData[] = [];
  pageViewsDatasets: TimeLineData[];
  pageViewsSelectedDatasets: TimeLineData[] = [];
  sessionsPerCountriesDatasets: TimeLineData[];
  sessionsPerCountriesSelectedDatasets: TimeLineData[] = [];

  sessionsChartData: ChartData;
  pageViewsChartData: ChartData;
  sessionsPerCountriesChartData: ChartData;

  catalogs: {id: string, name: string}[];
  catalogsData: {[key: string]: FacetItem[]} = {};

  constructor(
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey, {
      showCountryFilter: false,
      showBrandFilter: false,
      showGenericArticleFilter: false,
    });

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  private getChartRequest(filterData?: AnalyticsFilterData, facetId?: string): ChartRequest {
    if (!filterData) {
      filterData = this.filterSnapshot;
    }

    const facets = facetId
      ? [facetId]
      : this.pageFacets;
    const chartRequest = new ChartRequest('article_selection');
    chartRequest.setParams(filterData, facets);

    return chartRequest;
  }

  private requestData(filter: AnalyticsFilterData, dataset: DatasetSelection) {
    this.api.getReport(this.reportPath, this.getChartRequest(filter))
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate);
        const setDatasetColors = (dataset: TimeLineData, index: number) => {
          dataset.color = DEFAULT_BACKGROUND_COLORS[index % DEFAULT_BACKGROUND_COLORS.length]
        };

        // Sessions data
        if (dataset === 'sessions' || dataset === 'all') {
          const sessionsData = data.facets.get('visits');

          this.sessionsDatasets = sessionsData.timeLine.datasets;
          this.sessionsDatasets.forEach((dataset, i) => setDatasetColors(dataset, i));
          this.sessionsSelectedDatasets = this.sessionsDatasets.filter(d => d.filledData);

          this.sessionsChartData = {
            labels: sessionsData.timeLine.labels,
            datasets: sessionsData.timeLine.datasets.map((dataset, i) => ({
              data: dataset.data,
              label: dataset.label,
              backgroundColor: DEFAULT_BACKGROUND_COLORS[i % DEFAULT_BACKGROUND_COLORS.length],
              hidden: !dataset.filledData,
            })),
          };
        }

        // Page views data
        if (dataset === 'pageViews' || dataset === 'all') {
          const pageViewsData = data.facets.get('pageViews');

          this.pageViewsDatasets = pageViewsData.timeLine.datasets;
          this.pageViewsDatasets.forEach((dataset, i) => setDatasetColors(dataset, i));
          this.pageViewsSelectedDatasets = this.pageViewsDatasets.filter(d => d.filledData);

          this.pageViewsChartData = {
            labels: pageViewsData.timeLine.labels,
            datasets: pageViewsData.timeLine.datasets.map((dataset, i) => ({
              data: dataset.data,
              label: dataset.label,
              backgroundColor: DEFAULT_BACKGROUND_COLORS[i % DEFAULT_BACKGROUND_COLORS.length],
              hidden: !dataset.filledData,
            })),
          };
        }

        // Sessions per Countries data
        if (dataset === 'sessionsPerCountries' || dataset === 'all') {
          const sessionsPerCountriesData = data.facets.get('countries');

          this.sessionsPerCountriesDatasets = sessionsPerCountriesData.timeLine.datasets;
          this.sessionsPerCountriesDatasets.forEach((dataset, i) => setDatasetColors(dataset, i));
          this.sessionsPerCountriesSelectedDatasets = this.sessionsPerCountriesDatasets.filter(d => d.filledData);

          this.sessionsPerCountriesChartData = {
            labels: sessionsPerCountriesData.timeLine.labels.slice(0, this.MAX_COUNTRIES),
            datasets: sessionsPerCountriesData.timeLine.datasets.map((dataset, i) => ({
              data: dataset.data?.slice(0, this.MAX_COUNTRIES),
              label: dataset.label,
              backgroundColor: DEFAULT_BACKGROUND_COLORS[i % DEFAULT_BACKGROUND_COLORS.length],
              hidden: !dataset.filledData,
            })),
          };
        }


        if (dataset !== 'all') {
          return;
        }

        // Catalogs data
        this.catalogs = data.facets.get('catalogNames').originalData.map(x => ({id: x.id, name: x.name}));
        data.facets.forEach((value, key) => {
          if (!key.startsWith('users_')) {
            return;
          }

          this.catalogsData[key] = value.originalData;
        });
      });
  }

  onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter, 'all');
  }

  onSessionsSelectedDatasetsChange(selection: TimeLineData[]) {
    this.requestData(this.filterSnapshot, 'sessions');
  }

  onPageViewsSelectedDatasetsChange(selection: TimeLineData[]) {
    this.requestData(this.filterSnapshot, 'pageViews');
  }

  onSessionsPerCountriesSelectedDatasetsChange(selection: TimeLineData[]) {
    this.requestData(this.filterSnapshot, 'sessionsPerCountries');
  }

  onSessionsChartExportCsvClick() {
    const request = this.getChartRequest(undefined, 'visits:dtl:');
    request.params.set('visits_tl_datasets', this.sessionsSelectedDatasets.map(x => x.id));
    this.api.doExport(this.exportPath, new DownloadHandler(false), request);
  }

  onPageViewsChartExportCsvClick() {
    const request = this.getChartRequest(undefined, 'pageViews:dtl:');
    request.params.set('pageViews_tl_datasets', this.pageViewsSelectedDatasets.map(x => x.id));
    this.api.doExport(this.exportPath, new DownloadHandler(false), request);
  }

  onSessionsPerCountryChartExportCsvClick() {
    const request = this.getChartRequest(undefined, 'countries:dtl:')
    request.params.set('countries_tl_datasets', this.sessionsPerCountriesSelectedDatasets.map(x => x.id));
    this.api.doExport(this.exportPath, new DownloadHandler(false), request);
  }

  onUsersTrafficCompareCsvExport(catalogId: string) {
    const catalogKey = `users_${catalogId}`;

    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getChartRequest(undefined, catalogKey));
  }
}
