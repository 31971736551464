<div class="d-flex flex-column h-100">
  <div class="flex-grow-1">
    <chart-panel [panelStyleClass]="'h-100'"
      [chartData]="chartData"
      [chartOptions]="chartOptions"
      [addTourAnchors]="true"
      [headerLabel]="'MARKET_OVERVIEW_COMPETITION.HEADER_LABEL' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'MARKET_OVERVIEW_COMPETITION.INFO_TEXT' | translate"
      [showTopDropdown]="false"
      [enableChartTypeToggle]="true"
      [selectedTopOptions]="30"
      (chartTypeChange)="onChartTypeChanged($event)"
      (exportCsvClick)="onExportCsvClick()">
    </chart-panel>
  </div>
</div>
