<div class="d-flex flex-column">

  <!-- General info card -->
  <div class="card col-6 mb-3">
    <div class="card-header">
      {{ 'ARTICLE_VIEWS_COUNTRIES_DETAILS.CARD_HEADER' | translate }}
    </div>
    <div class="card-body">
      <table class="table">
        <tr>
          <td class="col-3">{{ 'ARTICLE_VIEWS_COUNTRIES_DETAILS.CARD_DETAIL_1' | translate }}:</td>
          <td>{{ countryName }}</td>
        </tr>
      </table>
    </div>
  </div>

  <!-- Charts cards -->
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between mb-3">
      <chart-panel class="col-6"
        [panelStyle]="{'height': '550px', 'width': '99%'}"
        [headerLabel]="'ARTICLE_VIEWS_COUNTRIES_DETAILS.CHART_HEADER_LABEL_1' | translate"
        [infoText]="countryName"
        [chartOptions]="genartsChartOptions"
        [chartData]="genartsChartData"
        (exportCsvClick)="onExportCsvClick(['genart_id'])">
      </chart-panel>
      <chart-panel class="col-6"
        [panelStyle]="{'height': '550px', 'width': '99%'}"
        [headerLabel]="'ARTICLE_VIEWS_COUNTRIES_DETAILS.CHART_HEADER_LABEL_2' | translate"
        [infoText]="countryName"
        [showSearch]="true"
        (searchStart)="onArticleNumbersSearchStart($event)"
        [chartOptions]="articleNumbersChartOptions"
        [chartData]="articleNumbersChartData"
        (exportCsvClick)="onExportCsvClick(['brand_article_number'])">
      </chart-panel>
    </div>
    <div class="d-flex justify-content-between">
      <chart-panel class="col-6"
        [panelStyle]="{'height': '550px', 'width': '99%'}"
        [headerLabel]="'ARTICLE_VIEWS_COUNTRIES_DETAILS.CHART_HEADER_LABEL_3' | translate"
        [infoText]="countryName"
        [showSearch]="true"
        (searchStart)="onOeNumbersSearchStart($event)"
        [chartOptions]="oeNumbersChartOptions"
        [chartData]="oeNumbersChartData"
        (exportCsvClick)="onExportCsvClick(['oe_number'])">
      </chart-panel>
      <chart-panel class="col-6"
        [panelStyle]="{'height': '550px', 'width': '99%'}"
        [headerLabel]="'ARTICLE_VIEWS_COUNTRIES_DETAILS.CHART_HEADER_LABEL_4' | translate"
        [infoText]="countryName"
        [showSearch]="true"
        (searchStart)="onVehiclesSearchStart($event)"
        [chartOptions]="vehiclesChartOptions"
        [chartData]="vehiclesChartData"
        (exportCsvClick)="onExportCsvClick(['linking_target_id'])">
      </chart-panel>
    </div>
  </div>

</div>
