import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ChartData, ChartOptions } from 'chart.js';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';

import { AnalyticsPage } from '../analytics-page';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

@Component({
  selector: 'app-solr-monitoring',
  templateUrl: './solr-monitoring.component.html',
  styleUrl: './solr-monitoring.component.scss'
})
export class SolrMonitoringPage extends AnalyticsPage {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Core Timelines';
  }

  get pageStoreKey(): string {
    return 'CoreTimeLines';
  }

  get reportPath(): string {
    return '/monitoring/eventsTimeLineAllCores';
  }

  get exportPath(): string {
    return '/monitoring/eventsTimeLineAllCores';
  }

  get pageFacets(): string[] {
    return [];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  readonly chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      }
    },
    plugins: {
      legend: {
        onClick: (e) => e.native.preventDefault(), // Omit default behavior of toggle a value
        labels: {
          filter: (item, data) => { // Only show labels for selected datasources
            return !item.hidden;
          }
        }
      }
    },
  }

  datasets: ChartData[];

  filterSnapshot: AnalyticsFilterData;

  constructor(
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey, {
      showBrandFilter: false,
      showCountryFilter: false,
      showDatasourceFilter: false,
      showGenericArticleFilter: false,
    });

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  private requestData(filterData: AnalyticsFilterData) {
    const chartRequest = new ChartRequest('*');
    chartRequest.setParams(filterData, this.pageFacets);

    this.api.getReport(this.reportPath, chartRequest)
      .subscribe(data => {
        const brandsData = data.facets.get('server_time');

        this.datasets = brandsData.timeLine.datasets.map(dataset => ({
          labels: brandsData.timeLine.labels,
          datasets: [
            {
              label: dataset.label,
              data: dataset.data,
              tension: 0.4,
            }
          ]
        }));
      });
  }

  onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter);
  }
}
