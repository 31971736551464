import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';

import { CoreModule } from '@app/core/core.module';
import { AuthTypes } from '@app/core/security/auth-types';
import { WebShopContext } from '@app/shared/models/web-shop-context';
import { SharedModule } from '@app/shared/shared.module';
import { AnonymousModule } from '@app/anonymous/anonymous.module';
import { AnalyticsModule } from '@app/analytics/analytics.module';

import { BootstrapRoutingModule } from './bootstrap-routing.module';
import { AppComponent } from './components/app.component';

/**
 * Initial app module. Loads the basic functionalities to startup the application.
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    BootstrapRoutingModule,
    FormsModule,
    ReactiveFormsModule,

    // Translation module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),

    // App modules
    CoreModule.forRoot({
      authType: AuthTypes.Cis,
      contextFactory: WebShopContext.factory,
      userFactory: undefined,
    }),
    SharedModule,
    AnonymousModule,
    AnalyticsModule,

    // Third-party modules
    TooltipModule,
    TourNgBootstrapModule,
    DropdownModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
  ],
  providers: [
    MessageService,
  ],
})
export class BootstrapModule { }
