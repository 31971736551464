<div class="d-flex flex-column">
  <div class="d-flex justify-content-center mb-3">
    <p-selectButton [options]="availableDatasources" [optionLabel]="'datasourceName'" [(ngModel)]="selectedDatasource"></p-selectButton>
  </div>
  <div class="flex-grow-1" *ngIf="selectedDatasource">
    <div class="row mb-3">
      <div class="col-6">
        <div class="card rounded-0">
          <div class="card-body">
            <h6>{{ 'USER_STATS_PREMIUM.OVERVIEW' | translate }}</h6>
            <span class="label">
              {{ 'USER_STATS_PREMIUM.SESSIONS' | translate }}
            </span>
            <h4>{{ selectedDatasource.sessions | number: undefined: 'de' }}</h4>
            <span class="label">
              {{ 'USER_STATS_PREMIUM.BOUNCE_RATE' | translate }}
            </span>
            <h4>{{ selectedDatasource.bounceRate | number: undefined: 'de' }} %</h4>
            <span class="label">
              {{ 'USER_STATS_PREMIUM.PAGE_VIEWS' | translate }}
            </span>
            <h4>{{ selectedDatasource.pageViews | number: undefined: 'de' }}</h4>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card rounded-0">
          <div class="card-body">
            <h6>{{ 'USER_STATS_PREMIUM.VISITORS' | translate }}</h6>
            <h4>{{ selectedDatasource.visitors | number: undefined: 'de' }}</h4>
            <p-chart type="pie"
              [data]="selectedDatasource.visitorsRateChartData"
              [options]="doughnutChartOptions"
              [height]="'145px'">
            </p-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-1 mb-3">
      <h6>{{ 'USER_STATS_PREMIUM.BY_REFERER_TYPE_HEADER' | translate }}</h6>
      <p-chart type="line"
        [data]="selectedDatasource.visitsByRefererType"
        [options]="lineChartOptions"
        [height]="'270px'">
      </p-chart>
    </div>
    <div class="row px-3">
      <div class="card rounded-0 p-0">
        <div class="card-header d-flex align-items-center justify-content-between p-0 ps-2">
          <!-- Left side -->
          <div class="flex-grow-1 py-2">
            <h6 class="m-0">{{ 'USER_STATS_PREMIUM.BY_SOURCE_MEDIUM_HEADER' | translate }}</h6>
          </div>

          <!-- Right side -->
          <div class="flex-shrink-0 d-flex align-items-center" [style.height.px]="45">
            <!-- Top 10, 20, 30 -->
            <p-dropdown
              [options]="topOptions"
              [(ngModel)]="selectedTopOption"
              class="mx-2"
              [styleClass]="'xs rounded-0'"
              [panelStyleClass]="'items-no-padding xs'">
            </p-dropdown>

            <button
              pTooltip="Save"
              tooltipPosition="left"
              class="btn text-primary border-0 border-start rounded-0 py-0 px-2 h-100"
              (click)="onSaveClick()">
              <i class="fa ta-icon-download text-black"></i>
            </button>
          </div>
        </div>
        <div class="card-body row">
          <table class="table m-0">
            <thead>
              <tr>
                <th scope="col">{{ 'USER_STATS_PREMIUM.SOURCE_MEDIUM' | translate }}</th>
                <th scope="col">{{ 'USER_STATS_PREMIUM.VISITORS' | translate }}</th>
                <th scope="col">{{ 'USER_STATS_PREMIUM.AVG_PAGE_VIEWS_IN_SESSION' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sourceVisits of topVisitsBySourceMedium">
                <th class="fw-normal" scope="row">{{ sourceVisits.name }}</th>
                <th class="fw-normal">{{ sourceVisits.details['Visitors'] }} ({{ getVisitorsBySourcePercentage(sourceVisits) }}%)</th>
                <th class="fw-normal">{{ sourceVisits.details['Average page views in session'] | number: '1.0-2': 'de' }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
