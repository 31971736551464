import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeyValue } from '@angular/common';

import { ConfirmationService } from 'primeng/api';
import moment from 'moment';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { TaskTrackerService, TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { DownloadInfo } from '@app/shared/models/download-info';
import { FullExportFormatInfo } from '@app/shared/models/full-export-format-info';
import { FullExportItem } from '@app/shared/models/full-export-item';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { DownloadStatus } from '@app/shared/models/download-status';
import { PageComponent } from '@app/shared/components/page';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

type DownloadType = 'last12Months' | 'download' | 'fullExport';

@Component({
  selector: 'app-full-export',
  templateUrl: './full-export.component.html',
  styleUrl: './full-export.component.scss'
})
export class FullExportPage extends PageComponent {
  get pageName(): string {
    return 'FullExport Listing';
  }

  get pageStoreKey(): string {
    return 'full-export';
  }

  hasChanges(): boolean {
    return false;
  }

  formatInfo: FullExportFormatInfo[]
  specialDownloads: DownloadInfo[];
  fullExportPerYearAndMonth: {[year: number]: {[month: string]: FullExportItem[]}} = {};

  downloadHandlers = new Map<string, DownloadHandler>();
  DownloadStatus = DownloadStatus;

  constructor(
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
    private translate: TranslateService,
    private taskTracker: TaskTrackerService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.disableFilter();
  }

  override ngOnInit(): void {
    this.taskTracker.add(
      this.api.getFullExportListing(this.pageStoreKey)
        .subscribe(exportList => {
          // Setup format info
          this.formatInfo = exportList.formatInfo.reverse();

          // Setup downloads (including last 12 months at the end)
          this.specialDownloads = [
            ...exportList.downloads,
            {
              id: 'last12M',
              name: this.translate.instant('FULL_EXPORT.LAST_12_MONTHS'),
            }
          ];

          // Setup the full exports dictionary, grouped by year and month
          Object.entries(_.groupBy(exportList.listing, 'year')).forEach(([year, yearValues]) => {
            this.fullExportPerYearAndMonth[+year] = {};
            Object.entries(_.groupBy(yearValues, 'month')).forEach(([month, monthValues]) => {
              this.fullExportPerYearAndMonth[+year][month] = _.sortBy(monthValues, 'viewId');
            });
          });
        })
      );
  }

  /**
   * Helper to be used with KeyValuePipe, which sorts the KeyValues desc by key.
   */
  keyDescOrder<T>(a: KeyValue<string, T>, b: KeyValue<string, T>): number {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }

  getMonthName(month: string): string {
    return moment().month(+month - 1).format('MMMM');
  }

  getDownloadStatus(id: string): DownloadStatus {
    return this.downloadHandlers.has(id)
      ? this.downloadHandlers.get(id).downloadStatus
      : DownloadStatus.NONE;
  }

  private getLinkByDownloadType(type: DownloadType, id: string = ''): string {
    switch (type) {
      case 'last12Months':
        return this.api.apiUrl('/rawexport/compose/12month');
      case 'download':
        return this.api.apiUrl('/download/byId/' + id);
      case 'fullExport':
        return this.api.apiUrl('/rawexport/compose/' + id);
    }
  }

  onDownloadClick(event: MouseEvent, id: string, type: DownloadType = 'fullExport') {
    event.preventDefault();

    const link = this.getLinkByDownloadType(type, id);

    if (!this.downloadHandlers.has(id)) {
      this.downloadHandlers.set(id, new DownloadHandler(true));
    }
    const downloadHandler = this.downloadHandlers.get(id);

    if (downloadHandler.downloadStatus !== DownloadStatus.NONE) {
      // There is a download started or in progress for this ID, trigger cancel confirmation.
      this.confirmation.confirm({
        target: event.target,
        message: this.translate.instant('FULL_EXPORT.CANCEL_DOWNLOAD'),
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'ms-2',
        rejectButtonStyleClass: 'me-2',
        accept: () => downloadHandler.cancel(),
      });

      return;
    }

    this.api.download(link, downloadHandler);
  }
}
