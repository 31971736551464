<div class="d-flex flex-column">

  <!-- General info card -->
  <div class="card col-6 mb-3">
    <div class="card-header">
      {{ 'ARTICLE_VIEWS_GENART_DETAILS.CARD_HEADER' | translate }}
    </div>
    <div class="card-body">
      <table class="table">
        <tr *ngIf="displayName.length">
          <td class="col-3">{{ 'ARTICLE_VIEWS_GENART_DETAILS.CARD_DETAIL_1' | translate }}:</td>
          <td>{{ displayName }}</td>
        </tr>
        <tr *ngIf="genartNo?.length">
          <td class="col-3">{{ 'ARTICLE_VIEWS_GENART_DETAILS.CARD_DETAIL_2' | translate }}:</td>
          <td>{{ genartNo }}</td>
        </tr>
      </table>
    </div>
  </div>

  <!-- Charts cards -->
  <div class="d-flex row">
    <div *ngFor="let chartData of visibleChartsData" class="col-3 mb-3">
      <chart-panel
        [panelStyle]="{'minHeight': '600px'}"
        [infoText]="chartData.infoText"
        [headerLabel]="chartData.headerLabel"
        [showTopDropdown]="false"
        [chartOptions]="chartOptions"
        [selectedChartType]="'doughnut'"
        [chartData]="chartData.chartData"
        (exportCsvClick)="onReportExportCsvClick(chartData.facetId)">
      </chart-panel>
    </div>
  </div>

</div>
