import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ChartData, ChartOptions } from 'chart.js';
import { cloneDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { MapOptions, latLng } from 'leaflet';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';
import { TimeLineData } from '@app/shared/models/analytics/time-line-data';
import { ChartData as AnalyticsChartData } from '@app/shared/models/analytics/chart-data';
import { FacetItem } from '@app/shared/models/facet-item';
import { BAR_CHART_DEFAULT_OPTIONS, DEFAULT_BACKGROUND_COLORS } from '@app/shared/components/chart-panel/chart-default-options';
import { ChartType } from '@app/shared/components/chart-panel/chart-panel.component';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { DateUtils } from '@app/shared/utils/date-utils';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

import { AnalyticsPage } from '../analytics-page';
import { AnalyticsDetailsPageQueryParams } from '../../models/details-page-query-params';
import { AnalyticsNavigationUtils } from '../../utils/navigation';

@Component({
  selector: 'app-article-views-countries',
  templateUrl: './article-views-countries.component.html',
  styleUrl: './article-views-countries.component.scss'
})
export class ArticleViewsCountriesPage extends AnalyticsPage {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Most searched countries';
  }

  get pageStoreKey(): string {
    return "MostSearchedCountriesComponent";
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.LOCATION_COUNTRY, Fields.LOCATION_COUNTRY_BRANDLESS];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  get isTimelineChart(): boolean {
    return this.chartType === 'line';
  }

  private readonly barChartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    onHover: (event, elements) => {
      event.native.target['style'].cursor = elements?.length ? 'pointer' : 'default';
    },
    onClick: (event, elements) => {
      if (!elements?.length) {
        return;
      }

      const clickedElementData = this.standardDataSnapshot.originalData[elements[0].index];
      this.ngZone.run(() => this.navigateToDetailsPage(clickedElementData.id, clickedElementData.label));
    }
  };

  private readonly lineChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      }
    },
    plugins: {
      legend: {
        onClick: (e) => e.native.preventDefault(), // Omit default behavior of toggle a value
        labels: {
          filter: (item, data) => { // Only show labels for selected datasources
            return !item.hidden;
          }
        }
      }
    },
    onHover: (event, elements) => {
      event.native.target['style'].cursor = elements?.length ? 'pointer' : 'default';
    },
    onClick: (event, elements) => {
      if (!elements?.length) {
        return;
      }

      const clickedElementData = this.datasets[elements[0].datasetIndex];
      this.ngZone.run(() => this.navigateToDetailsPage(clickedElementData.id, clickedElementData.label));
    }
  }

  dateRangeLabel: string;

  chartType: ChartType = 'bar';
  chartData: ChartData;
  chartOptions: ChartOptions;
  totalFound: number;

  selectedTopOptions: 10 | 20 | 30 = 10;

  filterSnapshot: AnalyticsFilterData;
  standardDataSnapshot: AnalyticsChartData;

  datasets: TimeLineData[];
  selectedDatasets: TimeLineData[] = [];

  mapOptions: MapOptions = {
    // NOTE: The following tile layer is disabled because the access_token seems to be invalid (Response HTTP 403 Forbidden)
    // layers: [
      // tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
      //   maxZoom: 18,
      //   attribution: 'TecAlliance',
      //   id: 'mapbox/light-v9',
      //   tileSize: 512,
      //   zoomOffset: -1,
      //   noWrap: true,
      //   bounds: [
      //     [-90, -180],
      //     [90, 180]
      //   ],
      //   minZoom: 1
      // })
    // ],
    zoom: 2,
    center: latLng(14.5994, 28.6731),
    scrollWheelZoom: false,
    maxBounds: [
      [-90.0, -180.0],
      [180.0, 180.0]
    ]
  };
  mapData: FacetItem[];

  constructor(
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey);

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  override ngAfterViewInit(): void {
    // To avoid NG0100 ("Expression has changed after it was checked") error
    setTimeout(() => this.chartOptions = this.barChartOptions);
  }

  private getChartRequest(filterData?: AnalyticsFilterData): ChartRequest {
    if (!filterData) {
      filterData = this.filterSnapshot;
    }

    const facets = this.isTimelineChart
      ? [this.pageFacets[0].concat(Fields.DEMAND_TIME_LINE)]
      : this.pageFacets;
    const chartRequest = new ChartRequest('article_selection');
    chartRequest.setParams(filterData, facets, this.isTimelineChart ? undefined : -1);

    // Add brands selection to the request params
    if (this.isTimelineChart && this.selectedDatasets?.length) {
      chartRequest.params.set('location_country_tl_datasets', this.selectedDatasets.map(dataset => dataset.id));
    }

    return chartRequest;
  }

  private requestData(filter: AnalyticsFilterData) {
    this.isTimelineChart
      ? this.requestTimelineData(filter)
      : this.requestStandardData(filter);
  }

  private requestStandardData(filterData: AnalyticsFilterData) {
    this.api.getReport(this.reportPath, this.getChartRequest(filterData))
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate);

        const articleNumbersData = data.facets.get('location_country');
        this.standardDataSnapshot = cloneDeep(articleNumbersData);
        this.totalFound = articleNumbersData.numFound;

        this.chartData = {
          labels: articleNumbersData.originalData.map(x => x.name),
          datasets: [
            {
              data: articleNumbersData.originalData.map(x => x.count),
            }
          ]
        };

        this.setChartColors();

        this.mapData = Array.from(articleNumbersData.originalData);
      });
  }

  private requestTimelineData(filterData: AnalyticsFilterData) {
    this.api.getReport(this.reportPath, this.getChartRequest(filterData))
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate);

        const articleNumbersData = data.facets.get('location_country');
        this.totalFound = articleNumbersData.numFound;

        const setDatasetColors = (dataset: TimeLineData, index: number) => {
          dataset.color = DEFAULT_BACKGROUND_COLORS[index % DEFAULT_BACKGROUND_COLORS.length]
        };

        this.datasets = articleNumbersData.timeLine.datasets;
        this.datasets.forEach((dataset, i) => setDatasetColors(dataset, i));
        this.selectedDatasets = this.datasets.filter(d => d.filledData);

        this.chartData = {
          labels: articleNumbersData.timeLine.labels,
          datasets: articleNumbersData.timeLine.datasets.map((dataset, i) => ({
            data: dataset.data,
            label: dataset.label,
            backgroundColor: DEFAULT_BACKGROUND_COLORS[i % DEFAULT_BACKGROUND_COLORS.length],
            hidden: !dataset.filledData,
          })),
        };

        this.setChartColors();
      });
  }

  private setChartOptions() {
    switch (this.chartType) {
      case 'bar':
        this.chartOptions = this.barChartOptions;
        break;
      case 'line':
        this.chartOptions = this.lineChartOptions;
        break;
    }
  }

  private setChartColors() {
    this.chartData.datasets[0].backgroundColor = '#9ad0f5';
  }

  private navigateToDetailsPage(countryId: string, countryName: string) {
    const filterTimespan = this.filterSnapshot.timespan;
    const queryParams: AnalyticsDetailsPageQueryParams = {
      countryId,
      countryName,
      fieldName: 'location_country',
      paramsMap: AnalyticsNavigationUtils.getSerializedParamsMap(this.filterSnapshot),
      genartNo: this.filterSnapshot.genarts.join(',') || undefined,
      brandNo: this.filterSnapshot.brands.join(',') || undefined,
      timespan: filterTimespan.isRange ? undefined : filterTimespan.dates as string,
      fromDate: filterTimespan.isRange ? filterTimespan.dates[0] : undefined,
      toDate: filterTimespan.isRange ? filterTimespan.dates[1] : undefined,
    };

    this.router.navigate(['details/level1'], {
      relativeTo: this.route,
      queryParams
    });
  }

  //#region Event handlers

  onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter);
  }

  onChartTypeChanged(chartType: ChartType) {
    this.chartType = chartType;
    this.requestData(this.filterSnapshot);
    this.setChartOptions();
    this.setChartColors();
  }

  onSelectedDatasetsChange(selectedDatasets: TimeLineData[]) {
    this.requestData(this.filterSnapshot);
  }

  onExportCsvClick() {
    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getChartRequest());
  }
}
