import { Component, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ChartOptions, ChartData } from 'chart.js';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';
import { ChartData as AnalyticsChartData } from '@app/shared/models/analytics/chart-data';
import { VehicleGranularity } from '@app/shared/enums/vehicle-granularity';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { DateUtils } from '@app/shared/utils/date-utils';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { BAR_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';

import { CustomTooltipsUtils } from '../../utils/custom-tooltips';
import { TextSearchUtils } from '../../utils/text-search';
import { AnalyticsPage } from '../analytics-page';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

@Component({
  selector: 'app-most-searched-vehicles',
  templateUrl: './most-searched-vehicles.component.html',
  styleUrl: './most-searched-vehicles.component.scss'
})
export class MostSearchedVehiclesPage extends AnalyticsPage {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Most searched vehicles';
  }

  get pageStoreKey(): string {
    return "MostSearchedVehiclesComponent";
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.VEHICLE_MAKES_BRANDLESS, Fields.VEHICLE_MODELS_BRANDLESS, Fields.VEHICLES_BRANDLESS];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  get showSearch(): boolean {
    return this.selectedVehicleGranularity === VehicleGranularity.Vehicles;
  }

  get granularitySelectedChartData(): AnalyticsChartData {
    switch (this.selectedVehicleGranularity) {
      case VehicleGranularity.Vehicles:
        return this.vehiclesDataSnapshot;
      case VehicleGranularity.Models:
        return this.modelsDataSnapshot;
      case VehicleGranularity.Manufacturers:
        return this.manufacturersDataSnapshot;
    }
  }

  get chartPanelHeaderLabel(): string {
    return this.vehicleGranularities.find(x => x.value === this.selectedVehicleGranularity)?.label;
  }

  private readonly barChartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    indexAxis: 'y',
    plugins: {
      datalabels: {
        display: true,
        anchor: 'start',
        align: 'left',
        offset: -60,
        color: 'black',
        formatter: (value, context) => `${((value / this.totalFound) * 100).toFixed(2)}%`
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: (context) => {
          /*
           *  In case the vehicle granularity is set to 'Vehicle', show also details about the vehicle;
           *  otherwise, just show the raw data, as on standard tooltips.
           */
          const showVehicleDetails = this.selectedVehicleGranularity === VehicleGranularity.Vehicles;
          CustomTooltipsUtils.setupVehicleDetailsTooltip(context, this.granularitySelectedChartData.originalData, showVehicleDetails);
        }
      },
    },
  };

  dateRangeLabel: string;

  chartData: ChartData;
  chartOptions: ChartOptions;
  totalFound: number;

  selectedTopOptions: 10 | 20 | 30 = 10;

  vehicleGranularities = [];
  selectedVehicleGranularity = VehicleGranularity.Vehicles;

  searchTerm: string = '';

  filterSnapshot: AnalyticsFilterData;
  vehiclesDataSnapshot: AnalyticsChartData;
  modelsDataSnapshot: AnalyticsChartData;
  manufacturersDataSnapshot: AnalyticsChartData;

  constructor(
    private translate: TranslateService,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router);

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey, {
      showBrandFilter: false,
      showGenericArticleFilter: false,
      showVehicleTypesFilter: true,
    });

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  override ngOnInit() {
    this.vehicleGranularities = this.getTranslatedOptions();
  }

  override ngAfterViewInit(): void {
    // To avoid NG0100 ("Expression has changed after it was checked") error
    setTimeout(() => this.chartOptions = this.barChartOptions);
  }

  private translateEnum(granularity: VehicleGranularity): string {
    return this.translate.instant(`COMMON.VEHICLE_GRANULARITY_${granularity.toUpperCase()}`);
  }

  private getTranslatedOptions(): any[] {
    const options = Object.keys(VehicleGranularity).map(key => ({
      label: this.translateEnum(VehicleGranularity[key]),
      value: key
    }));
    return options;
  }

  private getChartRequest(filterData?: AnalyticsFilterData, facetId?: string): ChartRequest {
    if (!filterData) {
      filterData = this.filterSnapshot;
    }

    // Any searchType value from other page causes the chart to not display any data, force it to be empty
    filterData.searchTypes = [];

    const facets = facetId
      ? [facetId]
      : this.pageFacets;
    const chartRequest = new ChartRequest('vehicle_search');
    if (this.searchTerm?.length) {
      chartRequest.params.set('search.linking_target_id', [this.searchTerm]);
    }
    chartRequest.setParams(filterData, facets);

    return chartRequest;
  }

  private requestData(filter: AnalyticsFilterData) {
    this.api.getReport(this.reportPath, this.getChartRequest(filter))
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate);

        this.vehiclesDataSnapshot = cloneDeep(data.facets.get('linking_target_id:bl:'));
        this.modelsDataSnapshot = cloneDeep(data.facets.get('model_id:bl:'));
        this.manufacturersDataSnapshot = cloneDeep(data.facets.get('make_id:bl:'));

        this.setupChartData();
        this.setChartColors();
      });
  }

  private setupChartData() {
    this.totalFound = this.granularitySelectedChartData.numFound;

    this.chartData = {
      labels: this.granularitySelectedChartData.originalData.map(x => x.name),
      datasets: [
        {
          data: this.granularitySelectedChartData.originalData.map(x => x.count),
        }
      ]
    };
  }

  private setChartColors() {
    this.chartData.datasets[0].backgroundColor = '#9ad0f5';
  }

  //#region Event handlers

  onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter);
  }

  onSearch(term: string) {
    this.searchTerm = TextSearchUtils.getWildcardEscapedString(term);
    this.requestData(this.filterSnapshot);
  }

  onVehicleGranularityChange(event) {
    this.setupChartData();
    this.setChartColors();
  }

  onExportCsvClick() {
    let facetId: string;
    switch (this.selectedVehicleGranularity) {
      case VehicleGranularity.Vehicles:
        facetId = 'linking_target_id';
        break;
      case VehicleGranularity.Models:
        facetId = 'model_id';
        break;
      case VehicleGranularity.Manufacturers:
        facetId = 'make_id';
        break;
    }

    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getChartRequest(undefined, facetId));
  }
}
