import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { cloneDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { ChartData, ChartOptions } from "chart.js"

import { ApiService } from '@app/shared';
import { TrackerService } from '@app/core';
import { Fields } from '@app/shared/models/fields';
import { DateUtils } from '@app/shared/utils/date-utils';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { TimeLineData } from '@app/shared/models/analytics/time-line-data';
import { ChartType } from '@app/shared/components/chart-panel/chart-panel.component';
import { ChartData as AnalyticsChartData } from "@app/shared/models/analytics/chart-data"
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { BAR_CHART_DEFAULT_OPTIONS, DEFAULT_BACKGROUND_COLORS } from '@app/shared/components/chart-panel/chart-default-options';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';

import { TextSearchUtils } from '../../utils/text-search';
import { AnalyticsPage } from '../analytics-page';
import { ChartRequest } from '../../../shared/utils/chart-request';

@Component({
  selector: 'app-most-searched-number-plates',
  templateUrl: './most-searched-number-plates.component.html',
  styleUrl: './most-searched-number-plates.component.scss'
})
export class MostSearchedNumberPlatesPage extends AnalyticsPage {
  get pageName(): string {
    return 'Most searched number plates';
  }

  get pageStoreKey(): string {
    return "MostSearchedNumberPlatesComponent";
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.SEARCH_STRING];
  }

  hasChanges(): boolean {
    return false;
  }

  get isTimelineChart(): boolean {
    return this.chartType === 'line';
  }

  private readonly barChartOptions: ChartOptions = {
    ...BAR_CHART_DEFAULT_OPTIONS,
    indexAxis: 'y',
    plugins: {
      datalabels: {
        display: true,
        anchor: 'start',
        align: 'left',
        offset: -60,
        color: 'black',
        formatter: (value, context) => `${((value / this.totalFound) * 100).toFixed(2)}%`
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const data = this.standardDataSnapshot.originalData;
            return tooltipItems.map(tooltipItem => data[tooltipItem.dataIndex].name);
          }
        }
      },
    },
  };

  private readonly lineChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      }
    },
    plugins: {
      legend: {
        onClick: (e) => e.native.preventDefault(), // Omit default behavior of toggle a value
        labels: {
          filter: (item, data) => { // Only show labels for selected datasources
            return !item.hidden;
          }
        }
      }
    },
  }

  dateRangeLabel: string;

  chartData: ChartData;
  chartType: ChartType = 'bar';
  chartOptions: ChartOptions;
  totalFound: number;

  selectedTopOptions: 10 | 20 | 30 = 10;

  searchTerm: string = '';

  filterSnapshot: AnalyticsFilterData;
  standardDataSnapshot: AnalyticsChartData;

  datasets: TimeLineData[];
  selectedDatasets: TimeLineData[] = [];

  constructor(
    protected api: ApiService,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected override router: Router,
    private analyticsFilter: AnalyticsFilterService,
  ) {
    super(tracker, confirmation, api, router)

    this.analyticsFilter.setupFilterForPage(this.pageStoreKey, {
      showBrandFilter: false,
      showGenericArticleFilter: false,
      showNumberPlateTypeFilter: true,
    });

    this.analyticsFilter.applyFilter
      .subscribe(filterData => this.onFilterChange(filterData));
  }

  override ngAfterViewInit(): void {
    // To avoid NG0100 ("Expression has changed after it was checked") error
    setTimeout(() => this.chartOptions = this.barChartOptions);
  }

  private getChartRequest(filterData?: AnalyticsFilterData): ChartRequest {
    if (!filterData) {
      filterData = this.filterSnapshot;
    }

    const facets = this.isTimelineChart
      ? this.pageFacets.map(x => x.concat(Fields.DEMAND_TIME_LINE))
      : this.pageFacets;
    const chartRequest = new ChartRequest('numberplate_search');
    if (!this.isTimelineChart && this.searchTerm?.length) {
      chartRequest.params.set('search.search_string', [this.searchTerm])
    }
    chartRequest.setParams(filterData, facets);

    // Add brands selection to the request params
    if (this.isTimelineChart && this.selectedDatasets?.length) {
      chartRequest.params.set('search_string:bl:_tl_datasets', this.selectedDatasets.map(dataset => dataset.id));
    }

    return chartRequest;
  }

  private requestData(filter: AnalyticsFilterData) {
    this.isTimelineChart
      ? this.requestTimelineData(filter)
      : this.requestStandardData(filter);
  }

  private requestStandardData(filterData: AnalyticsFilterData) {
    this.api.getReport(this.reportPath, this.getChartRequest(filterData))
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate)

        const numbersData = data.facets.get('search_string:bl:')
        this.standardDataSnapshot = cloneDeep(numbersData)
        this.totalFound = numbersData.numFound;

        this.chartData = {
          labels: numbersData.originalData.map(x => x.name),
          datasets: [
            {
              data: numbersData.originalData.map(x => x.count),
            }
          ]
        };
        this.chartData.datasets[0].backgroundColor = '#9ad0f5';
      });
  }

  private requestTimelineData(filterData: AnalyticsFilterData) {
    this.api.getReport(this.reportPath, this.getChartRequest(filterData))
      .subscribe(data => {
        this.dateRangeLabel = DateUtils.getDateRangeLabel(data.responseData?.fromDate, data.responseData?.toDate);

        const numbersData = data.facets.get('search_string:bl:');
        this.totalFound = numbersData.numFound;

        const setDatasetColors = (dataset: TimeLineData, index: number) => {
          dataset.color = DEFAULT_BACKGROUND_COLORS[index % DEFAULT_BACKGROUND_COLORS.length]
        };

        this.datasets = numbersData.timeLine.datasets;
        this.datasets.forEach((dataset, i) => setDatasetColors(dataset, i));
        this.selectedDatasets = this.datasets.filter(d => d.filledData);

        this.chartData = {
          labels: numbersData.timeLine.labels,
          datasets: numbersData.timeLine.datasets.map((dataset, i) => ({
            data: dataset.data,
            label: dataset.label,
            backgroundColor: DEFAULT_BACKGROUND_COLORS[i % DEFAULT_BACKGROUND_COLORS.length],
            hidden: !dataset.filledData,
          })),
        };
      });
  }

  private setChartOptions() {
    switch (this.chartType) {
      case 'bar':
        this.chartOptions = this.barChartOptions;
        break;
      case 'line':
        this.chartOptions = this.lineChartOptions;
        break;
    }
  }

  //#region Event Handler

  onFilterChange(filter: AnalyticsFilterData) {
    this.filterSnapshot = { ...filter };
    this.requestData(filter);
  }

  onChartTypeChanged(chartType: ChartType) {
    this.chartType = chartType;
    this.requestData(this.filterSnapshot);
    this.setChartOptions();
    if (this.chartType === 'bar') {
      this.chartData.datasets[0].backgroundColor = '#9ad0f5';
    }
  }

  onSelectedDatasetsChange(selectedDatasets: TimeLineData[]) {
    this.requestData(this.filterSnapshot);
  }

  onSearch(term: string) {
    this.searchTerm = TextSearchUtils.getWildcardEscapedString(term);
    this.requestData(this.filterSnapshot)
  }

  onExportCsvClick() {
    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getChartRequest());
  }

  //#endregion
}
