<p-toast></p-toast>
<p-confirmDialog />

<div tourAnchor="end-tour" class="invisible-tour-anchor-point"></div>
<div class="h-100 d-flex flex-column">
  <nav class="navbar navbar-expand-md navbar-light flex-shrink-0 justify-content-between p-0" id="main-navbar">
    <header id="main-header" class="d-flex" [ngClass]="{ 'bg-body-secondary': isLogged }">
      <div class="bg-body header-logo" [ngClass]=" { 'border clickable': isLogged }" (click)="goToHome();">
        <img src="../../../../assets/images/TecDoc-Demand-Dashboard-Logo 1.svg" alt="Logo">
      </div>

      <div class="d-flex flex-grow-1 px-3" *ngIf="isLogged && !isDetailsPage">
        <analytics-filter class="w-100"></analytics-filter>
      </div>

      <!-- <div class="header-buttons flex-grow-1 d-flex flex-row-reverse">
        <a [pTooltip]="'HEADER.LOGOUT' | translate" tooltipPosition="bottom" class="header-button btn-logout" routerLink="/portal/logout" *ngIf="isLogged">
          <i class="fa fa-sign-out"></i>
        </a>
        <a class="header-button" routerLink="/portal/login" routerLinkActive="active" *ngIf="!isLogged">
          <i class="fa fa-lock"></i>
        </a>

        <a [pTooltip]="'HEADER.FEEDBACK' | translate" tooltipPosition="bottom" class="header-button btn-comment" *ngIf="isLogged" (click)="onFeedbackButtonClick($event)">
          <i class="fa fa-comment"></i>
        </a>

        <a [pTooltip]="userEmail" tooltipPosition="bottom" class="header-button " *ngIf="isLogged" (click)="onChangePasswordButtonClick($event)">
          <i class="fa fa-user"></i>
        </a>

        <a tourAnchor="start-tour" [pTooltip]="'HEADER.START_PRODUCT_TOUR' | translate" tooltipPosition="bottom" class="header-button btn-tour" *ngIf="showGuidedTourButton" (click)="onTourStart()">
          <i class="fa ta-icon-graduation-cap"></i>
        </a>

        <language-selector class="align-self-center me-2"></language-selector>

        <button *ngIf="isLogged"
          class="navbar-toggler pull-right"
          type="button"
          (click)="onSwitchMenu()"
          aria-controls="sidenav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <button class="btn btn-sm btn-secondary my-3 me-2 border-0 get-full-version" *ngIf="showUpgradeButton"
          (click)="onUpgradeNowButtonClick($event)">
          {{ 'HEADER.UPGRADE' | translate }}
        </button>
      </div> -->
    </header>
  </nav>

  <div class="d-flex flex-column h-100 mh-100 overflow-auto">
    <router-outlet />
  </div>

  <portal-footer></portal-footer>

  <tour-step-template>
    <ng-template let-step="step">
      <div [style.width]="step.stepDimensions?.width"
        [style.min-width]="step.stepDimensions?.minWidth"
        [style.max-width]="step.stepDimensions?.maxWidth"
        class="main-container">

        <div class="title-container p-3">
          <h5 [innerHTML]="step?.title"></h5>
        </div>

        <p class="card-text" [innerHTML]="step?.content"></p>

        <div class="tour-buttons m-3">
          <button *ngIf="dashboardTour.tourService.hasNext(step)"
            class="btn btn-sm skip-button close me-1"
            (click)="dashboardTour.tourService.toggle()">
            {{ 'TOUR.SKIP_TOUR' | translate }}
          </button>
          <button *ngIf="dashboardTour.tourService.hasPrev(step)"
            class="btn btn-sm back-button prev me-1"
            (click)="dashboardTour.tourService.prev()">
            {{ step?.prevBtnTitle }}
          </button>
          <button *ngIf="dashboardTour.tourService.hasNext(step)"
            class="btn btn-sm next me-1 next-button"
            (click)="dashboardTour.tourService.next()">
            {{ step?.nextBtnTitle }}
          </button>
          <button *ngIf="!dashboardTour.tourService.hasNext(step)" class="btn btn-sm next-button me-1"
            (click)="dashboardTour.tourService.end()">
            {{ step?.endBtnTitle }}
          </button>
        </div>
      </div>
    </ng-template>
  </tour-step-template>
</div>
