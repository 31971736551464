import moment from "moment";

export class DateUtils {
  static getDateRangeLabel(from: string, to: string, dateFormat = 'YYYY-MM-DD HH:mm:ss'): string {
    const fromDate = moment(from, dateFormat);
    const toDate = moment(to, dateFormat);
    const dateRangeFormat = 'MMM DD, YYYY';

    return `${fromDate.format(dateRangeFormat)} - ${toDate.format(dateRangeFormat)}`;
  }
}
