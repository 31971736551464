import {Features} from '@app/shared/models/features';
import {DatasourceIds} from '@app/shared/models/datasource-ids';

export class DatasourceFeaturesMap {

  private static map: Map<string, Features[]> = null;

  private static featuresForDatasourcePage = [
      Features.MARKET_OVERVIEW,
      Features.MOST_SEARCHED_NUMBERS,
      Features.MOST_SELECTED_ARTICLES,
      Features.SALES
    ];

  private static getMap() {
    if( DatasourceFeaturesMap.map ) {
      return DatasourceFeaturesMap.map;
    }

    const m = new Map<string, Features[]>();

    m.set(DatasourceIds.WEBCAT_30, [
      Features.MARKET_OVERVIEW,
      Features.MOST_SELECTED_ARTICLES,
      Features.MOST_SEARCHED_NUMBERS,
      Features.VEHICLE_SEARCHES,
      Features.BASIC_USER_ACTIVITY,
      Features.FULL_EXPORT,
      Features.ARTICLE_VISIBILITY,
      Features.SALES
    ]);

    m.set(DatasourceIds.WEBCAT_25, [
      Features.MARKET_OVERVIEW,
      Features.MOST_SELECTED_ARTICLES,
      Features.MOST_SEARCHED_NUMBERS,
      Features.VEHICLE_SEARCHES,
      Features.BASIC_USER_ACTIVITY
    ]);

    m.set(DatasourceIds.WECHAT, [
      Features.MARKET_OVERVIEW,
      Features.MOST_SELECTED_ARTICLES,
      Features.MOST_SEARCHED_NUMBERS
    ]);

    m.set(DatasourceIds.CNCATALOG, [
      Features.MARKET_OVERVIEW,
      Features.MOST_SELECTED_ARTICLES,
      Features.MOST_SEARCHED_NUMBERS
    ]);

    m.set(DatasourceIds.QUANTUM, [
       Features.MARKET_OVERVIEW,
       Features.MOST_SELECTED_ARTICLES,
       Features.MOST_SEARCHED_NUMBERS
    ]);

    m.set(DatasourceIds.RMI, [
      Features.RMI_DATA
    ]);

    m.set(DatasourceIds.WEBSERVICE, [
      Features.MOST_SEARCHED_NUMBERS
    ]);

    DatasourceFeaturesMap.map = m;

    return m;
  }

  public static getFeaturesForDatasourcePage( datasourceId: string ) {
    const features = DatasourceFeaturesMap.getFeatures( [ datasourceId ]);

    const r = [];
    for( const f of features ) {
      if( DatasourceFeaturesMap.featuresForDatasourcePage.indexOf(f) != -1 ) {
        r.push(f);
      }
    }
    return r;
  }

  public static getFeatures( datasource_ids: string[] ): Features[] {
    const r: Features[] = [];
    const pIds = [];

    const map = DatasourceFeaturesMap.getMap();

    const dsMap = {};

    for( const ds of datasource_ids ) {
      const parentDsId = DatasourceIds.getParentId(ds);
      if( pIds.indexOf( parentDsId ) != -1 ) {
        continue; // skip if already checked this parent id
      }
      pIds.push(parentDsId);

      if( map.has(parentDsId) ) {
        if(datasource_ids.length == 1) {
          return map.get(parentDsId);
        }

        dsMap[parentDsId] = map.get(parentDsId);
      }
    }

    const featureReport = {};

    let max = 0;
    // create report of all features and take the highest count
    for( var parentDsId in dsMap ) {
      const features = dsMap[parentDsId];

      for( const f of features ) {
        if( !featureReport[f.id] ) {
          featureReport[f.id] = { feature: f, count: 1 };
        } else {
          featureReport[f.id].count++;
        }

        if(featureReport[f.id].count > max) {
          max = featureReport[f.id].count;
        }
      }
    }

    console.log('featureReport: ', featureReport);

    for( const fId in featureReport ) {
      if( featureReport[fId].count == max ) {
        r.push(featureReport[fId].feature);
      }
    }

    return r;
  }

}
