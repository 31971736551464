<div class="d-flex flex-column h-100">
  <div class="flex-grow-1">
    <p-chart *ngFor="let dataset of datasets"
      type="line"
      [data]="dataset"
      [options]="chartOptions"
      [height]="'300px'">
    </p-chart>
  </div>
</div>
