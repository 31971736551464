import { AppContext } from '@app/core';
import { environment } from '@env/environment';

export class WebShopContext extends AppContext {
 
  /**
   * General provider Id
   */
  providerId: number;

  /**
   * Api Key for Tecdoc Catalog calls
   */
  apiKey?: string;

  /**
   * Data suppliers for OE data
   */
  OEDataSupplierIds: number[];

  /**
   * Mandator for CIS login.
   */
  mandator: string;

  /**
   * Mandators availables. The first one is the default mandator.
   */
  mandators: string[];

  /**
   * Web Shop context factory.
   *
   * @param {any} data
   */
  static factory(): Promise<WebShopContext> {
    return new Promise<WebShopContext>((resolve, reject) => {
      const context = <WebShopContext>environment.context;
      context.language = environment.translate.default;
      resolve( context );
    });
  }
}
