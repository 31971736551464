<p-dialog [(visible)]="visible"
  [header]="'FILTERS_OVERVIEW_DIALOG.HEADER' | translate"
  [modal]="true"
  [contentStyle]="{'min-width': '900px', 'width': '1200px'}">
  <div class="d-flex mb-3">
    <button class="btn btn-primary me-2" (click)="onSelectUnusedFiltersClick()">
      {{ 'FILTERS_OVERVIEW_DIALOG.SELECT_UNUSED' | translate }}
    </button>
    <button class="btn btn-primary me-2" [disabled]="!selectedFilters?.length" (click)="onDeleteSelectedFiltersClick()">
      {{ 'FILTERS_OVERVIEW_DIALOG.DELETE_SELECTED' | translate }}
    </button>
  </div>
  <p-table [value]="filters"
    [(selection)]="selectedFilters"
    styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th><p-tableHeaderCheckbox /></th>
        <th>{{ 'FILTERS_OVERVIEW_DIALOG.BRAND_FILTER' | translate }}</th>
        <th>{{ 'FILTERS_OVERVIEW_DIALOG.BRANDS' | translate }}</th>
        <th>{{ 'FILTERS_OVERVIEW_DIALOG.USERS' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-filter>
      <tr>
        <td><p-tableCheckbox [value]="filter" /></td>
        <td>{{ filter.label }}</td>
        <td>
          <p class="overflow-auto m-0" [innerHTML]="getBrandsLabelsFromIds(filter.brandIds)" [style.maxHeight.px]="100">
          </p>
        </td>
        <td>
          <p class="overflow-auto m-0" [style.maxHeight.px]="100">
            <span *ngFor="let user of filter.users">
              <i class="pi pi-user me-1"></i>
              {{ user }}
              <br>
            </span>
          </p>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
