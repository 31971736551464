import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TreeModule } from 'primeng/tree'
import { ChartModule } from 'primeng/chart';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { AccordionModule } from 'primeng/accordion';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';


import { SharedModule } from '@app/shared/shared.module';

import { AnalyticsRoutingModule } from './analytics-routing.module';

import * as pages from './pages';
import * as components from './components';
import * as shells from './shells';
import { MenuService } from './services/menu';


/**
 * Module with components and functionalities related to Analytics.
 * This module should be loaded after the user is authenticated
 * (check guards usage on analytics-routing.module.ts).
 */
@NgModule({
  declarations: [
    pages.HomePage,
    pages.ArticleVisibilityArticleNumbersPage,
    pages.ArticleVisibilityBrandsPage,
    pages.ArticleVisibilityGenartsPage,
    pages.ArticleViewsArticleNumbersPage,
    pages.ArticleViewsBrandsPage,
    pages.ArticleViewsOeNumbersPage,
    pages.ArticleViewsVehiclesPage,
    pages.ArticleViewsVehiclesDetailsPage,
    pages.ArticleVisibilityArticleNumbersPage,
    pages.ArticleViewsArticleNumbersDetailsPage,
    pages.ArticleViewsGenartsPage,
    pages.ArticleViewsGenartsDetailsPage,
    pages.ArticleViewsCountriesPage,
    pages.ArticleViewsCountriesDetailsPage,
    pages.MostSearchedOeNumbersPage,
    pages.MostSearchedNumbersPage,
    pages.MostSearchedCountriesPage,
    pages.MostSearchedVinNumbersPage,
    pages.MostSearchedNumberPlatesPage,
    pages.MostSearchedVehiclesPage,
    pages.SalesBrandsPage,
    pages.SalesGenartsPage,
    pages.SalesArticleNumbersPage,
    pages.SalesArticleCountriesPage,
    pages.FullExportPage,
    pages.SolrMonitoringPage,
    pages.MarketOverviewCompetitionPage,
    pages.MarketOverviewTrendsPage,
    pages.AdminBrandFiltersPage,
    pages.FiltersOverviewDialogComponent,
    pages.AdminUsersPage,
    pages.UserStatsPage,
    pages.CatalogUsersTrafficCompareComponent,
    pages.UserStatsPremiumPage,
    components.DatasourceComponent,
    components.MultipleDatasourceComponent,
    shells.AnalyticsShell,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AnalyticsRoutingModule,
    SharedModule,
    // Third-party modules
    DragDropModule,
    TreeModule,
    ChartModule,
    TooltipModule,
    ConfirmDialogModule,
    SelectButtonModule,
    DialogModule,
    DropdownModule,
    MenuModule,
    AccordionModule,
    ListboxModule,
    TableModule,
    MultiSelectModule,
    MessageModule,
    ReactiveFormsModule,
    CheckboxModule,
  ],
  providers: [
    MenuService
  ]
})
export class AnalyticsModule { }
