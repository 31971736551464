import { Chart, ChartTypeRegistry, TooltipModel } from "chart.js";

import { FacetItem } from "@app/shared/models/facet-item";

export class CustomTooltipsUtils {

  /**
   * Setup tooltips with vehicle details.
   *
   * @param context the context for the `external` plugin tooltip callback
   * @param originalData the data from which the vehicle details are gathered.
   * @param showVehicleDetails if `true` the vehicle details will be included in the tooltip;
   * otherwise, only the label and value are shown in the tooltip (as the default tooltip behavior).
   */
  static setupVehicleDetailsTooltip(
    context: {
      chart: Chart,
      tooltip: TooltipModel<keyof ChartTypeRegistry>,
    },
    originalData: FacetItem[],
    showVehicleDetails: boolean = true,
  ) {
    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    const bodyLines = [];
    if (showVehicleDetails) {
      // Only show vehicle details on tooltip if the granularity is set to 'Vehicles"
      const dataIndex = tooltip.dataPoints[0].dataIndex;
      const data = originalData[dataIndex];

      tooltip.title = [`${data.details?.manufacturer_name}, ${data.details?.model_name}`];

      const types = data.details?.types_name;
      const motorCodes = data.details?.motor_codes;
      const tecDocId = data.id;

      bodyLines.push('<i class="fa ta-icon-car-front"></i>' + types);
      bodyLines.push('<i class="fa ta-icon-engine"></i>' + motorCodes);
      bodyLines.push('<i class="fa ta-icon-tecdoc-type-number"></i>' + tecDocId);
    }

    const colors = tooltip.labelColors[0];
    const span = `
      <span style="` +
      `background: ${colors.backgroundColor.toString()};` +
      `border-color: ${colors.borderColor.toString()};` +
      `border-width: 2px;` +
      `margin-right: 2px;` +
      `height: 10px;` +
      `width: 10px;` +
      `display: inline-block;` +
    `"></span>`;
    tooltip.body[0].lines[0] = `${span} ${tooltip.body[0].lines[0]}`;
    tooltip.body[0].lines = [...bodyLines, ...tooltip.body[0].lines];

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body[0].lines;

      const tableHead = document.createElement('thead');

      titleLines.forEach(title => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = '0';
        tr.style.fontSize = '14px';

        const th = document.createElement('th');
        th.style.borderWidth = '0';
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach(body => {
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = '0';
        tr.style.fontSize = '12px';

        const td = document.createElement('td');
        td.style.borderWidth = '0';

        const text = document.createElement('div');
        text.innerHTML = body;

        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  }
}
