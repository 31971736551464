import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { Chart, ChartOptions } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

import { TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { ChartData as AnalyticsChartData } from '@app/shared/models/analytics/chart-data';
import { Fields } from '@app/shared/models/fields';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { OptionalChartPanelData } from '@app/shared/components/chart-panel/chart-panel.component';
import { DEFAULT_BACKGROUND_COLORS, DOUGHNUT_CHART_DEFAULT_OPTIONS } from '@app/shared/components/chart-panel/chart-default-options';

import { AnalyticsPage } from '../analytics-page';
import { AnalyticsDetailsPageQueryParams } from '../../models/details-page-query-params';
import { AnalyticsNavigationUtils } from '../../utils/navigation';

@Component({
  selector: 'app-article-views-genarts-details',
  templateUrl: './article-views-genarts-details.component.html',
  styleUrl: './article-views-genarts-details.component.scss'
})
export class ArticleViewsGenartsDetailsPage extends AnalyticsPage implements AnalyticsDetailsPageQueryParams, OnChanges {
  //#region AnalyticsPage implementations

  get pageName(): string {
    return 'Generic article details';
  }

  get pageStoreKey(): string {
    return 'GenericArticleDetailsComponent';
  }

  get reportPath(): string {
    return '/report/facets';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.FOUND_BY];
  }

  hasChanges(): boolean {
    return false;
  }

  //#endregion

  //#region AnalyticsDetailsPageQueryParams

  @Input() displayName: string;
  @Input() genartNo?: string;
  @Input() brandNo?: string;
  @Input() timespan?: string;
  @Input() fromDate?: string;
  @Input() toDate?: string;
  @Input() paramsMap: string;
  @Input() fieldName?: string;

  //#endregion

  /**
   * Hierarchical level of the charts.
   */
  private readonly visibleChartsDataLevels: {[key: string]: number} = {
    'found_by_article': 0,
    'found_by_article_search': 1,
    'found_by_vehicle': 1,
    'found_by_number_plate': 2,
  };

  private readonly visibleChartsDataHeaderLabels: {[key: string]: string} = {
    'found_by_article': this.translate.instant('COMMON.FOUND_BY_TOP_LEVEL'),
    'found_by_article_search': this.translate.instant('COMMON.FOUND_BY_ARTICLE_SEARCH'),
    'found_by_vehicle': this.translate.instant('COMMON.FOUND_BY_VEHICLE'),
    'found_by_number_plate': this.translate.instant('COMMON.FOUND_BY_NUMBER_PLATE'),
  };

  get foundByInfoText(): string {
    return `[${this.genartNo}] ${this.displayName}`;
  }

  /**
   * Collection representation of `paramsMap`.
   */
  parsedParams: {[key: string]: string[]};

  private totalFound: number;

  /**
   * Charts data indexed by facet ID.
   */
  private chartsData: {
    [facetId: string]: OptionalChartPanelData<number>
  };

  /**
   * List of charts data which should be rendered. The position in the array
   * determines the order in which charts are shown.
   */
  visibleChartsData: OptionalChartPanelData<number>[] = [];

  chartOptions: ChartOptions = {
    ...DOUGHNUT_CHART_DEFAULT_OPTIONS,
    parsing: {
      key: 'nested.value',
    },
    onHover: (event, elements) => {
      event.native.target['style'].cursor = elements?.length ? 'pointer' : 'default';
    },
    onClick: (event, elements) => {
      if (!elements?.length) {
        return;
      }

      const datasetIndex = elements[0].datasetIndex;
      const dataIndex = elements[0].index;
      const clickedData = (event['chart'] as Chart).data.datasets[datasetIndex].data[dataIndex];

      // Find the related chart data and hierarchical level
      const chartDataKey = `found_by_${clickedData['id']}`;
      const selectedChartData = this.chartsData?.[chartDataKey];
      const selectedChartDataLevel = this.visibleChartsDataLevels?.[chartDataKey];
      if (!selectedChartData || !selectedChartDataLevel){
        return;
      }

      // Add the selected data to new or existing level
      if (this.visibleChartsData.length <= selectedChartDataLevel) {
        this.visibleChartsData.push(selectedChartData)
      } else {
        this.visibleChartsData[selectedChartDataLevel] = selectedChartData;
        this.visibleChartsData.splice(selectedChartDataLevel + 1); // Remove deeper level charts if exist.
      }
      this.cd.detectChanges();
    },
    plugins: {
      legend: {
        position: 'bottom',
        onClick: (e) => e.native.preventDefault() // Omit default behavior of toggle a value
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const tooltip = tooltipItems?.[0];
            if (!tooltip) {
              return;
            }

            const tooltipFragments = tooltip.label.split(' ');
            const countIndex = tooltipFragments.length - 2;
            tooltipFragments.splice(countIndex, 1);
            tooltip.label = tooltipFragments.join(' ');
          }
        }
      }
    }
  };

  constructor(
    private translate: TranslateService,
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected api: ApiService,
    protected override router: Router,
    private cd: ChangeDetectorRef,
  ) {
    super(tracker, confirmation, api, router);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.parsedParams = AnalyticsNavigationUtils.getDeserializedParamsMap(this.paramsMap);
    this.requestData();
  }

  private getReportChartRequest(facetId?: string): ChartRequest {
    const pageFacets = facetId
      ? [facetId]
      : this.pageFacets;
    const chartRequest = new ChartRequest('article_selection');
    const filterData = this.getFilterDataFromQueryParams();
    chartRequest.setParams(filterData, pageFacets, -1);

    return chartRequest;
  }

  private requestData() {
    this.api.getReport(this.reportPath, this.getReportChartRequest())
      .subscribe(data => {
        const allDetailsData = data.facets.get('found_by');
        this.totalFound = allDetailsData.numFound;

        const originalChartsData: { [facetId: string]: AnalyticsChartData } = {};
        this.chartsData = {};

        data.facets.forEach((value, key) => {
          if (key === 'found_by') {
            return;
          }
          originalChartsData[key] = value;
        });

        Object.entries(originalChartsData).forEach(([key, detailsData]) => {
          this.chartsData[key] = {
            chartData: {
              labels: detailsData.originalData.map(x => `${x.name} ${x.count.toLocaleString('de-DE')} (${((x.count / this.totalFound) * 100).toFixed(2)}%)`),
              datasets: [
                {
                  data: detailsData.originalData.map(x => ({ nested: {value: x.count}, id: x.id })),
                  parsing: {
                    key: 'nested.value'
                  },
                  backgroundColor: DEFAULT_BACKGROUND_COLORS,
                },
              ],
            },
            headerLabel: `${this.translate.instant('COMMON.FOUND_BY_PREFIX')} ${this.visibleChartsDataHeaderLabels[key]}`,
            infoText: this.foundByInfoText,
            facetId: key,
          };
        });

        this.visibleChartsData.push(this.chartsData['found_by_article']);
      });
  }

  /**
   * Returns an `AnalyticsFilterData` object with the fulfilled data,
   * according to query params values.
   *
   * @returns an `AnalyticsFilterData` object with the fulfilled data,
   * according to query params values.
   */
  private getFilterDataFromQueryParams(): AnalyticsFilterData {
    const isTimespanRange = !this.timespan?.length;

    return {
      brands: this.brandNo?.split(',').map(x => +x),
      genarts: this.genartNo?.split(',').map(x => ({id: +x, label: undefined})),
      timespan: {
        isRange: isTimespanRange,
        dates: isTimespanRange
          ? [this.fromDate, this.toDate]
          : this.timespan,
      },
      locations: this.parsedParams?.['location_country'],
      datasources: this.parsedParams?.['datasource'],
    };
  }

  onReportExportCsvClick(facetId: string) {
    this.api.doExport(this.exportPath, new DownloadHandler(false), this.getReportChartRequest(facetId));
  }
}
