<p-table #dt
        [value]="userTableData"
        dataKey="id"
        autoLayout="false"
        [tableStyle]="{'min-width': '50rem'}"
        [rows]="15"
        [paginator]="true"
        styleClass="p-datatable-striped"
        (onFilter)="onFilter($event)"
        [globalFilterFields]="['id', 'status', 'userName', 'email', 'settings.userRole']"
        selectionMode="single"
        [(selection)]="selectedUser"
        (onRowSelect)="handleSelectEdit($event)"
        (onRowUnselect)="handleUnselectEdit($event)">
  <ng-template pTemplate="caption">
    <div class="table-caption">
      <div class="left-buttons">
        <p-button icon="pi pi-user-plus"
                  [style]="{'height': '39px', 'width': 'auto%','margin-right':'4px'}"
                  [label]="'ADMIN_USERS.ADD_USER' | translate"
                  (click)="createNewUser()"
                  [loading]="!newUserform" />
        <p-button icon="pi pi-external-link"
                  [style]="{'margin-right':'4px'}"
                  [label]="'ADMIN_USERS.EXPORT' | translate"
                  (click)="exportUsers()"
                  [loading]="!datasources" />
      </div>
      <div class="right-input">
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <input pInputText
                  class="p-input-text"
                  type="text"
                  (input)="applyFilterGlobal($event, 'contains')"
                  [placeholder]="'ADMIN_USERS.SEARCH_PLACEHOLDER' | translate"
                  [style]="{'height':'40px'}" />
        </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width:10%">
        <div style="display: flex; align-items: center;">
          {{ 'ADMIN_USERS.HEADER_STATUS' | translate }}
          <p-columnFilter field="status"
                          matchMode="equals"
                          showOperator="false"
                          showAddButton="false"
                          showMatchModes="false"
                          showClearButton="false"
                          showApplyButton="false"
                          display="menu">
            <ng-template let-value
                         let-filter="filterCallback"
                         pTemplate="filter">
              <p-dropdown [ngModel]="value"
                          [options]="statusFilterOptions"
                          (onChange)="filter($event.value)"
                          placeholder="all">
                <ng-template let-option
                             pTemplate="item">
                  <span>{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th style="width:20%" pSortableColumn="userName">
        <div style="display: flex; align-items: center;">
          <p-sortIcon field="userName" style="margin-right: 10px;" />
          {{ 'ADMIN_USERS.HEADER_USERNAME' | translate }}
          <p-columnFilter type="text"
                          field="userName"
                          matchMode="contains"
                          showMatchModes="false"
                          showOperator="false"
                          showAddButton="false"
                          [placeholder]="'ADMIN_USERS.SEARCH_USERS' | translate"
                          display="menu" />
        </div>
      </th>
      <th style="width:20%" pSortableColumn="email">
        <div style="display: flex; align-items: center;">
          <p-sortIcon field="email" style="margin-right: 10px;" />
          {{ 'ADMIN_USERS.HEADER_MAIL' | translate }}
          <p-columnFilter type="text"
                          field="email"
                          matchMode="contains"
                          showMatchModes="false"
                          showOperator="false"
                          showAddButton="false"
                          placeholder="Search email"
                          display="menu" />
        </div>
      </th>
      <th style="width:15%">
        <div style="display: flex; align-items: center;">
          {{ 'ADMIN_USERS.HEADER_ROLE' | translate }}
          <p-columnFilter field="settings.userRole"
                          matchMode="equals"
                          showOperator="false"
                          showAddButton="false"
                          showMatchModes="false"
                          display="menu"
                          showClearButton="false"
                          showApplyButton="false">
            <ng-template let-value
                         let-filter="filterCallback"
                         pTemplate="filter">
              <p-dropdown [ngModel]="value"
                          [options]="userRoles"
                          (onChange)="filter($event.value)"
                          placeholder="all">
                <ng-template let-option
                             pTemplate="item">
                  <span>{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th style="width:15%">
        <div style="display: flex; align-items: center;">
          {{ 'ADMIN_USERS.HEADER_BRAND_FILTER' | translate }}
          <p-columnFilter field="settings.brandFilterId"
                          matchMode="equals"
                          showOperator="false"
                          showAddButton="false"
                          showMatchModes="false"
                          display="menu"
                          showClearButton="false"
                          showApplyButton="false">
            <ng-template let-value
                         let-filter="filterCallback"
                         pTemplate="filter">
              <p-dropdown [ngModel]="value"
                          [options]="brandFiltersFilter"
                          (onChange)="filter($event.value)"
                          placeholder="all"
                          role="option">
                <ng-template let-option pTemplate="item">
                  <span>{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th style="width:15%" pSortableColumn="lastLogin">
        <div style="display: flex; align-items: center;">
          <p-sortIcon field="lastLogin" style="margin-right: 10px;" />
          {{ 'ADMIN_USERS.HEADER_LAST_SEEN' | translate }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr [pSelectableRow]="user">
      <td>
        <i [className]="getUserStatusIcon(user)" [title]="getStatusTitle(user)">&nbsp;</i>
      </td>
      <td title="{{ user.id }}">{{ user.userName }}</td>
      <td title="{{ user.id }}">{{ user.email }}</td>
      <td title="{{ user.id }}">{{ user.settings.userRole }}</td>
      <td>
        <span *ngIf="brandNamesMap" title="{{ getBrandNamesByFilterId(user.settings.brandFilterId) }}">
          {{ getBrandFilterName(user.settings.brandFilterId) }}
        </span>
      </td>
      <td>
        <span title="{{ getLastLoginTitle(user) }}">
          <i [title]="user.deleted ? 'restore' : 'delete'" class="fa"
             role="button"
             [hidden]="shouldHiddeDeleteButton(user)"
             [ngClass]="{ 'fa-trash-o': !user.deleted, 'fa-undo': user.deleted }"
             (click)="deleteUser(user, $event)">&nbsp;</i>&nbsp;{{ getLastLoginDate(user) }}
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog [header]="'ADMIN_USERS.NEW_TITLE' | translate" [(visible)]="showNewUserDialog" [modal]=true [style]="dialogStyle">
  <form [formGroup]="newUserform" (ngSubmit)="onSubmit(newUserform)" class="fillhoriz">
    <div class="container-fluid fillhoriz"  [style]="{'margin-bottom':'10px'}">
      <div class="col-sm-12 mb-2 text-center">
        <p-message severity="error" *ngIf="errorMessage!=null&&errorMessage!=''" [text]="errorMessage"></p-message>
      </div>
      <loading [busy]="isRequesting" [showCloseButton]="false"></loading>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.NEW_USER_ROLE' | translate }}:
        </div>
        <div class="col-sm-8">
          <p-dropdown [options]="userRolesSelect"
                      formControlName="userRole"
                      styleClass="role-selector"
                      [placeholder]="_pleaseSelect.label"
                      [style]="{'width':'50%', 'height':'40px'}">
          </p-dropdown>
        </div>
      </div>
      <div class="row pb-2" *ngIf="!newUserform.controls['userRole'].valid && newUserform.controls['userRole'].dirty">
        <div class="col-sm-4"></div>
        <div class="col-sm-8">
          <p-message severity="error" text="ADMIN_USERS.USER_ROLE_ERROR"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.NEW_USERNAME' | translate }}:
        </div>
        <div class="col-sm-8">
          <input pInputText
                 type="text"
                 formControlName="userName"
                 [placeholder]="'ADMIN_USERS.REQUIRED' | translate"
                 [style]="{'width':'50%', 'height':'40px'}">
        </div>
      </div>
      <div class="row pb-2" *ngIf="!newUserform.controls['userName'].valid&&newUserform.controls['userName'].dirty">
        <div class="col-sm-4"></div>
        <div class="col-sm-8">
          <p-message severity="error" text="'ADMIN_USER.USERNAME_ERROR' | translate"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.NEW_EMAIL' | translate }}:
        </div>
        <div class="col-sm-8">
          <input pInputText
                 type="text"
                 formControlName="email"
                 [placeholder]="'ADMIN_USERS.REQUIRED' | translate"
                 [style]="{'width':'50%', 'height':'40px', 'left': '5px'}">
        </div>
      </div>
      <div class="row pb-2" *ngIf="!newUserform.controls['email'].valid && newUserform.controls['email'].dirty">
        <div class="col-sm-4"></div>
        <div class="col-sm-8">
          <p-message severity="error" [text]="'ADMIN_USERS.EMAIL_ERROR'"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.NEW_ACTIVATED' | translate }}:
        </div>
        <div class="col-sm-8">
          <p-checkbox name="activeCheck" formControlName="isActive" binary="true"></p-checkbox>
        </div>
      </div>
      <div class="row pb-2"  [style]="{'margin-bottom':'10px'}">
        <div class="col-sm-4">
          <loading [busy]="isRefreshingFilter" [showCloseButton]="false"></loading>
          <div class="list-header-row">
            <a class="list-label" target="_blank" style="text-decoration: none;" href="/manage/brand-filters">{{ 'ADMIN_USERS.NEW_BRAND_FILTER' | translate }}:*</a>
            <button pButton
                    tooltipPosition="top"
                    class="btn-reload-brand-filter btn btn-secondary"
                    [pTooltip]="'ADMIN_USERS.RELOAD_BRAND_FILTER' | translate"
                    (click)="loadBrandFilters($event, brandFilterNew)">
              <i class="fa ta-icon-cw"></i>
            </button>
          </div>
          <p-listbox #brandFilterNew
                     [options]="brandFilters"
                     formControlName="brandFilterId"
                     filter="true"
                     styleClass="brand-filter-selector"
                     optionLabel="label"
                     optionValue="value">
            <ng-template let-flt let-i="index" pTemplate="item">
              <div class="ui-helper-clearfix">
                <span [ngClass]="{ 'is-new-filter' : flt.isNew }">{{flt.label}}</span>
              </div>
            </ng-template>
          </p-listbox>
          <p-message severity="error" [text]="'ADMIN_USERS.BRAND_FILTER_ERROR' | translate"
                     *ngIf="!newUserform.controls['brandFilterId'].valid && newUserform.controls['brandFilterId'].dirty"></p-message>
        </div>
        <div class="col-sm-4">
          <div class="list-header-row">
            <span class="list-label">
              {{ 'ADMIN_USERS.NEW_DATASOURCES' | translate }}:
            </span>
          </div>
          <p-listbox [filter]="true" [multiple]="true" [checkbox]="true"
                     [options]="datasources"
                     formControlName="datasource"
                     styleClass="datasource-selector"
                     optionLabel="label"
                     optionValue="value"></p-listbox>
        </div>
        <div class="col-sm-4">
          <div class="list-header-row">
            <span class="list-label">
              {{ 'ADMIN_USERS.NEW_FEATURES' | translate }}:
            </span>
          </div>
          <p-listbox [filter]="true" [multiple]="true" [checkbox]="true"
                     [options]="features"
                     formControlName="features"
                     styleClass="feature-selector"
                     optionLabel="label"
                     optionValue="value">
          </p-listbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 text-end" [style]="{'margin-top':'2px'}">
          <p-button [label]="'ADMIN_USERS.CANCEL' | translate"
                    class="default-button"
                    (onClick)="showNewUserDialog = false"
                    iconPos="left"
                    [style]="{'margin-right':'8px'}"></p-button>
          <p-button class="default-button"
                    [label]="'ADMIN_USERS.ADD_USER' | translate"
                    type="submit"
                    [disabled]="!newUserform.valid"></p-button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog [header]="'ADMIN_USERS.EDIT_TITLE' | translate" [(visible)]="showEditUserDialog" [modal]=true [style]="dialogStyle" (onHide)="onEditDialogHide()">
  <form [formGroup]="editUserform" (ngSubmit)="onSubmit(editUserform)" class="fillhoriz">
    <div class="container-fluid fillhoriz">
      <loading [busy]="isRequesting" [showCloseButton]="false"></loading>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.EDIT_USER_ROLE' | translate }}:
        </div>
        <div class="col-sm-8">
          <p-dropdown [options]="userRolesSelect"
                      formControlName="userRole"
                      styleClass="role-selector"
                      [style]="{'width':'50%'}"
                      ng-reflect-position-style="relative"></p-dropdown>
        </div>
      </div>
      <div class="row pb-2" *ngIf="!editUserform.controls['userRole'].valid && editUserform.controls['userRole'].dirty">
        <div class="col-sm-4"></div>
        <div class="col-sm-8">
          <p-message severity="error" [text]="'ADMIN_USERS.USER_ROLE_ERROR' | translate"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.EDIT_USER_ID' | translate }}:
        </div>
        <div class="col-sm-8">
          {{selectedUser.id}}
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.EDIT_USERNAME' | translate }}:
        </div>
        <div class="col-sm-8">
          {{selectedUser.userName}}
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.EDIT_EMAIL' | translate }}:
        </div>
        <div class="col-sm-8">
          <input pInputText
                 type="text"
                 formControlName="email"
                 [style]="{'width':'50%'}">
        </div>
      </div>
      <div class="row pb-2" *ngIf="!editUserform.controls['email'].valid && editUserform.controls['email'].dirty">
        <div class="col-sm-4"></div>
        <div class="col-sm-8">
          <p-message severity="error" [text]="'ADMIN_USERS.EMAIL_ERROR' | translate"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-end">
          {{ 'ADMIN_USERS.EDIT_ACTIVATED' | translate }}:
        </div>
        <div class="col-sm-8">
          <p-checkbox formControlName="isActive"
                      binary="true"></p-checkbox>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4">
          <loading [busy]="isRefreshingFilter" [showCloseButton]="false"></loading>
          <div class="list-header-row">
            <a class="list-label" target="_blank" style="text-decoration: none;" href="/manage/brand-filters">{{ 'ADMIN_USERS.EDIT_BRAND_FILTER' | translate }}:*</a>
            <button pButton
                    class="btn-reload-brand-filter btn btn-secondary default-button"
                    tooltipPosition="top"
                    [pTooltip]="'ADMIN_USERS.RELOAD_BRAND_FILTER' | translate"
                    (click)="loadBrandFilters($event, brandFilterEdit)">
              <i class="fa ta-icon-cw"></i>
            </button>
          </div>
          <p-listbox #brandFilterEdit
                     filter="filter" checkbox="checkbox"
                     [options]="brandFilters"
                     formControlName="brandFilterId"
                     styleClass="brand-filter-selector"
                     optionLabel="label"
                     optionValue="value">
            <ng-template let-flt let-i="index" pTemplate="item">
              <div class="ui-helper-clearfix">
                <span [ngClass]="{ 'is-new-filter' : flt.isNew }">{{flt.label}}</span>
              </div>
            </ng-template>
          </p-listbox>
          <p-message severity="error" [text]="'ADMIN_USERS.BRAND_FILTER_ERROR' | translate"
                     *ngIf="!editUserform.controls['brandFilterId'].valid && editUserform.controls['brandFilterId'].dirty"></p-message>
        </div>
        <div class="col-sm-4">
          <div class="list-header-row">
            <span class="list-label">
              {{ 'ADMIN_USERS.EDIT_DATASOURCES' | translate }}:
            </span>
          </div>
          <p-listbox filter="filter" multiple="multiple" checkbox="checkbox"
                     [options]="datasources"
                     formControlName="datasource"
                     styleClass="datasource-selector"
                     optionLabel="label"
                     optionValue="value"></p-listbox>
        </div>
        <div class="col-sm-4">
          <div class="list-header-row">
            <span class="list-label">
              {{ 'ADMIN_USERS.EDIT_FEATURES' | translate }}:
            </span>
          </div>
          <p-listbox filter="filter" multiple="multiple" checkbox="checkbox"
                     [options]="features"
                     formControlName="features"
                     styleClass="feature-selector"
                     optionLabel="label"
                     optionValue="value"></p-listbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 text-end" [style]="{'margin-top':'8px'}">
          <p-button class="default-button"
                    [label]="'ADMIN_USERS.CANCEL' | translate"
                    type="button"
                    (onClick)="showEditUserDialog = false"
                    [style]="{'margin-right':'8px'}"></p-button>
          <p-button class="default-button"
                    type="submit"
                    [label]="'ADMIN_USERS.SAVE_CHANGES' | translate"
                    [disabled]="!editUserform.valid"></p-button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
