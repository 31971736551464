<div class="d-flex flex-column h-100">
  <div class="flex-grow-1">
    <chart-panel [panelStyleClass]="'h-100'"
      [chartData]="chartData"
      [chartOptions]="chartOptions"
      [addTourAnchors]="true"
      [headerLabel]="'ARTICLE_VIEWS_GENART.HEADER_LABEL' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'ARTICLE_VIEWS_GENART.INFO_TEXT' | translate"
      [showTopDropdown]="true"
      [enableChartTypeToggle]="false"
      [showTimelineButton]="true"
      [timelineDatasets]="datasets"
      [(selectedTimelineDatasets)]="selectedDatasets"
      (selectedTimelineDatasetsChange)="onSelectedDatasetsChange($event)"
      [timelineDatasetLimit]="10"
      [showAggregationOptions]="isTimelineChart"
      [showAggregationWeekdaysFilter]="true"
      [selectedTopOptions]="selectedTopOptions"
      [totalFound]="totalFound"
      (chartTypeChange)="onChartTypeChanged($event)"
      (exportCsvClick)="onExportCsvClick()">
    </chart-panel>
  </div>
</div>
