import { registerLocaleData } from '@angular/common';

import bg from '@angular/common/locales/bg';
import cs from '@angular/common/locales/cs';
import da from '@angular/common/locales/da';
import de from '@angular/common/locales/de';
import el from '@angular/common/locales/el';
import en from '@angular/common/locales/en-GB';
import es from '@angular/common/locales/es';
import et from '@angular/common/locales/et';
import fi from '@angular/common/locales/fi';
import fr from '@angular/common/locales/fr';
import he from '@angular/common/locales/he';
import hr from '@angular/common/locales/hr';
import hu from '@angular/common/locales/hu';
import it from '@angular/common/locales/it';
import lt from '@angular/common/locales/lt';
import lv from '@angular/common/locales/lv';
import nl from '@angular/common/locales/nl';
import nn from '@angular/common/locales/nn';
import pl from '@angular/common/locales/pl';
import pt from '@angular/common/locales/pt';
import enUS from '@angular/common/locales/en';
import enAU from '@angular/common/locales/en-AU';
// import ptBR from '@angular/common/locales/pt'; // There is no pt-BR in Angular
import ro from '@angular/common/locales/ro';
import ru from '@angular/common/locales/ru';
import sk from '@angular/common/locales/sk';
import sl from '@angular/common/locales/sl';
import sr from '@angular/common/locales/sr';
import sv from '@angular/common/locales/sv';
import tr from '@angular/common/locales/tr';
import zh from '@angular/common/locales/zh';

/**
 * Registeres all required locales.
 *
 * @export
 */
export const registerLocales = () => {
    registerLocaleData(bg);
    registerLocaleData(cs);
    registerLocaleData(da);
    registerLocaleData(de);
    registerLocaleData(el);
    registerLocaleData(en);
    registerLocaleData(es);
    registerLocaleData(et); // TecDoc only
    registerLocaleData(fi);
    registerLocaleData(fr);
    registerLocaleData(he); // TecDoc only
    registerLocaleData(hr); // RMI only
    registerLocaleData(hu);
    registerLocaleData(it);
    registerLocaleData(lt); // TecDoc only
    registerLocaleData(lv); // TecDoc only
    registerLocaleData(nl);
    registerLocaleData(nn, 'no'); // TODO: use 'nn' (Nynorsk) or 'nb' (Bokmål)?
    registerLocaleData(pl);
    registerLocaleData(pt);
    registerLocaleData(ro);
    registerLocaleData(ru);
    registerLocaleData(sk);
    registerLocaleData(sl);
    registerLocaleData(sr);
    registerLocaleData(sv);
    registerLocaleData(tr);
    registerLocaleData(zh);

    // TecDoc special
    registerLocaleData(enUS, 'qa');
    registerLocaleData(enAU, 'qb');
    registerLocaleData(pt, 'qc'); // TODO: 'pt-BR' does not exist in Angular, use just 'pt'?
};

