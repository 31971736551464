import { Component } from '@angular/core';
import { Fields } from '@app/shared/models/fields';
import { AnalyticsPage } from '../analytics-page';

@Component({
  selector: 'app-sales-genarts',
  templateUrl: './sales-genarts.component.html',
  styleUrl: './sales-genarts.component.scss'
})
export class SalesGenartsPage extends AnalyticsPage {
  get pageName(): string {
    return 'Sales generic articles';
  }

  get pageStoreKey(): string {
    return "SalesGenartsComponent";
  }

  get reportPath(): string {
    return '/report/order/sales';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.GENART_NO];
  }

  hasChanges(): boolean {
    return false;
  }
}
