export class TextSearchUtils {

  /**
   * Returns a given string input with wildcard characters to be sent
   * as a text search query parameter. If the input string already have
   * any wildcard character, it is kept.
   *
   * @param input the input string.
   */
  static getWildcardEscapedString(input: string): string {
    const escapedCharsRegex = /[\*\?\"]/;

    return input?.length
      ? escapedCharsRegex.test(input)
        ? input
        : `*${input}*`
      : '';
  }
}
