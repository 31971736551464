<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center mb-4">
    <button *ngIf="!isEditModeActive" class="btn btn-primary me-2" (click)="onAddClick()">
      {{ 'COMMON.ADD' | translate }}
    </button>
    <button *ngIf="isEditModeActive" class="btn btn-primary me-2" (click)="onSaveClick()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
    <button class="btn btn-primary me-2" (click)="onEditClick()">
      {{ (isEditModeActive ? 'COMMON.CANCEL' : 'COMMON.EDIT') | translate }}
    </button>
    <button class="btn btn-primary me-2" [disabled]="isEditModeActive" (click)="onDeleteClick()">
      {{ 'COMMON.DELETE' | translate }}
    </button>
    <button class="btn btn-primary me-2" [disabled]="isEditModeActive" (click)="onFiltersOverviewClick()">
      {{ 'BRAND_FILTERS.FILTER_OVERVIEW' | translate }}
    </button>
    <button class="btn btn-primary me-2"
      [disabled]="isEditModeActive"
      [pTooltip]="'BRAND_FILTERS.CLEAR_CACHE_BUTTON_TOOLTIP' | translate"
      tooltipPosition="top"
      (click)="onClearCacheClick()">
      {{ 'BRAND_FILTERS.CLEAR_CACHE' | translate }}
    </button>

    <h5 class="ms-2 mb-0" *ngIf="!isEditModeActive">{{ selectedFilter?.name }}</h5>
    <input type="text" *ngIf="isEditModeActive" [ngModel]="selectedFilter?.name" (ngModelChange)="onFilterNameChange($event)">
  </div>

  <div class="d-flex justify-content-between flex-grow-1">
    <div class="flex-grow-1 me-3">
      <span class="fw-bold">{{ 'BRAND_FILTERS.FILTERS' | translate }}</span>
      <p-listbox class="me-4"
        [disabled]="isEditModeActive"
        [options]="filters"
        [(ngModel)]="selectedFilter"
        (ngModelChange)="onSelectedFilterChange($event)"
        [multiple]="false"
        [checkbox]="true"
        [listStyleClass]="'items-no-padding'"
        [style]="{ height: '650px' }"
        [listStyle]="{ height: '650px', maxHeight: '100%' }">
      </p-listbox>
    </div>
    <div class="flex-grow-1 mx-3">
      <span class="fw-bold">{{ 'BRAND_FILTERS.SELECTED_BRANDS' | translate }}</span>
      <p-listbox #selectedBrands class="me-4"
        [options]="selectedFilterBrands"
        [optionDisabled]="'disabled'"
        [optionLabel]="'label'"
        [virtualScroll]="true"
        [virtualScrollItemSize]="40"
        [virtualScrollOptions]="{style: {'height': '594px'}}"
        [multiple]="true"
        [checkbox]="true"
        [filter]="true"
        [filterBy]="'label'"
        [showToggleAll]="false"
        [listStyleClass]="'items-no-padding'"
        [style]="{ height: '650px' }"
        (onChange)="onSelectedBrandsSelectionChange()">
        <ng-template let-brand pTemplate="item">
          <span
            [ngClass]="{'text-success fw-bold': brand.isAdded}">
            {{ brand.label }}
          </span>
        </ng-template>
      </p-listbox>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center mx-3">
      <button class="btn btn-primary brand-picker-control my-1"
        [disabled]="!isEditModeActive"
        [pTooltip]="'BRAND_FILTERS.REMOVE_ALL' | translate"
        tooltipPosition="top"
        (click)="onRemoveAll()">
        >>
      </button>
      <button class="btn btn-primary brand-picker-control my-1"
        [disabled]="!isEditModeActive || !selectedBrandsListboxSelection.length"
        [pTooltip]="'BRAND_FILTERS.REMOVE_SELECTED' | translate"
        tooltipPosition="top"
        (click)="onRemoveSelection()">
        >
      </button>
      <button class="btn btn-primary brand-picker-control my-1"
        [disabled]="!isEditModeActive || !availableBrandsListboxSelection.length"
        [pTooltip]="'BRAND_FILTERS.ADD_SELECTED' | translate"
        tooltipPosition="top"
        (click)="onAddSelection()">
        &lt;
      </button>
      <button class="btn btn-primary brand-picker-control my-1"
        [disabled]="!isEditModeActive"
        [pTooltip]="'BRAND_FILTERS.ADD_ALL' | translate"
        tooltipPosition="top"
        (click)="onAddAll()">
        &lt;&lt;
      </button>
    </div>
    <div class="flex-grow-1 ms-3">
      <span class="fw-bold">{{ 'BRAND_FILTERS.AVAILABLE_BRANDS' | translate }}</span>
      <p-listbox #availableBrands class="me-4"
        [options]="availableBrandsforFilter"
        [optionDisabled]="'disabled'"
        [optionLabel]="'label'"
        [virtualScroll]="true"
        [virtualScrollItemSize]="40"
        [virtualScrollOptions]="{style: {'height': '594px'}}"
        [multiple]="true"
        [checkbox]="true"
        [filter]="true"
        [filterBy]="'label'"
        [showToggleAll]="false"
        [listStyleClass]="'items-no-padding'"
        [style]="{ height: '650px' }"
        (onChange)="onAvailableBrandsSelectionChange()">
        <ng-template let-brand pTemplate="item">
          <span
            [ngClass]="{'text-danger fw-bold': brand.isRemoved}">
            {{ brand.label }}
          </span>
        </ng-template>
      </p-listbox>
    </div>
  </div>
</div>

<filters-overview-dialog #filtersOverviewDialog
  [filters]="filters"
  (filtersDeleted)="onFiltersDeletedFromDialog($event)">
</filters-overview-dialog>
