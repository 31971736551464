export class DatasourceIds {

  public static WEBCAT_25 = '1';
  public static WEBCAT_30 = '2';
  public static WEBSERVICE = '3';
  public static RMI = '4';
  public static WECHAT = '5';
  public static QUANTUM = '5fafa';
  public static CNCATALOG = 'cnc';

  public static getParentId( datasource_id : string ) {
    if( !datasource_id ) {
      return datasource_id;
    }

    return datasource_id.indexOf(':') != -1 ?
              datasource_id.substring(0, datasource_id.indexOf(':')) :
                datasource_id;
  }
}
