<div class="d-flex flex-column h-100">
  <div class="flex-grow-1">
    <chart-panel [panelStyleClass]="'h-100'"
      [chartData]="chartData"
      [chartOptions]="chartOptions"
      [addTourAnchors]="true"
      [headerLabel]="'MOST_SEARCHED_NUMBERS.HEADER' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'MOST_SEARCHED_NUMBERS.INFO' | translate"
      [showSearch]="!isTimelineChart"
      (searchStart)="onSearch($event)"
      [showTopDropdown]="true"
      [showCoverageToggle]="true"
      [showTimelineButton]="true"
      [timelineDatasets]="datasets"
      [(selectedTimelineDatasets)]="selectedDatasets"
      (selectedTimelineDatasetsChange)="onSelectedDatasetsChange($event)"
      [timelineDatasetLimit]="10"
      [showAggregationOptions]="isTimelineChart"
      [showAggregationWeekdaysFilter]="true"
      [selectedTopOptions]="selectedTopOptions"
      [totalFound]="totalFound"
      (chartTypeChange)="onChartTypeChanged($event)"
      (exportCsvClick)="onExportCsvClick()">
    </chart-panel>
  </div>
</div>
