import { inject } from "@angular/core";
import { CanDeactivateFn } from "@angular/router";

import { ConfirmationService } from "primeng/api";

import { AuthService } from "@app/core";
import { PageComponent } from "@app/shared/components/page";
import {
  DISCART_CHANGE_TITLE,
  DISCART_CHABGE_MESSAGE,
  LOGOUT_CONFIRM_TITLE,
  LOGOUT_CONFIRM_MESSAGE
} from '@app/shared/consts/messages';

/**
 * Checks if the page (component) has changes and popups a confirmation dialog to ensure
 * those changes can be discarded before deactivating the component.
 *
 * @param target The page (component)
 * @param currentRoute The current route
 * @param currentState The current state
 * @param nextState The next state to which the navigation will continue
 */
export const confirmDeactivate: CanDeactivateFn<PageComponent> = (target: PageComponent, currentRoute, currentState, nextState) => {
  return new Promise<boolean>((resolve, reject) => {
    if (!inject(AuthService).isLogged || (!target.hasChanges() && nextState.url.indexOf('logout') === -1)) {
      resolve(true);
      return;
    }

    inject(ConfirmationService).confirm({
      key: 'discart-changes',
      header: nextState.url.indexOf('logout') === -1
        ? DISCART_CHANGE_TITLE
        : LOGOUT_CONFIRM_TITLE,
      message: target.hasChanges()
        ? DISCART_CHABGE_MESSAGE
        : LOGOUT_CONFIRM_MESSAGE,
      accept: () => {
        resolve(true);
      },
      reject: () => {
        resolve(false);
      }
    });
  });
}
