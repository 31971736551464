<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-center mb-3">
    <p-selectButton [options]="vehicleGranularities" [(ngModel)]="selectedVehicleGranularity" (ngModelChange)="onVehicleGranularityChange($event)"></p-selectButton>
  </div>
  <div class="flex-grow-1">
    <chart-panel [panelStyleClass]="'h-100'"
      [chartData]="chartData"
      [chartOptions]="chartOptions"
      [addTourAnchors]="true"
      [headerLabel]="chartPanelHeaderLabel"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'MOST_SEARCHED_VEHICLES.INFO' | translate"
      [searchTooltip]="'CHART_PANEL.VEHICLE_SEARCH_TOOLTIP'"
      [showSearch]="showSearch"
      (searchStart)="onSearch($event)"
      [showTopDropdown]="true"
      [enableChartTypeToggle]="false"
      [showTimelineButton]="false"
      [selectedTopOptions]="selectedTopOptions"
      [totalFound]="totalFound"
      (exportCsvClick)="onExportCsvClick()">
    </chart-panel>
  </div>
</div>
