<div class="d-flex flex-column">
  <div class="d-flex justify-content-between mb-3">
    <div class="flex-grow-1">
      <div class="card rounded-0">
        <div class="card-header">
          <i class="fa ta-icon-info-circled me-1"></i>
          {{ 'FULL_EXPORT.VIEWS_DESCRIPTION' | translate}}
        </div>
        <div class="card-body">
          <ul class="mb-0">
            <li *ngFor="let info of formatInfo">
              <span class="fw-bold">{{ info.id }}: </span>
              <span class="fst-italic">{{ info.name | titlecase }}. </span>
              <span>{{ info.description | titlecase }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Separator -->
    <div class="px-2"></div>

    <div class="flex-grow-1">
      <div class="card rounded-0">
        <div class="card-header">
          <i class="fa ta-icon-download-cloud me-1"></i>
          {{ 'FULL_EXPORT.SPECIAL_DOWNLOADS' | translate}}
        </div>
        <div class="card-body">
          <ul class="mb-0">
            <li *ngFor="let specialDownload of specialDownloads">
              <a href
                class="text-decoration-none"
                (click)="onDownloadClick($event, specialDownload.id, specialDownload.id === 'last12Months' ? 'last12Months' : 'download')">
                <span *ngIf="getDownloadStatus(specialDownload.id) === DownloadStatus.NONE">{{ specialDownload.name }}</span>
                <span class="ms-2 spinner-border" *ngIf="getDownloadStatus(specialDownload.id) !== DownloadStatus.NONE"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <p-accordion [activeIndex]="0">
    <p-accordionTab *ngFor="let exportYear of fullExportPerYearAndMonth | keyvalue: keyDescOrder"
      [header]="exportYear.key"
      [headerStyleClass]="'rounded-0 text-decoration-none bg-light border'"
      [contentStyleClass]="'rounded-0 border border-top-0'">
      <div class="row">
        <div class="col-3 row" *ngFor="let exportMonth of exportYear.value | keyvalue">
          <span class="col-4 fw-bold me-3">{{ getMonthName(exportMonth.key) }}</span>
          <a href *ngFor="let exportItem of exportMonth.value"
            class="col-1 text-decoration-none me-2"
            (click)="onDownloadClick($event, exportItem.eTag)">
            <span *ngIf="getDownloadStatus(exportItem.eTag) === DownloadStatus.NONE">{{ exportItem.viewId }}</span>
            <span class="ms-2 spinner-border" *ngIf="getDownloadStatus(exportItem.eTag) !== DownloadStatus.NONE"></span>
          </a>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>
