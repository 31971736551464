<div class="d-flex flex-column h-100">
  <div class="header-panel d-inline-flex align-items-center px-3">
    <h3 class="m-0 fw-light">Analyze Trends, Decide, <span class="fw-bold">Succeed</span></h3>
    <span class="ms-4 subheader"><span class="fw-bold">Data Rules.</span> Take action based on the most <br>up-to date and complete data of the aftermarket demand</span>
  </div>

  <div class="flex-grow-1 m-3 row">
    <div class="col-4 p-2 h-50">
      <div class="border rounded-2 h-100 w-100 main-card p-3">
        <div *ngIf="selectedDatasources?.length">
          <span class="label">
            <i aria-hidden="true" class="filter-icon fa ta-icon-master-data me-1"></i>{{ 'HOME_PAGE.DATA_SOURCE' | translate }}
          </span>
          <h4>{{ selectedDatasourcesNames }}</h4>
          <hr>
          <span class="label">
            <i aria-hidden="true" class="filter-icon fa ta-icon-calendar me-1"></i>{{ 'HOME_PAGE.DATA_TIME_RANGE' | translate }}
          </span>
          <h6>{{ selectedDatasourcesMinDateRange }} » {{ selectedDatasourcesMaxDateRange }}</h6>
          <h4>{{ selectedDatasourcesDateRangeInDays }} {{ 'COMMON.DAYS' | translate }}</h4>
          <br>
          <span class="label">
            <i aria-hidden="true" class="filter-icon fa ta-icon-bell-alt me-1"></i>{{ 'HOME_PAGE.AVG_EVENTS' | translate }}
          </span>
          <h4>{{ selectedDatasourcesAvgEvents }}</h4>
        </div>
      </div>
    </div>
    <div class="col-4 p-2 h-50" *ngIf="topCountriesChartData">
      <div class="h-100 w-100 card rounded-0">
        <div class="card-header d-flex align-items-center py-0 pe-0">
          <span class="flex-grow-1">{{ 'HOME_PAGE.TOP_5_COUNTRIES' | translate }}</span>
          <button class="btn border-start rounded-0" (click)="goToPage(countriesPath)">
            <a class="text-decoration-none text-sm">{{ 'COMMON.SELECT' | translate }}</a>
          </button>
        </div>
        <div class="card-body p-4">
          <p-chart [height]="'100%'" type="doughnut" [options]="chartOptions" [data]="topCountriesChartData"></p-chart>
        </div>
      </div>
    </div>
    <div class="col-4 p-2 h-50" *ngIf="competitorsChartData">
      <div class="h-100 w-100 card rounded-0">
        <div class="card-header d-flex align-items-center py-0 pe-0">
          <span class="flex-grow-1">{{ 'MARKET_OVERVIEW_COMPETITION.HEADER_LABEL' | translate }}</span>
          <button class="btn border-start rounded-0" (click)="goToPage(competitorsPath)">
            <a class="text-decoration-none text-sm">{{ 'COMMON.SELECT' | translate }}</a>
          </button>
        </div>
        <div class="card-body p-4">
          <p-chart [height]="'100%'" type="doughnut" [options]="chartOptions" [data]="competitorsChartData"></p-chart>
        </div>
      </div>
    </div>
    <div class="col-4 p-2 h-50" *ngIf="mostSearchedNumberChartData">
      <div class="h-100 w-100 card rounded-0">
        <div class="card-header d-flex align-items-center py-0 pe-0">
          <span class="flex-grow-1">{{ 'HOME_PAGE.MOST_SEARCHED_NUMBERS' | translate }}</span>
          <button class="btn border-start rounded-0" (click)="goToPage(mostSearchedNumbersPath)">
            <a class="text-decoration-none text-sm">{{ 'COMMON.SELECT' | translate }}</a>
          </button>
        </div>
        <div class="card-body">
          <p-chart [height]="'100%'" type="doughnut" [options]="chartOptions" [data]="mostSearchedNumberChartData"></p-chart>
        </div>
      </div>
    </div>
    <div class="col-4 p-2 h-50" *ngIf="mostSearchedVehiclesChartData">
      <div class="h-100 w-100 card rounded-0">
        <div class="card-header d-flex align-items-center py-0 pe-0">
          <span class="flex-grow-1">{{ 'HOME_PAGE.MOST_SEARCHED_VEHICLES' | translate }}</span>
          <button class="btn border-start rounded-0" (click)="goToPage(mostSearchedVehiclesPath)">
            <a class="text-decoration-none text-sm">{{ 'COMMON.SELECT' | translate }}</a>
          </button>
        </div>
        <div class="card-body">
          <p-chart [height]="'100%'" type="doughnut" [options]="chartOptions" [data]="mostSearchedVehiclesChartData"></p-chart>
        </div>
      </div>
    </div>
    <div class="col-4 p-2 h-50" *ngIf="brandsChartData">
      <div class="h-100 w-100 card rounded-0">
        <div class="card-header d-flex align-items-center py-0 pe-0">
          <span class="flex-grow-1">{{ 'ARTICLE_VIEWS_BRANDS.HEADER_LABEL' | translate }}</span>
          <button class="btn border-start rounded-0" (click)="goToPage(brandsPath)">
            <a class="text-decoration-none text-sm">{{ 'COMMON.SELECT' | translate }}</a>
          </button>
        </div>
        <div class="card-body">
          <p-chart [height]="'100%'" type="doughnut" [options]="chartOptions" [data]="brandsChartData"></p-chart>
        </div>
      </div>
    </div>
  </div>
</div>
