import { Component } from '@angular/core';
import { Fields } from '@app/shared/models/fields';
import { AnalyticsPage } from '../analytics-page';

@Component({
  selector: 'app-sales-brands',
  templateUrl: './sales-brands.component.html',
  styleUrl: './sales-brands.component.scss'
})
export class SalesBrandsPage extends AnalyticsPage {
  get pageName(): string {
    return 'Sales brands';
  }

  get pageStoreKey(): string {
    return 'SalesBrandsComponent';
  }

  get reportPath(): string {
    return '/report/order/sales';
  }

  get exportPath(): string {
    return '/export/facets';
  }

  get pageFacets(): string[] {
    return [Fields.BRAND_NO];
  }

  hasChanges(): boolean {
    return false;
  }
}
