<div class="d-flex flex-column h-100">
  <div class="flex-grow-1">
    <chart-panel [panelStyleClass]="'h-100'"
      [chartData]="chartData"
      [chartOptions]="chartOptions"
      [addTourAnchors]="true"
      [headerLabel]="'MOST_SEARCHED_VIN_NUMBERS.HEADER' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'MOST_SEARCHED_VIN_NUMBERS.INFO' | translate"
      [showSearch]="true"
      (searchStart)="onSearch($event)"
      [showTopDropdown]="true"
      [selectedTopOptions]="selectedTopOptions"
      [totalFound]="totalFound"
      (exportCsvClick)="onExportCsvClick()">
    </chart-panel>
  </div>
</div>
