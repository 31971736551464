import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ChartData, ChartOptions } from 'chart.js';
import _ from 'lodash';
import moment from 'moment';

import { TaskTrackerService, TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { PageComponent } from '@app/shared/components/page';
import { AnalyticsFilterService } from '@app/shared/components/analytics-filter/analytics-filter.service';
import { AnalyticsFilterData } from '@app/shared/components/analytics-filter/analytics-filter-data';
import { DEFAULT_BACKGROUND_COLORS } from '@app/shared/components/chart-panel/chart-default-options';
import { DatasourceInfo } from '@app/shared/models/datasource-info';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { Fields } from '@app/shared/models/fields';
import { FacetItem } from '@app/shared/models/facet-item';
import { ChartRequest } from '@app/shared/utils/chart-request';

import { MenuService } from '../../services/menu';

import { map, mergeMap, tap } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-home-page',
  templateUrl: 'home.component.html',
  styleUrl: 'home.component.scss',
})

export class HomePage extends PageComponent implements OnInit {
  override get pageName(): string {
    return 'Home';
  }

  selectedDatasourcesNames: string;
  selectedDatasourcesMinDateRange: string;
  selectedDatasourcesMaxDateRange: string;
  selectedDatasourcesDateRangeInDays: number;
  selectedDatasourcesAvgEvents: number;

  isEveryDatasourceLoaded = false;
  datasources: DatasourceInfo[] = [];
  selectedDatasources: DatasourceInfo[];

  filterSnapshot: AnalyticsFilterData;

  readonly chartOptions: ChartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        onClick: (e) => e.native.preventDefault() // Omit default behavior of toggle a value
      },
    },
  }

  topCountriesChartData: ChartData;
  competitorsChartData: ChartData;
  mostSearchedNumberChartData: ChartData;
  mostSearchedVehiclesChartData: ChartData;
  brandsChartData: ChartData;

  readonly countriesPath = '/analytics/article-views-countries';
  readonly competitorsPath = '/analytics/market-overview-competition';
  readonly mostSearchedNumbersPath = '/analytics/most-searched-numbers';
  readonly mostSearchedVehiclesPath = '/analytics/most-searched-vehicles';
  readonly brandsPath = '/analytics/article-views-brands';

  constructor(
    protected tracker: TrackerService,
    protected confirmation: ConfirmationService,
    protected override analyticsApi: ApiService,
    protected override router: Router,
    private menu: MenuService,
    private analyticsFilter: AnalyticsFilterService,
    private api: ApiService,
    private taskTracker: TaskTrackerService,
  ) {
    super(tracker, confirmation, analyticsApi, router);

    this.analyticsFilter.setupFilterForPage(this.pageName, {
      showBrandFilter: false,
      showCountryFilter: false,
      showGenericArticleFilter: false,
    });

    this.analyticsFilter.applyFilter
      .pipe(
        // Setup menu
        mergeMap(filterData => {
          this.filterSnapshot = filterData;

          return this.menu.fetchPropertiesAsync();
        }),

        // Load datasources
        mergeMap(() => this.loadDatasources()),
        mergeMap(() => this.setupSelectedDatasourcesCharts()),
      )
      .subscribe(() => this.setupSelectedDatasourcesProperties())
  }

  public override hasChanges(): boolean {
    return false;
  }

  private loadDatasources(): Observable<void> {
    return this.taskTracker.add(
      this.analyticsApi.getUserDataSources("HomeComponent")
        .pipe(
          mergeMap(facets => {
            const datasourcesIds = facets.map(f => f.id);
            return combineLatest([
              of(facets),
              this.analyticsApi.getDatasourceStats(datasourcesIds),
            ]);
          }),
          map(([facets, datasourcesInfo]) => {
            this.datasources = datasourcesInfo.filter(i => i.topCountries !== null);

            // Fullfill datasources names from facets info
            this.datasources.forEach(ds => {
              ds.name = facets.find(f => f.id === ds.id).name;
              ds.features = this.menu.getFeaturesForDatasource(ds.id, ds.cartEnabled)?.features;
            });

            if (this.filterSnapshot?.datasources) {
              this.selectedDatasources = this.datasources.filter(x => this.filterSnapshot.datasources.includes(x.id));
            }

            this.isEveryDatasourceLoaded = true;
          })
        )
      ) as Observable<void>;
  }

  private setupSelectedDatasourcesProperties() {
    if (!this.selectedDatasources?.length) {
      return;
    }

    this.selectedDatasourcesNames = this.selectedDatasources.map(x => x.name).join(', ');

    const dateFormat = 'MMM D, YYYY';

    const minDateMs = _.min(this.selectedDatasources.map(x => +x.firstDate));
    const minDate = moment(minDateMs, 'x');
    this.selectedDatasourcesMinDateRange = minDate.format(dateFormat);

    const maxDateMs = _.max(this.selectedDatasources.map(x => +x.lastDate));
    const maxDate = moment(maxDateMs, 'x');
    this.selectedDatasourcesMaxDateRange = maxDate.format(dateFormat);

    this.selectedDatasourcesDateRangeInDays = Math.round(moment.duration(maxDate.diff(minDate)).asDays());

    this.selectedDatasourcesAvgEvents = (this.selectedDatasources
      .map(x => x.eventCountLastWeek?.events ?? 0)
      .reduce((a, b) => a + b)) / this.selectedDatasources.length;
  }

  private setupSelectedDatasourcesCharts() {
    if (!this.selectedDatasources?.length) {
      return of(null);
    }

    this.competitorsChartData = undefined;
    this.mostSearchedNumberChartData = undefined;
    this.mostSearchedVehiclesChartData = undefined;
    this.brandsChartData = undefined;

    const countriesData = this.selectedDatasources[0].topCountries;
    this.topCountriesChartData = {
      labels: countriesData.map(x => `${x.name} (${x.percent}%)`),
      datasets: [
        {
          data: countriesData.map(x => x.count),
          backgroundColor: [...DEFAULT_BACKGROUND_COLORS.slice(0, countriesData.length - 1), '#808080'],
        }
      ],
    };

    const datasourceSettings = this.menu.datasourceSelected();

    const isCompetitorsAvaliable = datasourceSettings.allVisiblePaths.includes(this.competitorsPath);
    const isMostSearchedNumbersAvailable = datasourceSettings.allVisiblePaths.includes(this.mostSearchedNumbersPath);
    const isMostSearchedVehiclesAvailable = datasourceSettings.allVisiblePaths.includes(this.mostSearchedVehiclesPath);
    const isBrandsAvailable = datasourceSettings.allVisiblePaths.includes(this.brandsPath);

    return combineLatest([
      isCompetitorsAvaliable ? this.getMarketOverviewCompetitorsReport() : of(undefined),
      isMostSearchedNumbersAvailable ? this.getMostSearchedNumbersReport() : of(undefined),
      isMostSearchedVehiclesAvailable ? this.getMostSearchedVehiclesReport() : of(undefined),
      isBrandsAvailable ? this.getBrandsReport() : of(undefined),
    ])
    .pipe(
      tap(([competitors, mostSearchedNumbers, mostSearchedVehicles, brands]) => {
        this.competitorsChartData = this.getChartDataFromReportResponse(competitors, 'competitors');
        this.mostSearchedNumberChartData = this.getChartDataFromReportResponse(mostSearchedNumbers, 'search_string:bl:');
        this.mostSearchedVehiclesChartData = this.getChartDataFromReportResponse(mostSearchedVehicles, 'linking_target_id:bl:');
        this.brandsChartData = this.getChartDataFromReportResponse(brands, 'brand_id');
      })
    );
  }

  private getReport(reportType: string, fields: Fields[]): Observable<ReportResponse> {
    const chartRequest = new ChartRequest(reportType);
    chartRequest.setParams(this.filterSnapshot, fields);

    return this.api.getReport('/report/facets', chartRequest);
  }

  private getMarketOverviewCompetitorsReport(): Observable<ReportResponse> {
      return this.getReport('article_selection', [Fields.COMPETITORS]);
  }

  private getMostSearchedNumbersReport(): Observable<ReportResponse> {
      return this.getReport('article_search', [Fields.SEARCH_STRING]);
  }

  private getMostSearchedVehiclesReport(): Observable<ReportResponse> {
      return this.getReport('vehicle_search', [
          Fields.VEHICLE_MAKES_BRANDLESS,
          Fields.VEHICLE_MODELS_BRANDLESS,
          Fields.VEHICLES_BRANDLESS
      ]);
  }

  private getBrandsReport(): Observable<ReportResponse> {
      return this.getReport('article_selection', [Fields.BRAND_NO]);
  }

  private getChartDataFromReportResponse(response: ReportResponse, facetName: string): ChartData {
    if (!response) {
      return undefined;
    }

    const facetData = response.facets.get(facetName);

    const maxValuesShown = 5;
    let data = facetData.originalData;
    let colors = DEFAULT_BACKGROUND_COLORS;
    if (data.length > maxValuesShown) {
      const othersData = data.slice(maxValuesShown);
      const others: FacetItem = {
        name: 'others',
        id: 'others',
        count: othersData.map(x => x.count).reduce((a, b) => a + b),
        percent: +othersData.map(x => x.percent).reduce((a, b) => a + b).toFixed(2),
      }

      data = [...data.slice(0, maxValuesShown), others];
      colors = [...colors.slice(0, maxValuesShown), '#808080'];
    }

    return <ChartData>{
      labels: data.map(x => `${x.name} (${x.percent}%)`),
      datasets: [
        {
          data: data.map(x => x.count),
          backgroundColor: colors,
        }
      ]
    };
  }

  goToPage(path: string) {
    this.router.navigate([path]);
  }
}
