<div class="d-flex flex-column h-100">
  <div class="flex-grow-1">
    <map-panel [panelStyleClass]="'h-100 mb-3'"
      [panelStyle]="{'maxHeight': '650px'}"
      [infoText]="'MOST_SEARCHED_COUNTRIES.INFO_TEXT_MAP' | translate"
      [headerLabel]="'MOST_SEARCHED_COUNTRIES.HEADER_LABEL_MAP' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [mapData]="mapData"
      [leafletOptions]="mapOptions">
    </map-panel>
    <chart-panel [panelStyleClass]="'h-100'"
      [panelStyle]="{'maxHeight': '600px'}"
      [chartData]="chartData"
      [chartOptions]="chartOptions"
      [addTourAnchors]="true"
      [headerLabel]="'MOST_SEARCHED_COUNTRIES.HEADER_LABEL_CHART' | translate"
      [dateRangeLabel]="dateRangeLabel"
      [infoText]="'MOST_SEARCHED_COUNTRIES.INFO_TEXT_CHART' | translate"
      [showTopDropdown]="true"
      [enableChartTypeToggle]="false"
      [showTimelineButton]="true"
      [timelineDatasets]="datasets"
      [(selectedTimelineDatasets)]="selectedDatasets"
      (selectedTimelineDatasetsChange)="onSelectedDatasetsChange($event)"
      [timelineDatasetLimit]="10"
      [showAggregationOptions]="isTimelineChart"
      [showAggregationWeekdaysFilter]="true"
      [selectedTopOptions]="selectedTopOptions"
      (chartTypeChange)="onChartTypeChanged($event)"
      (exportCsvClick)="onExportCsvClick()">
    </chart-panel>
  </div>
</div>
