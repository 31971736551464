import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import moment from 'moment';
import { MenuItem } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';

import { DownloadStatus } from '@app/shared/models/download-status';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { FacetItem } from '@app/shared/models/facet-item';

type Catalog = { id: string, name: string };

@Component({
  selector: 'catalog-users-traffic-compare',
  templateUrl: './catalog-users-traffic-compare.component.html',
  styleUrl: './catalog-users-traffic-compare.component.scss'
})
export class CatalogUsersTrafficCompareComponent implements OnChanges {
  @Input() catalogs: Catalog[];
  @Input() catalogsData: {[key: string]: FacetItem[]};

  @Output() exportCsvClick = new EventEmitter<string>();

  /**
   * Flag that specifies if there is any chart export in progress.
   */
  get isAnyDownloadInProgress(): boolean {
    return this.downloadHandler.downloadStatus !== DownloadStatus.NONE;
  }

  /**
   * Available top options.
   */
  readonly topOptions = [
    { label: 'Top 10', value: 10 },
    { label: 'Top 20', value: 20 },
    { label: 'Top 30', value: 30 }
  ];

  /**
   * Instance of `DownloadHandler`, used in this component for the chart exports.
   */
  private readonly downloadHandler = new DownloadHandler(true);

  private leftPanelCatalogDataRaw: FacetItem[];
  private rightPanelCatalogDataRaw: FacetItem[];

  get leftPanelCatalogData(): FacetItem[] {
    return this.leftPanelCatalogDataRaw?.slice(0, this.leftTopOption);
  }
  leftPanelCatalog: Catalog;
  leftTopOption: 10 | 20 | 30 = 10;

  get rightPanelCatalogData(): FacetItem[] {
    return this.rightPanelCatalogDataRaw?.slice(0, this.rightTopOption);
  }
  rightPanelCatalog: Catalog;
  rightTopOption: 10 | 20 | 30 = 10;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['catalogs']?.currentValue) {
      this.leftPanelCatalog = this.catalogs?.[0];
      this.rightPanelCatalog = this.catalogs?.[1];

      this.setupPanelData();
    }
  }

  private setupPanelData() {
    this.leftPanelCatalogDataRaw = this.catalogsData[`users_${this.leftPanelCatalog.id}`];
    this.rightPanelCatalogDataRaw = this.rightPanelCatalog
      ? this.catalogsData[`users_${this.rightPanelCatalog.id}`]
      : [];
  }

  /**
   * Convert a given number of seconds into _humanized_ string,
   * expressed in hours and minutes.
   *
   * @param seconds the given number of seconds
   * @returns the given time in hours and minutes
   */
  normalizeTime(seconds: number): string {
    const duration = moment.duration(seconds, 'seconds');
    const hours = Math.trunc(duration.asHours());
    const minutes = duration.seconds() >= 30
      ? duration.minutes() + 1 // Round minutes
      : duration.minutes();
    return `${hours}h ${minutes}m`;
  }

  /**
   * Event handler triggered when the selected "Top options" value changes.
   *
   * @param event the "Top options" PrimeNG dropdown change event.
   */
  onChangeTop(event: DropdownChangeEvent, side: 'left' | 'right') {
    switch (side) {
      case 'left':
        this.leftTopOption = event.value;
        break;
      case 'right':
        this.rightTopOption = event.value;
        break;
    }
  }

  /**
   * Event handler triggered when the catalog selector value changes.
   *
   * @param event the catalog selector PrimeNG dropdown change event.
   */
  onChangeCatalog(event: DropdownChangeEvent, side: 'left' | 'right') {
    switch (side) {
      case 'left':
        this.leftPanelCatalog = event.value;
        break;
      case 'right':
        this.rightPanelCatalog = event.value;
        break;
    }

    this.setupPanelData();
  }

  onSaveClick(side: 'left' | 'right') {
    this.exportCsvClick.emit(side === 'left' ? this.leftPanelCatalog.id : this.rightPanelCatalog.id);
  }
}
